import { useState } from "react";
import {
  Box,
  Typography,
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
  Collapse,
  TextField,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import EmailInputWithDropdown from "./EmailInputWithDropdown"; // URL 런처 아이콘
import { FormattedMessage, useIntl } from "react-intl";
import parse from "html-react-parser";

const ConsentForm = ({
  agreements,
  onAgreementChange,
  formData,
  providerName,
}) => {
  const intl = useIntl();
  const [isExpanded, setIsExpanded] = useState({
    terms: false,
    privacy: false,
    sales: false,
    info: false,
  });

  // 운영 정보 수신 방법 상태 관리
  const [communicationMethods, setCommunicationMethods] = useState({
    email: false,
    kakao: false,
    push: false,
    sms: false,
    mail: false,
  });
  // 토글 확장 기능
  const toggleExpand = (item) => {
    setIsExpanded((prev) => ({ ...prev, [item]: !prev[item] }));
  };

  const handleCommunicationChange = (method) => {
    setCommunicationMethods((prev) => ({
      ...prev,
      [method]: !prev[method],
    }));
  };

  // URL을 새 탭에서 열기
  // const openURL = (url) => {
  //   window.open(url, "_blank", "noopener,noreferrer");
  // };

  return (
    <FormGroup>
      <div style={{ display: "flex", alignItems: "center" }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={agreements.termsOfService}
              onChange={(e) =>
                onAgreementChange("termsOfService", e.target.checked)
              }
            />
          }
          label={intl.formatMessage({ id: "signup.termsTitle" })}
        />
        <IconButton onClick={() => toggleExpand("terms")}>
          {isExpanded.terms ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
      </div>
      <Collapse in={isExpanded.terms}>
        {/* <div
          dangerouslySetInnerHTML={{
            __html: intl.formatMessage({ id: "signup.termsContent" }),
          }}
        /> */}
        {parse(`${intl.formatMessage({ id: "signup.termsContent" })}`)}
      </Collapse>
      <div style={{ display: "flex", alignItems: "center" }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={agreements.privacyPolicy}
              onChange={(e) =>
                onAgreementChange("privacyPolicy", e.target.checked)
              }
            />
          }
          label={intl.formatMessage({ id: "signup.privacyTitle" })}
        />
        <IconButton onClick={() => toggleExpand("privacy")}>
          {isExpanded.privacy ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
      </div>
      <Collapse in={isExpanded.privacy}>
        {/* <div
          dangerouslySetInnerHTML={{
            __html: intl.formatMessage({ id: "signup.privacyContent" }),
          }}
        /> */}
        {parse(`${intl.formatMessage({ id: "signup.privacyContent" })}`)}
      </Collapse>
      <div style={{ display: "flex", alignItems: "center" }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={agreements.promotions}
              onChange={(e) =>
                onAgreementChange("promotions", e.target.checked)
              }
            />
          }
          label={intl.formatMessage(
            { id: "signup.salesTitle" },
            { provider: providerName }
          )}
        />
        <IconButton onClick={() => toggleExpand("sales")}>
          {isExpanded.sales ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
      </div>
      <Collapse in={isExpanded.sales}>
        <Typography variant="body2" style={{ marginInlineStart: 32 }}>
          <FormattedMessage
            id="signup.salesContent"
            values={{ provider: providerName }}
          />
        </Typography>
        <FormGroup row style={{ marginInlineStart: 32 }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={communicationMethods.email}
                onChange={() => handleCommunicationChange("email")}
              />
            }
            label={intl.formatMessage({ id: "signup.email" })}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={communicationMethods.kakao}
                onChange={() => handleCommunicationChange("kakao")}
              />
            }
            label={intl.formatMessage({ id: "signup.alimTalk" })}
          />
          {/*<FormControlLabel*/}
          {/*  control={*/}
          {/*    <Checkbox*/}
          {/*      checked={communicationMethods.push}*/}
          {/*      onChange={() => handleCommunicationChange("push")}*/}
          {/*    />*/}
          {/*  }*/}
          {/*  label="Push"*/}
          {/*/>*/}
          <FormControlLabel
            control={
              <Checkbox
                checked={communicationMethods.sms}
                onChange={() => handleCommunicationChange("sms")}
              />
            }
            label={intl.formatMessage({ id: "signup.sms" })}
          />
          {/*<FormControlLabel*/}
          {/*  control={*/}
          {/*    <Checkbox*/}
          {/*      checked={communicationMethods.mail}*/}
          {/*      onChange={() => handleCommunicationChange("mail")}*/}
          {/*    />*/}
          {/*  }*/}
          {/*  label="우편물"*/}
          {/*/>*/}
        </FormGroup>
      </Collapse>
      <div style={{ display: "flex", alignItems: "center" }}>
        <FormControlLabel
          control={<Checkbox />}
          label={intl.formatMessage({ id: "signup.benefitTitle" })}
        />
        <IconButton onClick={() => toggleExpand("info")}>
          {isExpanded.info ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
      </div>
      <Collapse in={isExpanded.info}>
        <Typography variant="body2" style={{ marginInlineStart: 32 }}>
          <FormattedMessage id="signup.benefitContent" />
        </Typography>
      </Collapse>
      {/* TODO: 한패스 회원 관련 내용 */}
      <Box sx={{ display: "none" }}>
        <TextField fullWidth name="name" label="이름" value={formData.name} />
        <TextField
          fullWidth
          margin="normal"
          name="username"
          label="아이디"
          value={formData.username}
        />
        <EmailInputWithDropdown
          email={formData.email}
          // setEmail={setEmail}
          // domain={domain}
          // setDomain={setDomain}
        />
        <TextField
          fullWidth
          margin="normal"
          type="tel"
          name="phoneNumber"
          label="휴대폰 번호"
          value={formData.phoneNumber}
          // onChange={handleChange}
        />
      </Box>
    </FormGroup>
  );
};

export default ConsentForm;
