import { TrainParams } from "app/reservationSlice";
import { CostResponse } from "services/trainService";
import { Coupon } from "utils/reservationUtils";
import {
  getAdultsNumber,
  getChildrensNumber,
} from "utils/urlSearchParamsUtils";

export type PriceType = {
  originalPrice: number;
  discount: number;
  commission: number;
  paymentPrice: number;
  pointAmount: number;
};

// 총액 계산 함수
const calculateTotalPrice = ({
  csmrFare,
  mrkFare,
  sellDscntRt = 0,
  sellCmsnRt = 0,
  trnPsgTpCd,
}: Exclude<CostResponse, "buyDscntRt" | "trnPsrmClCd">): {
  originalPrice: number;
  expectedPoint: number;
  expectedCommission: number;
  finalPrice: number;
} => {
  let finalPrice = 0;
  let originalPrice = trnPsgTpCd === "1" ? csmrFare : mrkFare;
  let expectedPoint = 0;
  const expectedCommission = (originalPrice * sellCmsnRt) / 100;

  if (trnPsgTpCd === "1") {
    // 성인 가격은 csmrFare에서 sellDscntRt를 적용
    // finalPrice = csmrFare * (1 - sellDscntRt / 100);
    // expectedPoint = csmrFare - finalPrice;

    // 할인대신 적립으로 변경
    expectedPoint = (csmrFare * sellDscntRt) / 100;
    finalPrice = csmrFare;
  } else if (trnPsgTpCd === "3") {
    // 어린이 가격은 mrkFare 기준
    finalPrice = mrkFare;
  }

  return { originalPrice, expectedPoint, expectedCommission, finalPrice };
};

// 성인 가격은 csmrFare에서 sellDscntRt 를 적용한 금액, 어린이 가격은 mrkFare 으로 계산
export const calculateFilteredTotalPrice = (
  trainParams: TrainParams
): PriceType => {
  const costDetailsArray = trainParams.costDetails;
  let totalOriginalPrice = 0;
  let totalExpectedDiscount = 0;
  let totalExpectedCommission = 0;
  let totalPayableAmount = 0;

  const adultsCount = getAdultsNumber();
  const childrenCount = getChildrensNumber();
  // const adultsCount = reservation.searchParams.adults;
  // const childrenCount = reservation.searchParams.children;

  costDetailsArray?.forEach((detail) => {
    if (detail.trnPsrmClCd === "1") {
      // 열차객실등급코드 일반
      const { originalPrice, expectedPoint, expectedCommission, finalPrice } =
        calculateTotalPrice(detail);

      if (detail.trnPsgTpCd === "1") {
        // 성인 대상 데이터
        totalOriginalPrice += originalPrice * adultsCount;
        totalExpectedDiscount += expectedPoint * adultsCount;
        totalExpectedCommission += expectedCommission * adultsCount;
        totalPayableAmount += finalPrice * adultsCount;
      } else if (detail.trnPsgTpCd === "3") {
        // 어린이 대상 데이터
        totalOriginalPrice += originalPrice * childrenCount;
        totalExpectedDiscount += expectedPoint * childrenCount;
        totalExpectedCommission += expectedCommission * childrenCount;
        totalPayableAmount += finalPrice * childrenCount;
      }
    }
  });

  // 적립 포인트
  const pointAmount =
    ((costDetailsArray?.[0]?.pointSavingRt || 0) * totalOriginalPrice) / 100;

  return {
    originalPrice: totalOriginalPrice,
    discount: totalExpectedDiscount,
    commission: totalExpectedCommission,
    paymentPrice: totalPayableAmount + totalExpectedCommission,
    pointAmount,
  };
};

export const calculateNewPrice = (price: PriceType, coupon: Coupon) => {
  return {
    originalPrice: price.originalPrice + coupon.saleAmount,
    discount: price.discount,
    commission: price.commission,
    paymentPrice: price.paymentPrice + coupon.saleAmount,
  };
};
