import {
  Typography,
  Stack,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Divider,
  TextField,
} from "@mui/material";
import CardLayout from "components/layout/CardLayout";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import { useEffect, useState } from "react";
import CustomButton from "components/button/CustomButton";
import { useIntl } from "react-intl";
import { useOutletContext } from "react-router-dom";
import { NumericString } from "types/numericString";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { formatCardNumber } from "components/card/Card";
import CardService from "services/cardService";
import { CardContext } from "../CardPageLayout";
import { updateCard } from "app/cardsSlice";
import LoadingSpinner from "components/LoadingSpinner";

const Transfer = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();

  const { currentCard } = useOutletContext<CardContext>();
  const { iapCdno: currentCardNo, acnRmd: balance } = currentCard || {};
  const cards = useAppSelector((state) => state.cards);
  const toCards = cards.filter(
    (card) => card.iapCrdStcd === "01" && card.iapCdno !== currentCardNo
  );
  const [toCardNo, setToCardNo] = useState(toCards[0]?.iapCdno);

  const [isLoading, setIsLoading] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [balanceToTransfer, setBalanceToTransfer] = useState("0");
  const numericBalanceToTransfer = Number(
    balanceToTransfer.replaceAll(",", "")
  );

  useEffect(() => {
    setToCardNo(toCards[0]?.iapCdno);
    setBalanceToTransfer("0");
  }, [currentCard]); // toCards도 dependency에 추가하면 매번 toCardNo가 초기화되서 넣지 않아야 한다.

  // TODO: 현재 balance보다 큰 경우 처리하기

  const handleChangeAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    if (parseInt(value) > balance) {
      alert("Cannot transfer more than balance");
      return;
    }

    setBalanceToTransfer(`${Number(value)}`);
  };

  const handleFocus = () => {
    setIsFocused(true);
    setBalanceToTransfer(`${numericBalanceToTransfer || "0"}`);
  };

  const handleBlur = () => {
    setIsFocused(false);
    setBalanceToTransfer(
      numericBalanceToTransfer > 0
        ? numericBalanceToTransfer.toLocaleString()
        : "0"
    );
  };

  const handleChangeToCard = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    setToCardNo(value as NumericString);
  };

  // const getHeader = () => {
  //   return (
  //     <Typography variant="h6" sx={{ fontWeight: "bold" }}>
  //       Transfer Balance
  //     </Typography>
  //   );
  // };

  const transferBalance = async () => {
    setIsLoading(true);

    await CardService.transferBalance(
      currentCardNo,
      toCardNo,
      numericBalanceToTransfer
    );

    // const newFromCardBalance = await CardService.getCardBalance(currentCardNo);
    // const newToCardBalance = await CardService.getCardBalance(toCardNo);
    // dispatch(
    //   updateCard({
    //     cardNumber: currentCardNo,
    //     balance: Number(newFromCardBalance.acnRmd),
    //   })
    // );
    // dispatch(
    //   updateCard({
    //     cardNumber: toCardNo,
    //     balance: Number(newToCardBalance.acnRmd),
    //   })
    // );

    const balanceRequestArr = [
      CardService.getCardBalance(currentCardNo),
      CardService.getCardBalance(toCardNo),
    ];

    Promise.allSettled(balanceRequestArr)
      .then((result) => {
        result.forEach(async (val, index) => {
          if (val.status === "rejected") {
            await balanceRequestArr[index];
          }
        });
        // Redux에 저장된 balance를 update
        result.forEach((val, index) => {
          if (val.status === "fulfilled") {
            const acnRmd = val.value.data?.acnRmd;
            if (acnRmd) {
              if (index === 0) {
                dispatch(
                  updateCard({
                    cardNumber: currentCardNo,
                    balance: parseInt(acnRmd),
                  })
                );
              } else if (index === 1) {
                dispatch(
                  updateCard({
                    cardNumber: toCardNo,
                    balance: parseInt(acnRmd),
                  })
                );
              }
            }
          }
        });
        // TODO: success modal
        alert("Transfer success");
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setBalanceToTransfer("0");
        setIsLoading(false);
      });
  };

  return (
    <CardLayout
      // header={getHeader()}
      sx={{ border: "none", boxShadow: "none", p: 1 }}
    >
      <Stack gap={2} sx={{ width: "100%" }}>
        <label>
          <Typography variant="body1" sx={{ fontWeight: "bold", mb: 1 }}>
            Amount to transfer
          </Typography>
          <TextField
            fullWidth
            name="balance"
            type={isFocused ? "number" : "text"}
            value={balanceToTransfer}
            onChange={handleChangeAmount}
            InputProps={{
              inputProps: {
                min: 0,
              },
              sx: {
                height: "48px",

                "& .MuiInputBase-input": {
                  textAlign: "end",
                },
                "& input[type=number]": {
                  "-moz-appearance": "textfield",
                  margin: 0.3,
                },
                "& input[type=number]::-webkit-outer-spin-button": {
                  "-webkit-appearance": "none",
                  margin: 0.3,
                },
                "& input[type=number]::-webkit-inner-spin-button": {
                  "-webkit-appearance": "none",
                  margin: 0.3,
                },
                "& input[type=text]": {
                  "-moz-appearance": "textfield",
                  margin: 1,
                },
                "& input[type=text]::-webkit-outer-spin-button": {
                  "-webkit-appearance": "none",
                  margin: 1,
                },
                "& input[type=text]::-webkit-inner-spin-button": {
                  "-webkit-appearance": "none",
                  margin: 1,
                },
              },
              endAdornment: intl.formatMessage({ id: "payment.koreanWon" }),
            }}
            onFocus={handleFocus}
            onBlur={handleBlur}
            placeholder="0"
          />
        </label>
        <Stack sx={{ width: "100%" }} gap={1}>
          <Stack sx={{ width: "100%" }} gap={1}>
            <Typography variant="body1" sx={{ fontWeight: "bold" }}>
              From
            </Typography>
            <FormControl>
              <RadioGroup value={currentCardNo} name="from-card">
                <FormControlLabel
                  value={currentCardNo}
                  disabled
                  control={<Radio />}
                  label={<CardThumbnail cardName={currentCardNo} />}
                />
              </RadioGroup>
            </FormControl>
          </Stack>
          <Divider flexItem />
          <Stack
            justifyContent="space-between"
            alignItems="start"
            sx={{ width: "100%" }}
          >
            <Typography variant="body1" sx={{ fontWeight: "bold" }}>
              To
            </Typography>
            <FormControl>
              <RadioGroup
                // row
                // aria-labelledby="demo-radio-buttons-group-label"
                value={toCardNo}
                name="to-cards-group"
                onChange={handleChangeToCard}
              >
                {toCards.map((card) => (
                  <FormControlLabel
                    key={card.iapCdno}
                    value={card.iapCdno}
                    control={<Radio />}
                    label={
                      <CardThumbnail
                        key={card.iapCdno}
                        cardName={card.iapCdno}
                      />
                    }
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </Stack>
        </Stack>

        <CustomButton
          size="medium"
          variant="outlined"
          onClick={transferBalance}
          disabled={balanceToTransfer === "0"}
        >
          Transfer Balance
        </CustomButton>
      </Stack>
      {isLoading && <LoadingSpinner overlap />}
    </CardLayout>
  );
};

export default Transfer;

//
const CardThumbnail = ({ cardName }: { cardName: string }) => {
  return (
    <Stack direction="row" alignItems="center" gap={1}>
      <CreditCardIcon />
      <Typography variant="body2" sx={{ fontWeight: "bold" }}>
        {formatCardNumber(cardName)}
      </Typography>
    </Stack>
  );
};
