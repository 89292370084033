import { Divider, Stack, Typography, useTheme } from "@mui/material";
import { useAppSelector } from "app/hooks";
import useFetchPoint from "hooks/useFetchPoint";

const HomePoint = () => {
  const theme = useTheme();
  const point = useFetchPoint();
  const { userId } = useAppSelector((state) => state.user);

  return (
    userId && (
      <Stack gap={1} sx={{ "& > *": { px: 1 } }}>
        <Typography
          variant="body1"
          color="text.secondary"
          sx={{ fontWeight: "bold" }}
        >
          보유 라차 적립금:{" "}
        </Typography>
        <Stack
          direction="row"
          alignItems="end"
          gap={0.5}
          sx={{ alignSelf: "end" }}
        >
          <Typography
            variant="h5"
            sx={{
              color: theme.palette.primary.main,
              fontWeight: "bold",

              lineHeight: "1.3",
            }}
          >
            {point.toLocaleString()}
          </Typography>
          <Typography
            variant="body1"
            color="text.secondary"
            sx={{ fontWeight: "bold" }}
          >
            적립금
          </Typography>
        </Stack>
        <Divider sx={{ bgcolor: "primary.main", height: "2px" }} />
      </Stack>
    )
  );
};

export default HomePoint;
