import { useEffect } from "react";
import { ProviderUtils } from "utils/providerUtils";

const useFetchWebBridge = () => {
  useEffect(() => {
    // Check if the current URL contains "lotte"
    if (ProviderUtils.isLottecard) {
      var script = document.createElement("script");

      // Set the source to the path of the JavaScript file
      script.src = "/lottecardWebBridge.js";

      // Append the script to the document's head or body
      document.body.appendChild(script); // Or document.head.appendChild(script);
    }
  }, []);
  return;
};

export default useFetchWebBridge;
