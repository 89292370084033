import { useLocation } from "react-router-dom";

const useCheckIsLoginPage = () => {
  const { pathname } = useLocation();

  const isLoginPage = () => {
    return pathname === "/login";
  }

  return isLoginPage();
};

export default useCheckIsLoginPage;
