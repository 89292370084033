import { IconButton, Stack, Typography } from "@mui/material";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import useFormatPrice from "hooks/useFormatPrice";
import { useState } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";

interface CardBalanceProps {
  balance: number;
}

const CardBalance = ({ balance }: CardBalanceProps) => {
  const formatPrice = useFormatPrice();
  const [showBalance, setShowBalance] = useState(
    JSON.parse(localStorage.getItem("showBalance") || "false")
  );

  const toggleBalance = () => {
    setShowBalance(!showBalance);
    localStorage.setItem("showBalance", JSON.stringify(!showBalance));
  };

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="end"
      sx={{ width: "100%" }}
    >
      <Stack direction="row" justifyContent="start" alignItems="center" gap={1}>
        <AccountBalanceWalletIcon fontSize="large" color="primary" />
        <Typography variant="body1" sx={{ fontWeight: "bold" }}>
          Balance
        </Typography>
      </Stack>
      <Stack direction="row" alignItems="center">
        <IconButton size="small" sx={{ opacity: 0.8 }} onClick={toggleBalance}>
          {showBalance ? (
            <Visibility fontSize="small" />
          ) : (
            <VisibilityOff fontSize="small" />
          )}
        </IconButton>
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          {showBalance ? formatPrice(balance) : `•••••••••••`}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default CardBalance;
