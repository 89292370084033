import { Stack } from "@mui/material";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import CustomButton from "components/button/CustomButton";
import DateRange from "./DateRange";
import { useAppSelector } from "app/hooks";
import { forwardRef, memo } from "react";

const TabComponent = forwardRef<HTMLDivElement>((_props, ref) => {
  const intl = useIntl();
  const {
    filterSettings: { status },
  } = useAppSelector((state) => state.myOrders);

  // 페이지 이동을 위한 navigate 함수
  const navigate = useNavigate();
  const handleDetailQueryOpen = () => {
    navigate("/detail-query");
  };

  return (
    <div
      style={{ borderBottom: 1, borderColor: "divider", marginBottom: 2 }}
      ref={ref}
    >
      <DateRange />
      <Stack
        direction="row"
        justifyContent={status === "ALL" ? "end" : "space-between"}
        alignItems="center"
      >
        {status !== "ALL" && (
          <CustomButton
            size="small"
            variant="text"
            fullWidth={false}
            disabled
            style={{ color: "black" }}
          >
            {intl.formatMessage({ id: `orders.${status.toLowerCase()}` })}
          </CustomButton>
        )}
        <CustomButton
          size="medium"
          variant="text"
          // variant="outlined"
          onClick={handleDetailQueryOpen}
          sx={{ float: "right" }} // 버튼 위치 조정
          fullWidth={false}
        >
          {intl.formatMessage({ id: "orders.filterTitle" })} /{" "}
          {intl.formatMessage({ id: "orders.status" })}
        </CustomButton>
      </Stack>
    </div>
  );
});

export default memo(TabComponent);
