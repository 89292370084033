import CircularProgress from "@mui/material/CircularProgress";
import { Stack } from "@mui/system";
import { useEffect, useState } from "react";

interface LoadingSpinnerProps {
  height?: string;
  overlap?: boolean;
}

const LoadingSpinner = ({
  height = "80vh",
  overlap = false,
}: LoadingSpinnerProps) => {
  const [scrollTop, setScrollTop] = useState(
    document.documentElement.scrollTop
  );

  useEffect(() => {
    const onScroll = () => {
      setScrollTop(document.documentElement.scrollTop);
    };
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      style={
        !overlap
          ? {
              height,
            }
          : {
              position: "absolute",
              height: "100%",
              left: "50%",
              // top: `${document.documentElement.scrollTop}px`,
              top: `${scrollTop}px`,
              transform: "translateX(-50%)",
            }
      }
    >
      <CircularProgress />
    </Stack>
  );
};

export default LoadingSpinner;
