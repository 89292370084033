import { Stack, Typography } from '@mui/material';
import CustomButton from 'components/button/CustomButton';
import CardLayout from 'components/layout/CardLayout';
import LoadingSpinner from 'components/LoadingSpinner';
import TransactionList from './components/TransactionList';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { CardContext } from './CardPageLayout';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { useEffect, useState } from 'react';
import CardService from 'services/cardService';
import { fetchTransactions } from 'app/cardsSlice';

const RecentTransaction = () => {
  const navigate = useNavigate();
  const { currentCard } = useOutletContext<CardContext>();
  const { iapCdno: cardNumber } = currentCard || {};

  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useAppDispatch();
  const transactionList = useAppSelector(
    (state) =>
      state.cards.find((card) => card.iapCdno === cardNumber)?.transactionList
  );

  const navigateToTransactions = () => {
    navigate(`/card/${cardNumber}/transactions`);
  };

  useEffect(() => {
    if (!transactionList?.length) {
      const getTransactionList = async () => {
        setIsLoading(true);
        try {
          const { data } = await CardService.getTransactionList(cardNumber);
          if (data) {
            dispatch(
              fetchTransactions({
                cardNumber,
                transactions: data.list,
              })
            );
          }
        } catch {
        } finally {
          setIsLoading(false);
        }
      };

      getTransactionList();
    }
  }, [cardNumber, dispatch, transactionList]);

  return (
    <CardLayout>
      <Stack gap={1} sx={{ width: '100%' }}>
        <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
          Recent Transactions
        </Typography>

        {isLoading ? (
          <LoadingSpinner height="80px" />
        ) : (
          <TransactionList transactions={transactionList?.slice(0, 5)} />
        )}

        <CustomButton
          variant="outlined"
          size="medium"
          onClick={navigateToTransactions}
        >
          Show more
        </CustomButton>
      </Stack>
    </CardLayout>
  );
};

export default RecentTransaction;
