import { Stack } from "@mui/material";
import { Container } from "@mui/system";
import { useEffect, useRef, useState } from "react";
import CardsCarousel from "./components/CardsCarousel";
import Layout from "components/layout/Layout";
import { Outlet, useLocation } from "react-router-dom";
import { CardInterface, fetchCards } from "app/cardsSlice";
import CardService from "services/cardService";
import { useAppDispatch, useAppSelector } from "app/hooks";
import Card from "components/card/Card";
import Carousel from "react-multi-carousel";

export interface CardContext {
  currentCard: CardInterface;
  resetCarousel: () => void;
}

const CardPageLayout = () => {
  const carouselRef = useRef<Carousel>(null);
  const dispatch = useAppDispatch();
  const cards = useAppSelector((state) => state.cards);
  const { pathname } = useLocation();
  const title = pathname.includes("detail") ? "Card Detail" : "Card Home";

  const [currentCardIndex, setCurrentCardIndex] = useState(0);
  const currentCard = cards[currentCardIndex];
  const [isLoading, setIsLoading] = useState(!cards.length);

  const resetCarousel = () => {
    carouselRef.current?.goToSlide(0);
  };

  useEffect(() => {
    // redux에 카드 리스트 없는 경우
    if (!cards.length) {
      const getCards = async () => {
        const { data } = await CardService.getCardList();
        if (data) {
          dispatch(fetchCards(data.list));
        }
        setIsLoading(false);
      };

      getCards();
    }
  }, [cards.length, dispatch]);

  return (
    <Layout text={title}>
      {/* <Header /> */}
      <Container maxWidth="sm" sx={{ width: "100%", mt: 2, px: 0 }}>
        <Stack gap={2}>
          {isLoading ? (
            <Card cardNumber="" isRegister />
          ) : (
            <CardsCarousel
              ref={carouselRef}
              cards={cards.filter((card) => card.iapCrdStcd !== "03")}
              setCurrentCardIndex={setCurrentCardIndex}
            />
          )}
          <Outlet context={{ currentCard, resetCarousel }} />
        </Stack>
      </Container>
    </Layout>
  );
};

export default CardPageLayout;
