import { fetchUser } from "app/userSlice";
import axios, { isAxiosError } from "axios";
import LoadingSpinner from "components/LoadingSpinner";
import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import AuthService from "services/authService";
import TokenService from "services/tokenService";
import UserService from "services/userService";
import { getURIVariable } from "utils/socialLoginUtils";
import { StorageUtils } from "utils/storageUtils";

interface UserInfo {
  data: {
    id: number;
    kakao_account: {
      email: string;
    };
  };
}

const KaKaoCallback = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const searchParams = new URLSearchParams(window.location.search);
  const code = searchParams.get("code")!;
  const isModal = StorageUtils.IsLoginModal.get();

  const userInfoRef = useRef<UserInfo>();

  useEffect(() => {
    const [KAKAO_CLIENT_ID, KAKAO_REDIRECT_URI] = getURIVariable("KAKAO");
    const KAKAO_TOKEN_URI = `https://kauth.kakao.com/oauth/token?grant_type=authorization_code&client_id=${KAKAO_CLIENT_ID}&redirect_uri=${KAKAO_REDIRECT_URI}`;
    const KAKAO_USER_URI = "https://kapi.kakao.com/v2/user/me";

    const getToken = async () => {
      try {
        const tokenResponse = await axios.post(
          `${KAKAO_TOKEN_URI}&code=${code}`
        );

        const userResponse = await axios.get(KAKAO_USER_URI, {
          headers: {
            Authorization: `Bearer ${tokenResponse.data.access_token}`,
          },
        });

        userInfoRef.current = userResponse;
      } catch (error) {
        if (isAxiosError(error)) {
          console.error(error);
        }
        alert("Kakao signin failed");
        return;
      }

      try {
        const response = await AuthService.kakaoLogin(
          String(userInfoRef.current?.data.id)
        );

        if (response.status < 300) {
          TokenService.setAccessToken(response.data.accessToken);

          // 사용자 정보
          const userInfoResponse = await UserService.getMyInfo();
          dispatch(fetchUser(userInfoResponse));

          StorageUtils.RecentLoginMethod.set("KAKAO");

          if (isModal) {
            navigate("/search-results");
            return;
          }

          navigate("/");
        }
      } catch (error) {
        if (isAxiosError(error)) {
          const userInfo = { ...error.response?.data.userInfo };
          userInfo.email = userInfoRef.current?.data.kakao_account.email;
          navigate("/auth/additional-info", {
            state: { userInfo, ssoName: "KAKAO" },
          });
        }
      }
    };
    getToken();
  });

  return <LoadingSpinner />;
};
export default KaKaoCallback;
