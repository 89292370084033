import React from "react";
import { Stack } from "@mui/material";
import { styled } from "@mui/material/styles";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";

const DateWrapper = ({ children }: { children: React.ReactNode }) => {
  return (
    <Wrapper>
      <CalendarMonthIcon fontSize="large" />
      {children}
    </Wrapper>
  );
};

export default DateWrapper;

const Wrapper = styled(Stack)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 8px;

  button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 1em;
    padding: 5px 10px;
    margin: 0 15px;
  }
`;
