import BookingInfos from "../../components/booking_info/BookingInfos";
import QRCode from "components/QRCode";
import { Container, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import CancelIcon from "@mui/icons-material/Cancel";
import useLanguages from "hooks/useLanguages";
import { useLocation } from "react-router-dom";
import TickerNotice from "pages/tickets/components/TickerNotice";
import { OrderType } from "types/orderType";

interface TicketProps {
  reservationDetails?: OrderType;
}

const Ticket = ({ reservationDetails }: TicketProps) => {
  const { isKorean } = useLanguages();

  const state = useLocation().state ?? reservationDetails;

  return state.orderStatus === "COMPLETE" ? (
    <Container
      sx={{
        mt: reservationDetails ? 0 : 2,
        mb: 2,
        px: 0,
        wordBreak: "keep-all",
      }}
    >
      <BookingInfos
        order={state}
        qrCode={
          <QRCode
            src={`data:image/png;base64,${state?.trainList[0].qrCodeImage}`}
          />
        }
      />
      <TickerNotice />
    </Container>
  ) : (
    <Stack
      justifyContent="space-around"
      alignItems="center"
      sx={{ width: "100%", minHeight: "50dvh" }}
    >
      <Stack
        alignItems="center"
        justifyContent="center"
        gap={1}
        sx={{ width: "100%", my: 6, textAlign: "center" }}
      >
        <CancelIcon color="error" sx={{ fontSize: 120 }} />
        <Typography
          variant="h6"
          sx={{ maxWidth: "280px", wordBreak: "keep-all" }}
        >
          {isKorean ? "취소된 승차권입니다." : "Cancelled train ticket."}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default Ticket;
