import { SocialLoginProvider } from "components/social_login/SocialLoginButton";
import { CurrencyType } from "services/trainService";

const enum LocalStorageKey {
  FIELD_NAME = "fieldName",
  RECENT_SEARCHES = "recentSearches",
  IS_LOGIN_MODAL = "isLoginModal",
  SAVED_LOGIN_ID = "savedLoginId",
  RECENT_LOGIN_METHOD = "recentLoginMethod",
}

const enum SessionStorageKey {
  HANPASS_BACK = "hanpass_back",
  TRAIN_TAB = "tab",
  Y_SCROLL = "yScroll",
  DOLLAR_RATE = "dollar_rate",
  JPY_RATE = "jpy_rate",
}

// type BooleanishString = "true" | "false";
type BooleanishString = `${boolean}`;

export class StorageUtils {
  // localStorage
  static RecentSearches = class {
    static KEY = LocalStorageKey.RECENT_SEARCHES;

    static get() {
      return localStorage.getItem(this.KEY);
    }

    static set(value: string) {
      localStorage.setItem(this.KEY, value);
    }

    static remove() {
      localStorage.removeItem(this.KEY);
    }
  };

  static IsLoginModal = class {
    static KEY = LocalStorageKey.IS_LOGIN_MODAL;

    static get() {
      return localStorage.getItem(this.KEY);
    }

    static set(value: boolean | BooleanishString) {
      localStorage.setItem(this.KEY, JSON.stringify(value));
    }

    static remove() {
      localStorage.removeItem(this.KEY);
    }
  };

  static SavedLoginId = class {
    static KEY = LocalStorageKey.SAVED_LOGIN_ID;

    static get() {
      return localStorage.getItem(this.KEY);
    }

    static set(value: string) {
      localStorage.setItem(this.KEY, value);
    }

    static remove() {
      localStorage.removeItem(this.KEY);
    }
  };

  static RecentLoginMethod = class {
    static KEY = LocalStorageKey.RECENT_LOGIN_METHOD;

    static get() {
      return localStorage.getItem(this.KEY);
    }

    static set(value: SocialLoginProvider | "DEFAULT_FORM") {
      localStorage.setItem(this.KEY, value);
    }

    static remove() {
      localStorage.removeItem(this.KEY);
    }
  };

  // sessionStorage
  static HanpassBack = class {
    static KEY = SessionStorageKey.HANPASS_BACK;

    static get() {
      return sessionStorage.getItem(this.KEY);
    }

    static set(value: string) {
      sessionStorage.setItem(this.KEY, value);
    }

    static remove() {
      sessionStorage.removeItem(this.KEY);
    }
  };

  static TrainTab = class {
    static KEY = SessionStorageKey.TRAIN_TAB;

    static get() {
      return sessionStorage.getItem(this.KEY);
    }

    static set(value: string | number) {
      sessionStorage.setItem(this.KEY, JSON.stringify(value));
    }

    static remove() {
      sessionStorage.removeItem(this.KEY);
    }
  };

  static ScrollY = class {
    static KEY = SessionStorageKey.Y_SCROLL;

    static get() {
      return sessionStorage.getItem(this.KEY);
    }

    static set(value: number) {
      sessionStorage.setItem(this.KEY, JSON.stringify(value));
    }

    static remove() {
      sessionStorage.removeItem(this.KEY);
    }
  };

  static CurrencyRate = class {
    static DOLLAR_KEY = SessionStorageKey.DOLLAR_RATE;
    static JPY_KEY = SessionStorageKey.JPY_RATE;

    static get(code: CurrencyType) {
      const key = code === "USD" ? this.DOLLAR_KEY : this.JPY_KEY;
      return sessionStorage.getItem(key);
    }

    static set(code: CurrencyType, value: number) {
      const key = code === "USD" ? this.DOLLAR_KEY : this.JPY_KEY;

      sessionStorage.setItem(key, JSON.stringify(value));
    }

    static remove(code: CurrencyType) {
      const key = code === "USD" ? this.DOLLAR_KEY : this.JPY_KEY;

      sessionStorage.removeItem(key);
    }
  };
}
