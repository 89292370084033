import { useIntl } from "react-intl";

type DateTypeOptionType = Record<string, string | boolean>;

const useFormatDateToLocaleString = () => {
  const intl = useIntl();

  // 날짜와 시간을 형식화하는 함수
  const formatDateTime = (
    dateTimeStr: string,
    options?: DateTypeOptionType
  ) => {
    const dateTimeOptions: DateTypeOptionType = options ?? {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    };
    return new Date(dateTimeStr).toLocaleString(intl.locale, dateTimeOptions);
  };

  return formatDateTime;
};

export default useFormatDateToLocaleString;
