import {
  Outlet,
  useLocation,
  useNavigate,
  useOutletContext,
} from 'react-router-dom';
import CardLayout from 'components/layout/CardLayout';
import { Stack, Typography } from '@mui/material';
import CustomButton from 'components/button/CustomButton';
import SettingsIcon from '@mui/icons-material/Settings';
import PaidIcon from '@mui/icons-material/Paid';
import IosShareIcon from '@mui/icons-material/IosShare';
import CardBalance from './components/CardBalance';
import { CardContext } from './CardPageLayout';
import { useEffect, useRef } from 'react';

const useScrollRestoration = () => {
  const { pathname } = useLocation();
  const scrollY = useRef(0);

  useEffect(() => {
    window.scrollTo(0, scrollY.current);

    return () => {
      scrollY.current = window.scrollY;
    };
  }, [pathname]);
};

const CardDetail = () => {
  useScrollRestoration();
  const navigate = useNavigate();
  const { currentCard, resetCarousel } = useOutletContext<CardContext>();
  const { iapCdno: cardNumber, acnRmd: balance } = currentCard || {};

  const pathname = window.location.pathname;
  const lastUrlSegment = pathname.slice(pathname.lastIndexOf('/') + 1);

  return (
    cardNumber && (
      <Stack gap={1}>
        {/* <ScrollRestoration /> */}
        <CardLayout header={<CardBalance balance={balance} />}>
          <Stack direction="row" gap={1} sx={{ width: '100%' }}>
            <CustomButton
              size="medium"
              onClick={() => navigate('deposit')}
              variant={lastUrlSegment === 'deposit' ? 'contained' : 'outlined'}
            >
              <Stack
                justifyContent="center"
                alignItems="center"
                gap={0.5}
                sx={{ py: 0.5 }}
              >
                <PaidIcon fontSize="medium" />
                <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
                  Deposit / Withdraw
                </Typography>
              </Stack>
            </CustomButton>
            <CustomButton
              size="medium"
              onClick={() => navigate('transfer')}
              variant={lastUrlSegment === 'transfer' ? 'contained' : 'outlined'}
            >
              <Stack
                justifyContent="center"
                alignItems="center"
                gap={0.5}
                sx={{ py: 0.5 }}
              >
                <IosShareIcon
                  fontSize="medium"
                  sx={{
                    transform: 'rotate(90deg)',
                  }}
                />
                <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
                  Transfer Balance
                </Typography>
              </Stack>
            </CustomButton>
            <CustomButton
              size="medium"
              onClick={() => navigate('settings')}
              variant={lastUrlSegment === 'settings' ? 'contained' : 'outlined'}
            >
              <Stack
                justifyContent="space-between"
                alignItems="center"
                gap={0.5}
                sx={{ py: 0.5 }}
              >
                <SettingsIcon
                  fontSize="medium"
                  sx={{ justifyItems: 'start' }}
                />
                <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
                  Settings
                </Typography>
              </Stack>
            </CustomButton>
          </Stack>
        </CardLayout>
        {lastUrlSegment !== 'card' && (
          <CardLayout>
            <Stack sx={{ width: '100%' }}>
              <Outlet context={{ currentCard, resetCarousel }} />
            </Stack>
          </CardLayout>
        )}
      </Stack>
    )
  );
};

export default CardDetail;
