export const getURLSearchParams = () =>
  new URLSearchParams(window.location.search);

export const getTotalPassengersNumber = (params?: URLSearchParams) => {
  const searchParams = params ?? new URLSearchParams(window.location.search);

  return (
    Number(searchParams.get('adults') ?? 1) +
    Number(searchParams.get('children') ?? 0)
  );
};

export const getAdultsNumber = (params?: URLSearchParams) => {
  const searchParams = params ?? new URLSearchParams(window.location.search);
  return Number(searchParams?.get('adults') ?? 1);
};

export const getChildrensNumber = (params?: URLSearchParams) => {
  const searchParams = params ?? new URLSearchParams(window.location.search);

  return Number(searchParams?.get('children') ?? 0);
};
