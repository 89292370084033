import { SeatDetails } from "components/seat/Seat";
import { OrderType } from "types/orderType";

// 시간을 포매팅하는 함수
export const formatTime = (time: string): string => {
  const pattern = /(\d{2})(\d{2})(\d{2})/;
  return time.replace(pattern, "$1:$2");
};

// 날짜를 YYYY-MM-DD 형식으로 포맷하는 함수
export const formatDateToISOString = (date: Date): string => {
  return date.toISOString().split("T")[0];
};

// 날짜를 포매팅하는 함수
export const formatDate = (dateStr: string): string => {
  const year = dateStr.substring(0, 4);
  const month = dateStr.substring(4, 6);
  const day = dateStr.substring(6, 8);
  return `${year}-${month}-${day}`;

  // const date = new Date(`${year}-${month}-${day}`);
  // return date.toLocaleDateString("ko-KR").replace(/\./g, "");
};

// This function removes leading zeros from a string
export const removeLeadingZeros = (str: string): string => {
  return str.replace(/^0+/, "");
};

type OrderStatus = "PENDING" | "COMPLETE" | "CANCELED";

// 주문 상태를 포매팅하는 함수
export const formatOrderStatus = (statusCode: OrderStatus): string => {
  const statusMap: {
    [key in OrderStatus]: string;
  } = {
    PENDING: "orders.pending",
    COMPLETE: "orders.complete",
    CANCELED: "orders.canceled",
  };
  return statusMap[statusCode];
};

//
export const getSeatDetailsFromOrder = (order: OrderType): SeatDetails[] => {
  return order.passengerList.map((passenger) => {
    const [scarNo, seatSpec] = passenger.seats.split("호차 ");
    return {
      scarNo,
      seatSpec,
    };
  });
};
