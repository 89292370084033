import { Typography } from "@mui/material";
import useGetOrderName from "hooks/useGetOrderName";
import { useNavigate } from "react-router-dom";
import { OrderType } from "types/orderType";
import CardLayout from "components/layout/CardLayout";
import ShowTrainTicketButton from "components/reservation/ShowTrainTicketButton";
import ReservationDetails from "components/reservation/ReservationDetails";

interface ProductInfoProps {
  order: OrderType;
}

const ProductInfoSection = ({ order }: ProductInfoProps) => {
  const getOrderName = useGetOrderName();
  const navigate = useNavigate();
  // const isPending = order.orderStatus === "PENDING";

  const showTicket = () => {
    navigate(`/order-details/${order.orderId}/boarding-pass`, { state: order });
  };

  const getHeader = () => {
    return (
      <Typography variant="h6" sx={{ width: "100%" }}>
        {getOrderName(order.orderName)}
      </Typography>
    );
  };

  // 금액 표시
  // const getAdditionalInfo = () => {
  //   return (
  //     <Stack sx={{ width: "100%" }}>
  //       <Stack
  //         direction="row"
  //         justifyContent="space-between"
  //         alignItems="center"
  //         sx={{ width: "100%" }}
  //       >
  //         <Typography variant="body1" sx={{ fontWeight: "bold" }}>
  //           <FormattedMessage id="orders.price" />:
  //         </Typography>

  //         <Typography
  //           variant="h6"
  //           component="div"
  //           color="secondary"
  //           sx={{ fontWeight: "bold" }}
  //         >
  //           {!isPending && ProviderUtils.isHanacard
  //             ? formatPrice(order.totalPrice)
  //             : formatPrice(
  //                 Number(order.originalPrice) - (order.goodsList?.price ?? 0)
  //               )}
  //         </Typography>
  //       </Stack>
  //       {!isPending && ProviderUtils.isHanacard && (
  //         <PriceDetailSection order={order} />
  //       )}
  //     </Stack>
  //   );
  // };

  return (
    <CardLayout
      header={getHeader()}
      // additionalInfo={getAdditionalInfo()}
    >
      <ReservationDetails reservationDetails={order} />
      <ShowTrainTicketButton status={order.orderStatus} onClick={showTicket} />
    </CardLayout>
  );
};

export default ProductInfoSection;
