import { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import CardService from "services/cardService";

const CardRoute = () => {
  const navigate = useNavigate();
  const [cardSignedUp, setCardSignedUp] = useState(false);

  useEffect(() => {
    CardService.checkSignUp()
      .then(() => {
        setCardSignedUp(true);
      })
      .catch(() => {
        navigate("/card/signup");
      });
  });
  return cardSignedUp && <Outlet />;
};

export default CardRoute;
