import { Stack } from '@mui/material';
import { useOutletContext } from 'react-router-dom';
import { CardContext } from './CardPageLayout';
import CardDetail from './CardDetail';
import RecentTransaction from './RecentTransaction';

const CardHome = () => {
  const { currentCard } = useOutletContext<CardContext>();
  const { iapCrdStcd: status } = currentCard || {};

  return (
    status && (
      <Stack gap={1}>
        <CardDetail />
        <RecentTransaction />
      </Stack>
    )
  );
};

export default CardHome;
