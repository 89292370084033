import axios from "axios";
import apiBaseUrl from "const/properties";

const timeService = axios.create({
  baseURL: apiBaseUrl + "/api",
});

class TimeService {
  static async getCurrentTime(): Promise<string> {
    try {
      const response: { data: string } = await timeService.get("/hello");
      return response.data;
    } catch (error) {
      throw error;
    }
  }
}

export default TimeService;
