interface CardLogoProps {
  src: string;
  alt?: string;
}

const CardLogo = ({ src, alt }: CardLogoProps) => {
  return <img src={src} alt={alt} style={{ height: "28px" }} />;
};

export default CardLogo;
