import { useEffect } from "react";
import { Card, Divider, Stack } from "@mui/material";
import { styled } from "@mui/material/styles";
import { FormattedMessage } from "react-intl";
import LoginLinks from "./LoginLinks";
import LoginForm from "./LoginForm";
import useCheckIsLoginPage from "hooks/useCheckIsLoginPage";
import SocialLoginButton from "components/social_login/SocialLoginButton";
import { ProviderUtils } from "utils/providerUtils";

interface LoginContentProps {
  isModal: boolean;
  closeModal?: () => void;
}

const LoginContent = ({ isModal, closeModal }: LoginContentProps) => {
  const isLoginPage = useCheckIsLoginPage();

  useEffect(() => {
    // reset location.state when rendering
    window.history.replaceState({}, "");
  }, []);

  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      sx={{
        mt: isLoginPage ? 9 : 0,
        width: "100%",
      }}
    >
      {!isLoginPage && (
        <h3 style={{ paddingInlineStart: 16, alignSelf: "start" }}>
          <FormattedMessage id="user.signIn" />
        </h3>
      )}
      <LoginContainer isLoginPage={isLoginPage}>
        <LoginForm closeModal={closeModal} />
        <Divider />

        {!ProviderUtils.isDollarProvider && (
          <Stack
            // direction="row"
            justifyContent="center"
            alignItems="center"
            gap={1}
          >
            {ProviderUtils.isMame && (
              <SocialLoginButton loginProvider="LINE" closeModal={closeModal} />
            )}
            <SocialLoginButton loginProvider="NAVER" closeModal={closeModal} />
            <SocialLoginButton loginProvider="KAKAO" closeModal={closeModal} />
          </Stack>
        )}
        <LoginLinks isModal={isModal} />
      </LoginContainer>
    </Stack>
  );
};

export default LoginContent;

const LoginContainer = styled(Card, {
  shouldForwardProp: (prop) => prop !== "isLoginPage",
})<{ isLoginPage: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 18px;

  width: 100%;
  max-width: 400px;
  margin: ${(props) => (props.isLoginPage ? "16px auto" : "8px 0")};
  padding: 16px;

  /* @media screen and (max-width: 768px) {
  } */
`;
