import { useEffect } from "react";

const PointParkComplete = () => {
  useEffect(() => {
    const iframe = window.top?.document.querySelector("iframe");
    iframe?.parentElement?.setAttribute("style", "display:none");
    window.top?.location.reload();
  }, []);

  return <></>;
};

export default PointParkComplete;
