import React from "react";
import { Box, Typography, Button } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { css } from "styled-components";
import Stepper from "./sections/Stepper"; // 프로세스의 단계를 표시하는 컴포넌트
import { Step } from "./sections/Step"; // 각 단계를 나타내는 컴포넌트

const HanpassCompletionPage = () => {
  const activeStep = 2;
  return (
    <Box sx={{ textAlign: "center", p: 3 }}>
      <Box height={20} /> {/* 여백 추가 */}
      {/* 현재 단계를 표시하는 스테퍼 컴포넌트 */}
      <Stepper activeStep={activeStep}>
        {[1, 2, 3].map(({ label, Icon }, index) => (
          <Step key={label} id={index}>
            <Icon className={css({ marginInlineEnd: "10px" })} />
            <span className={css({ textStyle: "h3" })}>{label}</span>
          </Step>
        ))}
      </Stepper>
      <Box height={10} /> {/* 추가 여백 */}
      <CheckCircleOutlineIcon sx={{ fontSize: 60, color: "primary.main" }} />
      <Typography variant="h4" sx={{ mt: 2, mb: 1 }}>
        회원가입 완료!
      </Typography>
      <Typography variant="subtitle1" sx={{ mb: 3 }}>
        회원가입이 성공적으로 완료되었습니다. 지금 바로 로그인하여 서비스를
        이용해 보세요.
      </Typography>
      <Button
        variant="text"
        color="primary"
        href="/"
        sx={{ marginInlineStart: 2 }}
      >
        홈페이지로 이동하기
      </Button>
    </Box>
  );
};

export default HanpassCompletionPage;
