import React from "react";
import { FormattedMessage } from "react-intl";

const useFormatDuration = () => {
  const formatDuration = (time: string | number): React.ReactElement => {
    let hour: number;
    let minute: number;
    if (typeof time === "string") {
      hour = Number(time.slice(0, 2));
      minute = Number(time.slice(2, 4));
    } else {
      hour = Math.floor(time / 60);
      minute = time % 60;
    }

    return (
      <FormattedMessage
        id="fareInquiry.timeFormat"
        values={{
          hour,
          minute,
        }}
      />
    );
  };

  return formatDuration;
};

export default useFormatDuration;
