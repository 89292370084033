import {
  Stack,
  TextField,
  Container,
  Typography,
  Grid,
  FormControl,
  Select,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import Card from "components/card/Card";
import CustomButton from "components/button/CustomButton";
import CardLayout from "components/layout/CardLayout";
import Layout from "components/layout/Layout";
import useValidate from "hooks/useValidate";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import CardBack from "components/card/CardBack";
import { NumericString } from "types/numericString";
import CardService from "services/cardService";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "components/LoadingSpinner";
import { useIntl } from "react-intl";

const getCardNumberForUI = (cardNumber: string) => {
  const cn1 = cardNumber.slice(0, 4).padEnd(4, "_");
  const cn2 = cardNumber.slice(4, 8).replaceAll(/\d/g, "*").padEnd(4, "_");
  const cn3 = cardNumber.slice(8, 12).replaceAll(/\d/g, "*").padEnd(4, "_");
  const cn4 = cardNumber.slice(12, 16).padEnd(4, "_");

  return `${cn1}${cn2}${cn3}${cn4}`;
};

const getCardExpirationDateForUI = (expirationDate: string) => {
  const month = expirationDate.slice(0, 2).padStart(2, "__");
  const year = expirationDate.slice(2, 4).padStart(2, "__");

  return `${month} / ${year}`;
};

const isNumericString = (str: string) => {
  return /^\d*$/.test(str);
};

const initialValidState = {
  cardName: true,
  cardNumber1: true,
  cardNumber2: true,
  cardNumber3: true,
  cardNumber4: true,
  cvc: true,
};

const CardRegister = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const [, validate] = useValidate(initialValidState);
  const [isFlipped, setIsFlipped] = useState(false);

  const [cardNumber1, setCardNumber1] = useState("");
  const [cardNumber2, setCardNumber2] = useState("");
  const [cardNumber3, setCardNumber3] = useState("");
  const [cardNumber4, setCardNumber4] = useState("");
  const cardNumber = cardNumber1 + cardNumber2 + cardNumber3 + cardNumber4;

  const cardNameSetterFunctions = {
    cardNumber1: setCardNumber1,
    cardNumber2: setCardNumber2,
    cardNumber3: setCardNumber3,
    cardNumber4: setCardNumber4,
  };

  const [expirationDateMonth, setExpirationDateMonth] = useState("");
  const [expirationDateYear, setExpirationDateYear] = useState("");
  const expirationDate = `${
    expirationDateMonth ? expirationDateMonth : "__"
  }${expirationDateYear}`;

  const [CVC, setCVC] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    switch (name) {
      // case "cardName":
      //   validate(e);
      //   setCardName(value);
      //   break;
      case "cardNumber1":
      case "cardNumber2":
      case "cardNumber3":
      case "cardNumber4":
        validate(e);
        if (isNumericString(value)) {
          cardNameSetterFunctions[name](value);
        }
        break;
      case "cvc":
        validate(e);
        if (isNumericString(value)) {
          setCVC(value);
        }
        break;
      default:
        break;
    }
  };

  const handleChangeExpirationDate = (e: SelectChangeEvent<string>) => {
    const { name, value } = e.target;

    switch (name) {
      case "expirationDateMonth":
        setExpirationDateMonth(value);
        break;
      case "expirationDateYear":
        setExpirationDateYear(value);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    document.body.addEventListener("click", () => {
      setIsFlipped(false);
    });
  });

  const registerCard = async () => {
    setIsLoading(true);
    try {
      const response = await CardService.addNewCard(
        cardNumber as NumericString,
        `${expirationDateYear}${expirationDateMonth}`,
        CVC as NumericString
      );
      if (response.data !== null) {
        navigate("/card");
        return;
      } else {
        const errorMessages = response.result_msg;

        alert(
          errorMessages[intl.locale as keyof typeof errorMessages] ??
            errorMessages.ko
        );
      }
    } catch (error) {
      alert("카드 등록 실패");
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Layout text="Register Card">
      <Container maxWidth="sm" sx={{ width: "100%", mt: 2, px: 0 }}>
        <Stack gap={2} sx={{ perspective: "5000px" }}>
          <AnimationWrapper
            $isFlipped={isFlipped}
            onClick={(e) => {
              e.stopPropagation();
              setIsFlipped(!isFlipped);
            }}
          >
            <CardFrontWrapper $isFlipped={isFlipped}>
              <Card
                cardNumber={getCardNumberForUI(cardNumber)}
                expirationDate={getCardExpirationDateForUI(expirationDate)}
                isRegister
              />
            </CardFrontWrapper>

            <CardBackWrapper $isFlipped={isFlipped}>
              <CardBack cvc={CVC || "___"} />
            </CardBackWrapper>
          </AnimationWrapper>

          <CardLayout>
            <Grid
              container
              justifyContent="space-between"
              gap={2}
              sx={{
                width: "100%",
              }}
            >
              <Grid item xs={12}>
                <Stack gap={0.5}>
                  <label>
                    <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                      Card Number
                    </Typography>
                  </label>
                  <Stack direction="row" gap={1}>
                    <TextField
                      fullWidth
                      type="text"
                      name="cardNumber1"
                      value={cardNumber1}
                      onChange={handleChange}
                      InputProps={{
                        inputProps: {
                          maxLength: 4,
                          sx: {
                            textAlign: "center",
                            p: 0,
                            height: 32,
                          },
                        },
                      }}
                    />
                    <TextField
                      fullWidth
                      type="password"
                      name="cardNumber2"
                      value={cardNumber2}
                      onChange={handleChange}
                      InputProps={{
                        inputProps: {
                          maxLength: 4,
                          sx: {
                            textAlign: "center",
                            p: 0,
                            height: 32,
                          },
                        },
                      }}
                    />
                    <TextField
                      fullWidth
                      type="password"
                      name="cardNumber3"
                      value={cardNumber3}
                      onChange={handleChange}
                      InputProps={{
                        inputProps: {
                          maxLength: 4,
                          sx: {
                            textAlign: "center",
                            p: 0,
                            height: 32,
                          },
                        },
                      }}
                    />
                    <TextField
                      fullWidth
                      type="text"
                      name="cardNumber4"
                      value={cardNumber4}
                      onChange={handleChange}
                      InputProps={{
                        inputProps: {
                          maxLength: 4,
                          sx: {
                            textAlign: "center",
                            p: 0,
                            height: 32,
                          },
                        },
                      }}
                    />
                  </Stack>
                </Stack>
              </Grid>

              <Grid item xs={8}>
                <Stack gap={0.5}>
                  <label>
                    <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                      {/* Expiration Date (MM/YY) */}
                      Expiration Date
                    </Typography>
                  </label>
                  <Stack direction="row" alignItems="center" gap={1}>
                    <FormControl fullWidth>
                      <Select
                        name="expirationDateMonth"
                        value={expirationDateMonth}
                        onChange={handleChangeExpirationDate}
                        displayEmpty
                        sx={{
                          height: 32,
                          textAlign: "center",
                        }}
                        IconComponent={() => null}
                        inputProps={{ sx: { p: "0 !important" } }}
                      >
                        <MenuItem value="" dense selected divider disabled>
                          <Typography color="text.secondary">Month</Typography>
                        </MenuItem>
                        {Array.from({ length: 12 }, (_, index) => (
                          <MenuItem
                            key={index}
                            value={String(index + 1).padStart(2, "0")}
                            dense
                          >
                            {String(index + 1).padStart(2, "0")}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                      /
                    </Typography>
                    <FormControl fullWidth>
                      <Select
                        name="expirationDateYear"
                        value={expirationDateYear}
                        onChange={handleChangeExpirationDate}
                        displayEmpty
                        sx={{
                          height: 32,
                          textAlign: "center",
                        }}
                        IconComponent={() => null}
                        inputProps={{ sx: { p: "0 !important" } }}
                      >
                        <MenuItem value="" dense selected divider disabled>
                          <Typography color="text.secondary">Year</Typography>
                        </MenuItem>
                        {Array.from({ length: 20 }, (_, index) => {
                          const currentYear = new Date().getFullYear();
                          return (
                            <MenuItem
                              key={index}
                              value={String(currentYear + index - 2000)}
                              dense
                            >
                              {currentYear + index}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Stack>
                </Stack>
              </Grid>
              <Grid item xs={3} onClick={(e) => e.stopPropagation()}>
                <Stack gap={0.5}>
                  <label>
                    <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                      CVC
                    </Typography>
                  </label>
                  <TextField
                    fullWidth
                    type="text"
                    name="cvc"
                    value={CVC}
                    onChange={handleChange}
                    InputProps={{
                      inputProps: {
                        maxLength: 3,
                        sx: {
                          textAlign: "center",
                          p: 0,
                          height: 32,
                        },
                      },
                    }}
                    onFocus={() => {
                      setIsFlipped(true);
                    }}
                    onBlur={() => {
                      setIsFlipped(false);
                    }}
                  />
                </Stack>
              </Grid>

              <Grid item xs={12}>
                <CustomButton size="medium" onClick={registerCard}>
                  Register
                </CustomButton>
              </Grid>
            </Grid>
          </CardLayout>
        </Stack>
      </Container>
      {isLoading && <LoadingSpinner overlap />}
    </Layout>
  );
};

export default CardRegister;

const AnimationWrapper = styled("div")<{ $isFlipped: boolean }>`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;
  transform-style: "preserve-3d";
  transition: transform 0.6s cubic-bezier(0.455, 0.03, 0.515, 0.955);
  transform: ${(props) =>
    props.$isFlipped ? "rotateY(180deg)" : "rotateY(0deg)"};
`;

const CardWrapper = styled("div")`
  width: 100%;
  position: absolute;
  transition: opacity 0.6s cubic-bezier(1, 0, 0, 1);
`;

const CardFrontWrapper = styled(CardWrapper)<{ $isFlipped: boolean }>`
  z-index: ${(props) => (props.$isFlipped ? 0 : 2)};
  opacity: ${(props) => (props.$isFlipped ? 0 : 1)};
`;

const CardBackWrapper = styled(CardWrapper)<{ $isFlipped: boolean }>`
  position: relative;
  z-index: ${(props) => (props.$isFlipped ? 2 : 0)};
  opacity: ${(props) => (props.$isFlipped ? 1 : 0)};
  transform: rotateY(180deg);
`;
