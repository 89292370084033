import parse from "html-react-parser";
import { useIntl } from "react-intl";
import Layout from "components/layout/Layout";

const PrivacyPolicy = () => {
  const intl = useIntl();

  return (
    <Layout text={intl.formatMessage({ id: "consent.privacyTitle" })}>
      <div style={{ padding: "0 16px", lineHeight: 1 }}>
        {parse(`${intl.formatMessage({ id: "signup.privacyContent" })}`)}
      </div>
    </Layout>
  );
};

export default PrivacyPolicy;
