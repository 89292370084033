import { Stack } from "@mui/material";
import PassengerCounter from "./sections/PassengerCounter";
import DateInformation from "./sections/DateInformation";
import CustomButton from "components/button/CustomButton";
import { useIntl } from "react-intl";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  getAdultsNumber,
  getChildrensNumber,
  getURLSearchParams,
} from "utils/urlSearchParamsUtils";

interface PassengerSelectionPros {
  closeWindow: () => void;
}

const PassengerSelection = ({ closeWindow }: PassengerSelectionPros) => {
  const navigate = useNavigate();
  const intl = useIntl();

  const [adults, setAdults] = useState(getAdultsNumber);
  const [children, setChildren] = useState(getChildrensNumber);
  const total = adults + children;

  const handleSubmit = () => {
    // Check if at least one adult is selected
    if (adults < 1) {
      alert(intl.formatMessage({ id: "passengerSelection.noAdultError" }));
      return;
    }

    const params = getURLSearchParams();

    params.set("adults", adults.toString());
    params.set("children", children.toString());

    navigate(`?${params.toString()}`);
    closeWindow();
  };

  return (
    <Stack gap={1} sx={{ mt: 8, mb: 2 }} alignItems="center">
      <Stack direction="row" gap={1} sx={{ width: "100%" }}>
        <PassengerCounter
          label="adults"
          count={adults}
          total={total}
          onIncrement={() => setAdults((prev) => ++prev)}
          onDecrement={() => setAdults((prev) => --prev)}
        />
        <PassengerCounter
          label="children"
          count={children}
          total={total}
          onIncrement={() => setChildren((prev) => ++prev)}
          onDecrement={() => setChildren((prev) => --prev)}
        />
      </Stack>
      <DateInformation />
      <CustomButton
        id="passengerSelection.confirmed"
        onClick={handleSubmit}
        disabled={adults + children > 9}
      />
    </Stack>
  );
};

export default PassengerSelection;
