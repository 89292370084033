import { Typography, Stack, useMediaQuery } from "@mui/material";
import useFormatDateToLocaleString from "hooks/useFormatDateToLocaleString";
import useTranslateStation from "hooks/useTranslateStation";
import { TicketDetail } from "./BookingInfos";
import { OrderType } from "types/orderType";

interface StationInfoProps {
  status: "DEPARTURE" | "ARRIVAL";
  detail: Pick<TicketDetail, "departure" | "arrival"> &
    Pick<OrderType, "startDate" | "endDate">;
}

const StationInfo = ({ status, detail }: StationInfoProps) => {
  // hooks
  const matches = useMediaQuery("(min-width:321px)");
  const translateStation = useTranslateStation();
  const formatDateTime = useFormatDateToLocaleString();

  // data
  const { departure, arrival, startDate, endDate } = detail;

  return (
    <Stack direction="column" alignItems="center">
      <Stack alignItems="center">
        <Typography
          variant={matches ? "body1" : "caption"}
          sx={{ fontWeight: "bold" }}
        >
          {translateStation(status === "DEPARTURE" ? departure : arrival)}
        </Typography>
        <Typography
          variant={matches ? "h5" : "h6"}
          component="div"
          sx={{ fontWeight: "medium", textAlign: "center" }}
        >
          {formatDateTime(status === "DEPARTURE" ? startDate : endDate, {
            hour: "2-digit",
            hour12: false,
            minute: "2-digit",
          })}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default StationInfo;
