import { useIntl } from "react-intl";
import { OrderStatus } from "types/orderType";
import { formatOrderStatus } from "utils/formatUtils";

const useOrderStatusText = () => {
  const intl = useIntl();

  const getOrderStatusText = (statusCode: OrderStatus) => {
    const statusMap = intl.formatMessage({ id: formatOrderStatus(statusCode) });
    return statusMap || "Unknown Status";
  };
  return getOrderStatusText;
};

export default useOrderStatusText;
