import { useIntl } from "react-intl";
import { Box, Divider, Grid, Stack } from "@mui/material";
import Typography from "@mui/material/Typography";
import seatForwardDisabled from "assets/images/seat/seat_forward_disabled.png";
import seatForwardNormal from "assets/images/seat/seat_forward_normal.png";
import seatForwardSelect from "assets/images/seat/seat_forward_select.png";
import seatReverseNormal from "assets/images/seat/seat_reverse_normal.png";
import useTranslateStation from "hooks/useTranslateStation";
import styled from "styled-components";
import TrainLogo from "components/TrainLogo";
import { ScheduleType } from "types/scheduleType";
import { CarType } from "types/carType";

const SeatInfoIcon = styled.img`
  width: 24px;
  height: 24px;
  margin-inline-end: 4px;
`;

interface RestSeat {
  restSeatNum: number;
  scarNo: string;
  seatNum: number;
}

interface TrainInfoIndicatorProps {
  schedule: ScheduleType;
  car: CarType;
  restSeat: RestSeat;
}

const TrainInfoIndicator = ({
  schedule,
  car,
  restSeat,
}: TrainInfoIndicatorProps) => {
  const intl = useIntl();
  const translateStation = useTranslateStation();

  return (
    <Stack
      sx={{
        p: 2,
      }}
      gap={1}
    >
      <Box
        sx={{
          // display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Stack direction="row" alignItems="center" gap={1}>
          <TrainLogo stlbTrnClsfCd={schedule.stlbTrnClsfCd} size="large" />

          <Box>
            <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
              {intl.formatMessage({ id: "seatSelection.itinerary" })}
              {`${translateStation(schedule.dptRsStnNm)} → ${translateStation(
                schedule.arvRsStnNm
              )} ${schedule.trnGpNm}`}{" "}
              {intl.formatMessage(
                { id: "seatSelection.trainNumber" },
                { number: +schedule.trnNo }
              )}
            </Typography>
            <Typography variant="subtitle1">
              {intl.formatMessage(
                { id: "seatSelection.carNumber" },
                { number: +car.scarNo }
              )}{" "}
              {intl.formatMessage(
                { id: "seatSelection.remaining" },
                { rest: restSeat.restSeatNum, all: restSeat.seatNum }
              )}
            </Typography>
          </Box>
        </Stack>
      </Box>

      <Divider />

      <Grid container spacing={1}>
        <Grid item xs={6}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              marginInlineEnd: "8px",
            }}
          >
            <SeatInfoIcon src={seatForwardNormal} alt="순방향" />
            <Typography variant="body2">
              {intl.formatMessage({ id: "seatSelection.forward" })}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              marginInlineEnd: "8px",
            }}
          >
            <SeatInfoIcon src={seatReverseNormal} alt="역방향" />
            <Typography variant="body2">
              {intl.formatMessage({ id: "seatSelection.reverse" })}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              marginInlineEnd: "8px",
            }}
          >
            <SeatInfoIcon src={seatForwardDisabled} alt="예매불가" />
            <Typography variant="body2">
              {intl.formatMessage({ id: "seatSelection.unavailable" })}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <SeatInfoIcon src={seatForwardSelect} alt="선택좌석" />
            <Typography variant="body2">
              {intl.formatMessage({ id: "seatSelection.selected" })}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default TrainInfoIndicator;
