import { useParams } from "react-router-dom";
import { dummyActivities } from "../ActivityHome";
import { Box, Chip, Divider, Stack, Typography, useTheme } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import DoDisturbIcon from "@mui/icons-material/DoDisturb";
import BookOnlineIcon from "@mui/icons-material/BookOnline";
import LanguageIcon from "@mui/icons-material/Language";
import StarRates from "components/StarRates";

const ActivityHeroSection = () => {
  const { activityId } = useParams();
  const theme = useTheme();

  const activity = dummyActivities[Number(activityId)];

  return (
    <Stack sx={{ bgcolor: theme.palette.white.main, px: 2 }} gap={2}>
      {/* <Box
        sx={{
          backgroundImage: `url(${activity.image})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          height: "calc(100vw - 64px)",
          width: "calc(100vw)",
          minHeight: "280px",
          minWidth: "280px",
          maxHeight: "360px",

          position: "relative",
          left: -16,
        }}
      /> */}

      {/* 상품 정보 */}
      <Stack gap={2} sx={{ pb: 2 }}>
        <Stack gap={2} sx={{ width: "100%" }}>
          <Typography variant="h6" sx={{ fontWeight: "bold", lineHeight: 1.2 }}>
            {activity.title}
          </Typography>
          <Stack gap={1}>
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{ fontWeight: "bold", lineHeight: 1.2 }}
            >
              {activity.description}
            </Typography>

            {/* 별점 */}
            <Stack direction="row" gap={1}>
              <StarRates rate={activity.rate} />
              <Typography
                variant="caption"
                sx={{ fontWeight: "bold", alignSelf: "end", lineHeight: 1.2 }}
              >
                {activity.rate}점
              </Typography>
            </Stack>
          </Stack>
        </Stack>

        <Divider />

        <Stack>
          {/* icons */}
          <Stack justifyContent="start" gap={1}>
            {/* <Stack direction="row" alignItems="center" gap={0.5}> */}
            <Box>
              <Chip
                label="즉시확정"
                color="primary"
                variant="outlined"
                size="small"
                sx={{
                  fontWeight: "bold",
                  px: 0,
                  py: 1.5,
                  mr: 1,
                  borderRadius: "4px",
                }}
              />
              <Typography variant="caption" sx={{ display: "inline" }}>
                예약 후 즉시 구매 (예약을 하시면 교환, 환불이 불가능합니다.)
              </Typography>
            </Box>
            {/* </Stack> */}
            <Stack direction="row" alignItems="center" gap={0.5}>
              <DoDisturbIcon fontSize="small" />
              <Typography variant="caption">취소불가</Typography>
            </Stack>
            <Stack direction="row" alignItems="center" gap={0.5}>
              <CreditCardIcon fontSize="small" />
              <Typography variant="caption">
                최대 00갸월 무이자 할부 가능
              </Typography>
            </Stack>
            <Stack direction="row" alignItems="center" gap={0.5}>
              <BookOnlineIcon fontSize="small" />
              <Typography variant="caption">e-티켓</Typography>
            </Stack>
            <Stack direction="row" alignItems="center" gap={0.5}>
              <LanguageIcon fontSize="small" />
              <Typography variant="caption">한국어/영어/일어/중국어</Typography>
            </Stack>
          </Stack>
        </Stack>

        <Divider />

        <Stack gap={0.5}>
          <Stack direction="row" gap={0.5}>
            <ErrorOutlineIcon />
            <Typography variant="body1" style={{ fontWeight: "bold" }}>
              Notice
            </Typography>
          </Stack>
          <Typography variant="caption">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Enim
            doloremque officia voluptatum, nobis rem, iusto quis et neque sit
            minus eius eos laborum numquam inventore sint atque tempora
            blanditiis nemo.
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default ActivityHeroSection;
