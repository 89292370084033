// https://stackoverflow.com/questions/41892470/how-to-reexport-from-a-module-that-uses-export
/* import en from "assets/locales/en.json";
import ko from "assets/locales/ko.json";
import vi from "assets/locales/vi.json";
import es from "assets/locales/es.json";
import ru from "assets/locales/ru.json";
import zh from "assets/locales/zh.json";
import uz from "assets/locales/uz.json";
import th from "assets/locales/th.json";
import mn from "assets/locales/mn.json";
import my from "assets/locales/my.json";
import km from "assets/locales/km.json";
import id from "assets/locales/id.json";
import ne from "assets/locales/ne.json";
import tl from "assets/locales/tl.json";
import si from "assets/locales/si.json";
import bn from "assets/locales/bn.json";
import ur from "assets/locales/ur.json";
import ja from "assets/locales/ja.json";

export {
  en,
  ko,
  es,
  vi,
  ru,
  zh,
  uz,
  th,
  mn,
  my,
  km,
  id,
  ne,
  tl,
  si,
  bn,
  ur,
  ja,
 */

import { LanguageCodeType } from "const/languageCodes";

export const loadLocaleData = (locale: LanguageCodeType) => {
  // switch (locale) {
  //   case "en":
  //     return import("assets/locales/en.json");
  //   case "ko":
  //     return import("assets/locales/ko.json");
  //   case "es":
  //     return import("assets/locales/es.json");
  //   case "vi":
  //     return import("assets/locales/vi.json");
  //   case "ru":
  //     return import("assets/locales/ru.json");
  //   case "zh":
  //     return import("assets/locales/zh.json");
  //   case "uz":
  //     return import("assets/locales/uz.json");
  //   case "th":
  //     return import("assets/locales/th.json");
  //   case "mn":
  //     return import("assets/locales/mn.json");
  //   case "my":
  //     return import("assets/locales/my.json");
  //   case "km":
  //     return import("assets/locales/km.json");
  //   case "id":
  //     return import("assets/locales/id.json");
  //   case "ne":
  //     return import("assets/locales/ne.json");
  //   case "tl":
  //     return import("assets/locales/tl.json");
  //   case "si":
  //     return import("assets/locales/si.json");
  //   case "bn":
  //     return import("assets/locales/bn.json");
  //   case "ur":
  //     return import("assets/locales/ur.json");
  //   case "ja":
  //     return import("assets/locales/ja.json");
  // }

  return import(`assets/locales/${locale}.json`);
};
