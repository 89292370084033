export const languageCodes = [
  { code: "ko", name: "한국어" },
  { code: "en", name: "English" },
  { code: "zh", name: "中文" },
  { code: "es", name: "Español" },
  { code: "ja", name: "日本語" },
  { code: "vi", name: "Tiếng Việt" },
  { code: "ru", name: "Русский" },
  { code: "uz", name: "o'zbek" },
  { code: "th", name: "แบบไทย" },
  { code: "mn", name: "Монгол" },
  { code: "my", name: "မြန်မာ" },
  { code: "km", name: "ខ្មែរ" },
  { code: "id", name: "bahasa Indonesia" },
  { code: "ne", name: "नेपाली" },
  { code: "tl", name: "Filipino" },
  { code: "si", name: "සිංහල" },
  { code: "bn", name: "বাংলা" },
  { code: "ur", name: "اردو" },
] as const;

export type LanguageCodeType = (typeof languageCodes)[number]["code"];
export type LanguageNameType = (typeof languageCodes)[number]["name"];
