import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { useIntl } from "react-intl";
import { styled } from "@mui/material/styles";
import { LanguageCodeType } from "const/languageCodes";
import arrow from "assets/images/ico_arrow3.png";

interface ArrowProps {
  variant?: "small" | "large";
}

const Arrow = ({ variant = "small" }: ArrowProps) => {
  const { locale } = useIntl() as { locale: LanguageCodeType };

  return variant === "small" ? (
    <SmallArrow locale={locale} />
  ) : (
    <LargeArrow locale={locale}>
      <img src={arrow} alt="" style={{ margin: "auto", display: "block" }} />
    </LargeArrow>
  );
};

export default Arrow;

const SmallArrow = styled(ArrowRightAltIcon)<{ locale: LanguageCodeType }>`
  transform: ${(props) => props.locale === "ur" && "scaleX(-1)"};
`;

const LargeArrow = styled("div")<{ locale: LanguageCodeType }>`
  margin: 0 auto;
  transform: ${(props) => props.locale === "ur" && "scaleX(-1)"};
`;
