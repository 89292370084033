import { Card, Divider, Stack, SxProps } from "@mui/material";
import React from "react";

interface CardLayoutProps {
  children: React.ReactNode;
  header?: React.ReactNode;
  additionalInfo?: React.ReactNode;
  sx?: React.CSSProperties | SxProps;
}

const CardLayout = ({
  header,
  children,
  additionalInfo,
  sx,
}: CardLayoutProps) => {
  return (
    <Card
      sx={{
        p: 2,
        ...sx,
      }}
    >
      <Stack alignItems="center" gap={1} sx={{ px: 0 }}>
        {header ? (
          <>
            <Stack
              direction="row"
              alignItems="flex-start"
              sx={{ width: "100%" }}
            >
              {header}
            </Stack>
            <Divider flexItem />
          </>
        ) : null}

        {children}

        {additionalInfo ? (
          <>
            <Divider flexItem />
            {additionalInfo}
          </>
        ) : null}
      </Stack>
    </Card>
  );
};

export default CardLayout;
