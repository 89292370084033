import { useTheme } from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import AuthService from "services/authService";
import { useNavigate } from "react-router-dom";
import { deleteUser } from "app/userSlice";
import { deleteReservation } from "app/reservationSlice";
import { deleteMyOrders } from "app/myOrdersSlice";
import { useAppDispatch } from "app/hooks";
import CustomButton from "./CustomButton";
import { resetCard } from "app/cardsSlice";

const LogoutButton = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const theme = useTheme();

  const handleLogout = async () => {
    AuthService.logout().then(() => {
      dispatch(deleteUser());
      dispatch(deleteReservation());
      dispatch(deleteMyOrders());
      dispatch(resetCard());
      navigate("/");
    });
  };

  return (
    <CustomButton
      style={{ backgroundColor: theme.palette.white.main }}
      variant="outlined"
      onClick={handleLogout}
    >
      <LogoutIcon />
      Logout
    </CustomButton>
  );
};

export default LogoutButton;
