import { TicketDetail } from "components/booking_info/BookingInfos";
import useFormatDuration from "hooks/useFormatDuration";
import { useIntl } from "react-intl";
import { OrderType } from "types/orderType";
import { getSeatDetailsFromOrder } from "utils/formatUtils";

export interface History {
  isHistory: true;
  order: OrderType;
  qrCode: React.ReactElement;
}

// 승객 정보 계산 hook
const useGetPassengerDetails = (order: OrderType) => {
  const intl = useIntl();
  const { adultCount, childrenCount } = order;

  return intl.formatMessage(
    { id: "booking.passengersDetail" },
    {
      total: adultCount + childrenCount,
      adults: adultCount,
      children: childrenCount,
    }
  );
};

// 티켓 상세 정보 계산 hook
export const useGetTicketDetails = (order: OrderType) => {
  const formatDuration = useFormatDuration();
  const passengerDetails = useGetPassengerDetails(order);

  const ticketDetails: TicketDetail = {
    date: order.startDate.split("T")[0],
    departure: order.trainList[0].startStationName,
    arrival: order.trainList[0].destStationName,
    trainType: order.trainList[0].trainType,
    trainNumber: order.trainList[0].trainCode,
    departureTime: order.startDate,
    arrivalTime: order.endDate,
    duration: formatDuration(order.trainList[0].rideDuration),
    passengerDetails,
    seatDetails: getSeatDetailsFromOrder(order),
  };
  return ticketDetails;
};
