import useLanguages from "hooks/useLanguages";
import { Helmet } from "react-helmet-async";

const Metatag = () => {
  const { isKorean } = useLanguages();

  const title = isKorean ? "라차" : "Lacucaracha";
  const description = isKorean
    ? "라차에서 KTX 할인받고 편리하게 여행하세요."
    : "With Lacha, get KTX discounts and travel conveniently.";

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />

      <meta property="og:url" content="https://lacha.co.kr" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:site_name" content={title} />
      <meta property="og:description" content={description} />

      <meta name="twitter:url" content="https://lacha.co.kr" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:site_name" content={title} />
      <meta name="twitter:description" content={description} />
    </Helmet>
  );
};

export default Metatag;
