import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { CostResponse } from "services/trainService";
import type { OneDigitNumericString } from "types/numericString";

export interface SearchParams {
  [key: string]: SearchParams[keyof SearchParams] | undefined;
  passengerDetails: PassengerDetail[];
  // departure: string;
  // arrival: string;
  // date: string;
  // departureTime: `${number}`;
  // passengers: number;
  // adults: number;
  // children: number;
  // departureCode: string;
  // arrivalCode: string;
}

interface SelectedSeat {
  scarNo: string;
  seatNo: string;
  seatSpec: string;
}

export interface PassengerDetail {
  name: string;
  birthDate: string;
  seat: SelectedSeat;
}

export type SeatAppFlgType = "Y" | "N"; // 좌석 지정 플래그

export interface TrainParams {
  [key: string]: TrainParams[keyof TrainParams];
  selectedSeats: SelectedSeat[];
  costDetails?: CostResponse[];
  seatAppFlg?: SeatAppFlgType;
  trnCnt?: string;
  trnPsrmClCd?: OneDigitNumericString;
  couponAmount?: number;
  pointSavingRt?: number;
}

export interface ReservationState {
  searchParams: SearchParams;
  trainParams: TrainParams;
}

const initialState: ReservationState = {
  searchParams: {
    passengerDetails: [],
    // departure: "", // 출발지
    // arrival: "", // 도착지
    // departureCode: "", // 출발지 코드
    // arrivalCode: "", // 도착지 코드
    // date: "", // 출발 날짜
    // passengers: 1, // 총 승객 수
    // adults: 1, // 성인 수
    // children: 0, // 어린이 수
    // departureTime: "000000",
    // passengerDetails: [
    //   // 탑승자 정보 배열
    //   {
    //     name: "", // 탑승자 이름
    //     birthDate: "", // 생년월일
    //   },
    // ],
  },
  trainParams: {
    selectedSeats: [], // 선택된 좌석
    costDetails: [], // 비용 세부사항
    // trnNo: "", // 기차 번호
    // trnGpCd: "", // 기차 그룹 코드
    // dptRsStnCd: "", // 출발역 코드
    // arvRsStnCd: "", // 도착역 코드
    // trnPsrmClCd: "", // 열차 객실 등급 코드
  },
};

const reservationSlice = createSlice({
  name: "reservation",
  initialState,
  reducers: {
    updateSearchParams: (
      state,
      action: PayloadAction<Partial<SearchParams>>
    ) => {
      for (const key in action.payload) {
        state.searchParams[key] = action.payload[key];
      }
      // state.searchParams = action.payload;
    },
    updateTrainParams: (state, action: PayloadAction<Partial<TrainParams>>) => {
      for (const key in action.payload) {
        state.trainParams[key] = action.payload[key];
      }
      // state.trainParams = action.payload;
    },
    deleteReservation: () => {
      return initialState;
    },
  },
  selectors: {
    selectSearchParams: (reservation: ReservationState) =>
      reservation.searchParams,
    selectTrainParams: (reservation: ReservationState) =>
      reservation.trainParams,
  },
});

export const { updateSearchParams, updateTrainParams, deleteReservation } =
  reservationSlice.actions;

export const { selectSearchParams, selectTrainParams } =
  reservationSlice.selectors;

export default reservationSlice.reducer;
