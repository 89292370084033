import { createSlice } from "@reduxjs/toolkit";

interface ThemeType {
  theme: "light" | "dark";
}

const initialState: ThemeType = {
  // theme:
  //   window.matchMedia &&
  //   window.matchMedia("(prefers-color-scheme: dark)").matches
  //     ? "dark"
  //     : "light",
  theme: "light",
};

const themeSlice = createSlice({
  name: "theme",
  initialState,
  reducers: {
    toggleTheme: (state) => {
      state.theme = state.theme === "light" ? "dark" : "light";
    },
  },
});

export const { toggleTheme } = themeSlice.actions;
export default themeSlice.reducer;
