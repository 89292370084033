import DarkModeIcon from "@mui/icons-material/DarkMode";
import LightModeIcon from "@mui/icons-material/LightMode";
import { Stack } from "@mui/material";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { toggleTheme } from "app/themeSlice";

const ThemeToggle = () => {
  const { theme } = useAppSelector((state) => state.theme);
  const dispatch = useAppDispatch();

  const handleToggleTheme = () => {
    dispatch(toggleTheme());
  };

  return (
    <Stack
      direction={"row"}
      onClick={handleToggleTheme}
      color={"black.main"}
      sx={{
        cursor: "pointer",
      }}
    >
      {theme === "light" ? <LightModeIcon /> : <DarkModeIcon />}
    </Stack>
  );
};

export default ThemeToggle;
