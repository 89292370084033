import { forwardRef } from "react";
import ActivityDetailLayout from "./ActivityDetailLayout";
import { Stack } from "@mui/system";
import parse from "html-react-parser";

interface ProductCancelRefundInfoSectionProps {
  title: string;
  content: string;
}

const ProductCancelRefundInfoSection = forwardRef<
  HTMLDivElement,
  ProductCancelRefundInfoSectionProps
>(({ title, content }, ref) => {
  return (
    <ActivityDetailLayout ref={ref} title={title}>
      <Stack>{parse(content)}</Stack>
    </ActivityDetailLayout>
  );
});

export default ProductCancelRefundInfoSection;
