import React, { useState } from "react";
import LanguageSelector from "./LanguageSelector";
import LanguageIcon from "@mui/icons-material/Language";

interface LanguageSelectIconProps {
  type?: "ALL" | "ENG";
}
const LanguageSelectIcon = ({ type = "ALL" }: LanguageSelectIconProps) => {
  const [anchorEl, setAnchorEl] = useState<SVGSVGElement | null>(null);

  const handleFlagClick = (event: React.MouseEvent<SVGSVGElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <LanguageIcon onClick={handleFlagClick} />
      <LanguageSelector
        anchorEl={anchorEl}
        handleClose={handleClose}
        type={type}
      />
    </>
  );
};

export default LanguageSelectIcon;
