import React, { useState } from "react";
import {
  Card,
  Container,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import Layout from "components/layout/Layout";
import CustomButton from "components/button/CustomButton";
import { Stack } from "@mui/system";
import useValidate from "hooks/useValidate";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { useNavigate } from "react-router-dom";
import AuthService from "services/authService";
import useInputs from "hooks/useInputs";
import { FormattedMessage, useIntl } from "react-intl";

const initialFormState = {
  password: "",
  confirmPassword: "",
};

const NewPasswordPage = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { form, onChange, reset } = useInputs(initialFormState);
  const [validState, validate] = useValidate({ password: false });
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    validate(e);
    onChange(e);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // 입력한 비밀번호와 확인 비밀번호가 일치하는지 확인
    if (form.password !== form.confirmPassword) {
      alert(intl.formatMessage({ id: "signup.passwordInvalid3" }));
      return;
    }

    try {
      const token = window.location.search.split("token=")[1];
      await AuthService.resetPassword(token, String(form.password));

      navigate("/login");
    } catch (error) {
      console.error("Error during resetting password:", error);
      alert(intl.formatMessage({ id: "forgot.newPasswordFail" }));
      // setform(initialFormState);
      reset();
      setShowPassword(false);
      setShowConfirmPassword(false);
    }
  };

  return (
    <Layout text={intl.formatMessage({ id: "forgot.newPasswordTitle" })}>
      <Container maxWidth="sm" sx={{ marginTop: 4 }}>
        <Card sx={{ p: 2, mb: 1 }}>
          <form onSubmit={handleSubmit} style={{ width: "100%" }}>
            <Stack spacing={2} sx={{ mb: 3 }}>
              <Stack gap={0.7}>
                <Typography variant="h6">
                  <FormattedMessage id="forgot.newPassword" />{" "}
                  <span style={{ color: "red" }}>*</span>
                </Typography>
                <TextField
                  fullWidth
                  type={showPassword ? "text" : "password"}
                  name="password"
                  label={intl.formatMessage({ id: "forgot.newPassword" })}
                  value={form.password}
                  onChange={handleChange}
                  required
                  size="small"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowPassword(!showPassword)}
                          edge="end"
                          tabIndex={-1}
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  error={!!form.password && !validState.password}
                  helperText={
                    form.password &&
                    !validState.password && (
                      <>
                        <span>
                          <FormattedMessage id="signup.passwordInvalid1" />
                        </span>
                        <br />
                        <span>
                          <FormattedMessage id="signup.passwordInvalid2" />
                        </span>
                      </>
                    )
                  }
                />
              </Stack>
              <Stack gap={0.7}>
                <Typography variant="h6">
                  <FormattedMessage id="forgot.newPasswordConfirm" />{" "}
                  <span style={{ color: "red" }}>*</span>
                </Typography>
                <TextField
                  label={intl.formatMessage({
                    id: "forgot.newPasswordConfirm",
                  })}
                  name="confirmPassword"
                  type={showConfirmPassword ? "text" : "password"}
                  value={form.confirmPassword}
                  onChange={handleChange}
                  required
                  fullWidth
                  size="small"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle confirm password visibility"
                          onClick={() =>
                            setShowConfirmPassword(!showConfirmPassword)
                          }
                          edge="end"
                          tabIndex={-1}
                        >
                          {showConfirmPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  error={
                    !!form.confirmPassword &&
                    form.password !== form.confirmPassword
                  }
                  helperText={
                    form.confirmPassword &&
                    form.password !== form.confirmPassword && (
                      <span>
                        <FormattedMessage id="signup.passwordInvalid3" />
                      </span>
                    )
                  }
                />
              </Stack>
            </Stack>
            <CustomButton>
              <FormattedMessage id="forgot.setNewPassword" />
            </CustomButton>
          </form>
        </Card>
      </Container>
    </Layout>
  );
};

export default NewPasswordPage;
