import { Stack } from "@mui/material";
import LocalMallIcon from "@mui/icons-material/LocalMall";
import SportsEsportsIcon from "@mui/icons-material/SportsEsports";
import FlatwareIcon from "@mui/icons-material/Flatware";
import DirectionsBusIcon from "@mui/icons-material/DirectionsBus";
import AddCardIcon from "@mui/icons-material/AddCard";
import PaidIcon from "@mui/icons-material/Paid";

export type TransactionCategory =
  | "FOOD"
  | "SHOPPING"
  | "ENTERTAINMENT"
  | "TRANSPORTATION"
  | "OTHER"
  | "DEPOSIT"
  | "WITHDRAW";

export const getTransactionIcon = (category: TransactionCategory) => {
  switch (category) {
    case "FOOD":
      return <FlatwareIcon fontSize="large" />;
    case "SHOPPING":
      return <LocalMallIcon fontSize="large" />;
    case "ENTERTAINMENT":
      return <SportsEsportsIcon fontSize="large" />;
    case "TRANSPORTATION":
      return <DirectionsBusIcon fontSize="large" />;
    case "OTHER":
      return <AddCardIcon fontSize="large" />;
    case "DEPOSIT":
    case "WITHDRAW":
      return <PaidIcon fontSize="large" />;
  }
};

interface TransactionIconProps {
  category: TransactionCategory;
}

const TransactionIcon = ({ category }: TransactionIconProps) => {
  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      sx={{
        borderRadius: 0.3,
        bgcolor: "primary.light",
        color: "primary.main",
        p: 0.5,
        height: 48,
        minWidth: 48,
        width: 48,
      }}
    >
      {getTransactionIcon(category)}
    </Stack>
  );
};

export default TransactionIcon;
