import { Stack, Typography, useTheme } from "@mui/material";
import CardLayout from "components/layout/CardLayout";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useNavigate } from "react-router-dom";
import { useEffect, useRef } from "react";

const NewCard = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const newCardRef = useRef<HTMLDivElement>(null);

  const navigateToRegisterCard = () => {
    navigate("/card/register");
  };

  useEffect(() => {
    newCardRef.current?.addEventListener("click", (e) => {
      e.preventDefault();
    });
    
    return 
  });

  return (
    <div ref={newCardRef}>
      <CardLayout sx={{ mx: 0.5, bgcolor: theme.palette.divider }}>
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{
            minHeight: "120px",
            maxHeight: "240px",
            height: "calc((100vw - 32px) / 2.1)",
            width: "100%",
          }}
          onClick={navigateToRegisterCard}
        >
          <AddCircleOutlineIcon color="action" sx={{ fontSize: "60px" }} />
          <Typography
            variant="body1"
            sx={{ color: theme.palette.grey[700], fontWeight: "bold" }}
          >
            Add new card
          </Typography>
        </Stack>
      </CardLayout>
    </div>
  );
};

export default NewCard;
