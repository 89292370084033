import { Grid, Typography, useMediaQuery } from "@mui/material";
import LuggageIcon from "@mui/icons-material/Luggage";
import TRAVELPACK_LINK from "const/lottecard";

const TravelPackBanner = () => {
  const matches = useMediaQuery("(min-width:360px)");

  const navigateToTravelPack = () => {
    // window.location.href = TRAVELPACK_LINK;
    window.open(TRAVELPACK_LINK, "_blank");
  };

  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      sx={{
        color: "homeBanner.textColor",
      }}
      onClick={navigateToTravelPack}
    >
      <Grid item>
        <Typography
          sx={{
            fontSize: "1rem",
            fontWeight: "bold",
            textAlign: "start",
          }}
        >
          여행가기전 트래블팩 구독하고
        </Typography>
        <Typography
          sx={{
            fontSize: "1rem",
            fontWeight: "bold",
            textAlign: "start",
            wordBreak: "keep-all",
          }}
        >
          <span style={{ fontSize: "1.2rem" }}>1천원 적립금 </span>
          받아요
        </Typography>
      </Grid>
      <LuggageIcon
        sx={{
          fontSize: matches ? `88px` : "80px",
          position: "absolute",
          bottom: -6,
          right: -8,
          color: "#fff",
          opacity: "0.6",
        }}
      />
    </Grid>
  );
};

export default TravelPackBanner;
