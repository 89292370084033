import { combineReducers, configureStore } from "@reduxjs/toolkit";
import userReducer from "./userSlice";
import reservationReducer from "./reservationSlice";
import myOrdersReducer from "./myOrdersSlice";
import paymentReducer from "./paymentSlice";
import themeReducer from "./themeSlice";
import cardsReducer from "./cardsSlice";
import storage from "redux-persist/lib/storage";
import {
  persistReducer,
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";

const rootPersistConfig = {
  key: "root",
  storage,
  blacklist: ["myOrders"],
};

const myOrdersPersistConfig = {
  key: "myOrders",
  storage,
  whitelist: ["filterSettings"],
};

const rootReducer = combineReducers({
  user: userReducer,
  reservation: reservationReducer,
  // myOrders: myOrdersReducer,
  myOrders: persistReducer(myOrdersPersistConfig, myOrdersReducer),
  payment: paymentReducer,
  theme: themeReducer,
  cards: cardsReducer
});

const persistedReducer = persistReducer(rootPersistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const persistor = persistStore(store);
