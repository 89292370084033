import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { styled } from "@mui/material/styles";
import { Stack } from "@mui/system";

const LoginLinks = ({ isModal }: { isModal: boolean }) => {
  return (
    <LoginLinksWrapper
      direction="row"
      justifyContent="center"
      alignItems="center"
      gap={1}
    >
      <Link to="/find-id" state={{ isModal }}>
        <FormattedMessage id="user.findId" />
      </Link>{" "}
      |
      <Link to="/find-password" state={{ isModal }}>
        <FormattedMessage id="user.forgot" />
      </Link>{" "}
      |
      <Link to="/register" state={{ isModal }}>
        <FormattedMessage id="user.signup" />
      </Link>
    </LoginLinksWrapper>
  );
};

export default LoginLinks;

const LoginLinksWrapper = styled(Stack)(({ theme }) => ({
  fontSize: "14px",
  wordBreak: "keep-all",
  textAlign: "center",

  a: {
    textDecoration: "none",
    color:
      theme.palette.mode === "light"
        ? theme.palette.primary.main
        : theme.palette.text.primary,
  },
}));
