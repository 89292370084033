import { Routes, Route, Navigate } from "react-router-dom";
import RouteChangeTracker from "../routeChangeTracker";
import LoginPage from "pages/login-panel/LoginPage";
import LoginProviderPage from "pages/login-panel/LoginProviderPage";
import FindIdPage from "pages/login-panel/FindIdPage";
import FindPwPage from "pages/login-panel/FindPwPage";
import HanpassConsentPage from "pages/signup-hanpass-panel/HanpassConsentPage";
import PersonalInfoPage from "pages/personal-info-panel/PersonalInfoPage";
import OrderHistoryPage from "pages/ktx/order-history-panel/history-panel/OrderHistoryPage";
import PersonalInfoFormPage from "pages/personal-info-panel/edit-panel/PersonalInfoFormPage";
import RegistrationTab from "pages/signup-panel/RegistrationTab";
import CompletionPage from "pages/signup-panel/CompletionPage";
import HanpassCompletionPage from "pages/signup-hanpass-panel/HanpassCompletionPage";
import DetailQueryPage from "pages/ktx/order-history-panel/history-panel/DetailQueryPage";
import PaymentBookingPage from "pages/ktx/payment-panel/PaymentBookingPage";
import ConfirmationCard from "pages/login-panel/ConfirmationPage";
import NewPasswordPage from "pages/login-panel/NewPasswordPage";
import CustomerCenterPage from "pages/cs-panel/CustomerCenter";
import PrivateRoute from "./PrivateRoute";
import AnonymousRoute from "./AnonymousRoute";
import NaverCallback from "components/social_login/NaverCallback";
import KaKaoCallback from "components/social_login/KakaoCallback";
import LineCallback from "components/social_login/LineCallback";
import OAuthAdditionalInfo from "components/social_login/OAuthAdditionalInfo";
import TermsOfUse from "pages/consents/TermsOfUse";
import PrivacyPolicy from "pages/consents/PrivacyPolicy";
import PointHistory from "pages/personal-info-panel/PointHistory";
import RegisterPoint from "pages/personal-info-panel/RegisterPoint";
import PointParkComplete from "pages/pointpark/PointParkComplete";
import HomePage from "pages/ktx/home-panel/HomePage";
import TrainListPage from "pages/ktx/train-list-panel/TrainListPage";
import AlimTalkTicket from "pages/tickets/AlimTalkTicket";
import SeatSelectionPage from "pages/ktx/seat-selection-panel/SeatSelectionPage";
import PaymentPage from "pages/ktx/payment-panel/PaymentPage";
import TicketPage from "pages/tickets/TicketPage";
import PaymentProcessingPage from "pages/ktx/payment-panel/PaymentProcessingPage";
import CreditCancelPage from "pages/ktx/payment-panel/CreditCancelPage";
import CreditPaymentPage from "pages/ktx/payment-panel/CreditPaymentPage";
import CardSignUp from "pages/card/CardSignUp";
import CardRoute from "./CardRoute";
import CardPageLayout from "pages/card/CardPageLayout";
import CardHome from "pages/card/CardHome";
import DepositWithdraw from "pages/card/Sections/DepositWithdraw";
import Transfer from "pages/card/Sections/Transfer";
import CardSettings from "pages/card/Sections/CardSettings";
import MyCards from "pages/card/MyCards";
import CardRegister from "pages/card/CardRegister";
import CardTransactions from "pages/card/CardTransactions";
import { lazy, Suspense } from "react";
import LoadingSpinner from "components/LoadingSpinner";
import BottomNavigationLayout from "components/layout/BottomNavigationLayout";
import ActivityHome from "pages/activities/ActivityHome";
import Activity from "pages/activities/Activity";
import ShoppingCartPage from "pages/shopping-cart/ShoppingCartPage";

const HanacardMyReservations = lazy(
  () => import("pages/hanacard/MyReservations")
);

const AppBody = () => {
  RouteChangeTracker();

  return (
    <Routes>
      <Route path="/login-provider" element={<LoginProviderPage />} />
      <Route path="/tickets/:ktxNumber" element={<AlimTalkTicket />} />

      <Route element={<AnonymousRoute />}>
        {/* social login */}
        <Route path="/auth/naver" element={<NaverCallback />} />
        <Route path="/auth/kakao" element={<KaKaoCallback />} />
        <Route path="/auth/line" element={<LineCallback />} />
        <Route path="/auth/additional-info" element={<OAuthAdditionalInfo />} />

        <Route path="/hp-sign-up" element={<HanpassConsentPage />} />
        <Route path="/completion-hanpass" element={<HanpassCompletionPage />} />

        {/* 새 비밀번호 페이지 */}
        <Route path="/new-password" element={<NewPasswordPage />} />
      </Route>

      <Route element={<PrivateRoute />}>
        {/* 롯데카드 결제 iframe 페이지 */}
        <Route
          path="/payment/process/:orderId"
          element={<PaymentProcessingPage />}
        />

        {/* Point Park */}
        <Route path="/pointpark/complete" element={<PointParkComplete />} />
      </Route>

      {/* BottonNavigation 보이는 페이지 */}
      {/* <Route element={<BottomNavigationLayout />}> */}
      <Route path="/" element={<HomePage />} />
      <Route path="/search-results" element={<TrainListPage />} />

      {/* consent */}
      <Route path="/usage-terms" element={<TermsOfUse />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/customer-center" element={<CustomerCenterPage />} />

      <Route element={<AnonymousRoute />}>
        <Route path="/login" element={<LoginPage />} />

        <Route path="/find-id" element={<FindIdPage />} />
        <Route path="/find-password" element={<FindPwPage />} />
        <Route path="/confirmation" element={<ConfirmationCard />} />

        <Route path="/register" element={<RegistrationTab />} />
        <Route path="/completion" element={<CompletionPage />} />
      </Route>

      <Route element={<PrivateRoute />}>
        {/* 기차 좌석 선택 페이지 */}
        <Route
          path="/seat-selection/:trainNumber"
          element={<SeatSelectionPage />}
        />
        {/* 예약 정보 입력 페이지 */}
        <Route path="/payment/booking" element={<PaymentBookingPage />} />

        {/* 사용자 설정 관련 페이지 */}
        <Route path="/user-settings" element={<PersonalInfoPage />} />
        <Route path="/user/point-history" element={<PointHistory />} />
        <Route path="/user/personal-info" element={<PersonalInfoFormPage />} />
        <Route path="/user/register-coupon" element={<RegisterPoint />} />

        <Route path="/order-history" element={<OrderHistoryPage />} />
        <Route
          path="/order-details/:orderId"
          element={<PaymentPage isHistory={true} />}
        />
        <Route
          path="/order-details/:orderId/boarding-pass"
          element={<TicketPage />}
        />
        <Route path="/detail-query" element={<DetailQueryPage />} />

        {/* 결제 확인 페이지 */}
        <Route
          path="/payment/confirmation/:orderId"
          element={<PaymentPage isHistory={false} />}
        />
        <Route path="/train/creditCancel" element={<CreditCancelPage />} />
        <Route path="/train/creditPayment" element={<CreditPaymentPage />} />
      </Route>

      {/* Shopping Cart */}
      <Route path="/cart" element={<ShoppingCartPage />} />

      {/* Card */}
      <Route path="/card/signup" element={<CardSignUp />} />
      <Route element={<CardRoute />}>
        <Route path="/card/*" element={<CardPageLayout />}>
          <Route path="" element={<CardHome />}>
            <Route path="deposit" element={<DepositWithdraw />} />
            <Route path="transfer" element={<Transfer />} />
            <Route path="settings" element={<CardSettings />} />
          </Route>
        </Route>
        <Route path="/card/*">
          <Route path="list" element={<MyCards />} />
          <Route path="register" element={<CardRegister />} />
          <Route
            path=":cardNumber/transactions"
            element={<CardTransactions />}
          />
        </Route>
      </Route>
      {/* </Route> */}

      {/* 여행가는 달 관련 */}
      <Route path="/travel/">
        {/* <Route path="" element={<TravelList />} /> */}
      </Route>

      {/* JTR 관련 */}
      <Route path="/activities/">
        <Route path="" element={<ActivityHome />} />
        <Route path=":activityId" element={<Activity />} />
      </Route>
      {/* </Route> */}

      {/* 하나카드 my 에약 페이지 */}
      <Route
        path="/hanacard/reservations"
        element={
          <Suspense fallback={<LoadingSpinner overlap />}>
            <HanacardMyReservations />
          </Suspense>
        }
      />
      <Route
        path="/hanacard/order-details/:orderId"
        element={<PaymentPage isHistory={true} />}
      />

      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

export default AppBody;
