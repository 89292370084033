import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Stack,
  Typography,
} from "@material-ui/core";
import CustomButton from "components/button/CustomButton";
import { useIntl } from "react-intl";
import { InfoUpdateProps } from "./PersonalInfoFormPage";
import { isAxiosError } from "axios";
import UserService from "services/userService";

interface ConsentInfo {
  isReceivingEmail: boolean;
  isReceivingSms: boolean;
  isReceivingTalk: boolean;
}

const ConsentInfoUpdate = ({
  userInfo,
  setUserInfo,
  setModalVisible,
}: InfoUpdateProps) => {
  const intl = useIntl();
  const handleConsentChange = (name: keyof ConsentInfo) => {
    setUserInfo((prev) => ({
      ...prev,
      [name]: !prev[name],
    }));
  };

  const handleSubmit = async () => {
    try {
      const updatedUserInfo = {
        isReceivingEmail: userInfo.isReceivingEmail,
        isReceivingSms: userInfo.isReceivingSms,
        isReceivingTalk: userInfo.isReceivingTalk,
      };

      await UserService.update(updatedUserInfo);

      setModalVisible(true);
    } catch (error) {
      if (isAxiosError(error)) {
        alert(error.message);
      }
    }
  };

  return (
    <Stack gap={3}>
      <Stack gap={1}>
        <Typography variant="h6">
          {intl.formatMessage({ id: "signup.salesTitle2" })}
        </Typography>
        <Typography variant="body1">
          {intl.formatMessage({ id: "signup.salesContent2" })}
        </Typography>
      </Stack>
      <Stack gap={2}>
        <FormGroup row sx={{ marginInlineStart: 16 }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={userInfo.isReceivingEmail}
                onChange={() => handleConsentChange("isReceivingEmail")}
              />
            }
            label={intl.formatMessage({ id: "signup.email" })}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={userInfo.isReceivingTalk}
                onChange={() => handleConsentChange("isReceivingTalk")}
              />
            }
            label={intl.formatMessage({ id: "signup.alimTalk" })}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={userInfo.isReceivingSms}
                onChange={() => handleConsentChange("isReceivingSms")}
              />
            }
            label="Push"
          />
        </FormGroup>
        <CustomButton onClick={handleSubmit}>
          {" "}
          {intl.formatMessage({ id: "user.info.save" })}
        </CustomButton>
      </Stack>
    </Stack>
  );
};

export default ConsentInfoUpdate;
