import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PaymentService from "services/paymentService";
import LoadingSpinner from "components/LoadingSpinner";
import { OrderType } from "types/orderType";
import PaymentPageContent from "./PaymentPageContent";
import OrderDetailPage from "pages/ktx/order-history-panel/order-detail-panel/OrderDetailPage";

const initialAgreements = {
  term1: false,
  term2: false,
  term3: false,
  term4: false,
};

export type PaymentAgreementType = typeof initialAgreements;

export const checkAllAgree = (agreements: PaymentAgreementType) => {
  return Object.values(agreements).every(Boolean);
};

interface PaymentPageProps {
  isHistory: boolean;
}

const PaymentPage = ({ isHistory }: PaymentPageProps) => {
  const navigate = useNavigate();
  const [reservationDetails, setReservationDetails] = useState<OrderType>();
  const { orderId } = useParams();

  const isPending = reservationDetails?.orderStatus === "PENDING";

  // Fetch reservation details from server
  useEffect(() => {
    if (orderId) {
      PaymentService.reserveDetail(orderId)
        .then((data) => {
          setReservationDetails(data);
        })
        .catch((error) => {
          console.error("Error fetching reservation details:", error);
          navigate(-1);
        });
    }
  }, [navigate, orderId]);

  if (!reservationDetails) {
    return <LoadingSpinner overlap />;
  }

  return isPending ? (
    <PaymentPageContent
      isHistory={isHistory}
      reservationDetails={reservationDetails}
    />
  ) : (
    <OrderDetailPage reservationDetails={reservationDetails} />
  );
};

export default PaymentPage;
