import React from 'react';
import { useStepperContext } from './StepperContext'; // Make sure the path is correct

export const Step = ({ id, children }) => {
  const { activeStep } = useStepperContext();

  // This function determines the style based on whether the step is active
  const stepStyle = (isActive) => {
    return {
      color: isActive ? 'gray' : 'rgba(255, 255, 255, 0.53)',
      backgroundColor: isActive ? 'purple' : 'transparent',
      padding: '10px 20px',
      borderRadius: '10px',
      borderWidth: '1px',
      borderColor: isActive ? 'purple' : 'rgba(255, 255, 255, 0.19)',
      transition: 'all 0.2s ease-in-out',
      // Add other styles as needed
    };
  };

  return (
    <span
      style={stepStyle(activeStep === id)}
      className={`step ${activeStep === id ? 'active' : ''}`}
    >
      {children}
    </span>
  );
};
