import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import CustomButton from "components/button/CustomButton";
import CardLayout from "components/layout/CardLayout";
import useFormatPrice from "hooks/useFormatPrice";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import Barcode from "react-barcode";
import CardService from "services/cardService";
import { useOutletContext } from "react-router-dom";
import { CardContext } from "../CardPageLayout";

const options = ["CHARGE", "REFUND"] as const;
type OptionType = (typeof options)[number]; // "CHARGE" | "REFUND"

const DepositWithdraw = () => {
  const intl = useIntl();
  const formatPrice = useFormatPrice();

  const { currentCard } = useOutletContext<CardContext>();
  const { acnRmd: balance, iapCdno: cardNo } = currentCard || {};

  const [option, setOption] = useState<OptionType>("CHARGE");
  const [isFocused, setIsFocused] = useState(false);
  const [amount, setAmount] = useState("0");
  const numericAmount = Number(amount.replaceAll(",", ""));
  const [barcodeSpec, setBarcodeSpec] = useState("");

  // Reset form when card changes
  useEffect(() => {
    setOption("CHARGE");
    setAmount("0");
    setBarcodeSpec("");
  }, [currentCard]);

  const handleChangeAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    setAmount(`${Number(value)}`);
  };

  const handleChangeOption = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value as OptionType;

    setOption(value);
  };

  const handleFocus = () => {
    setIsFocused(true);
    setAmount(`${numericAmount || "0"}`);
  };

  const handleBlur = () => {
    setIsFocused(false);
    setAmount(numericAmount > 0 ? numericAmount.toLocaleString() : "0");
  };

  const handleDepositWithdrawal = async () => {
    if (option === "REFUND" && numericAmount > balance) {
      alert("Can't withdraw more than balance");
    }

    try {
      const response = await CardService.cvsBarcode(
        cardNo,
        option,
        numericAmount,
        balance
      );

      setBarcodeSpec(response.barcodeSpec);
    } catch (error) {
      console.error(error);
    }
  };

  const openCardChargeView = async () => {
    const { data } = await CardService.getCardChargeUrl();
    const params = new URLSearchParams({
      cardNo,
      isgRqsDscd: "101", // 101 | 104 (default) | 106 | 901 | 902
      isgLanDscd: intl.locale, // "ko" (default) | "en" | "cn" | "jp"
      cardBalance: balance,
    } as any);
    const url = `${data}&${params.toString()}`;
    // console.log(url);
    window.location.href = url;
  };

  const getHeader = () => {
    return (
      <FormControl>
        <RadioGroup
          row
          // aria-labelledby="demo-radio-buttons-group-label"
          value={option}
          defaultValue={option}
          name="option-group"
          onChange={handleChangeOption}
        >
          {options.map((option) => (
            <FormControlLabel
              key={option}
              value={option}
              control={<Radio />}
              disabled={option === "REFUND" && balance === 0}
              label={
                <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                  {option}
                </Typography>
              }
            />
          ))}
        </RadioGroup>
      </FormControl>
    );
  };

  return (
    <CardLayout
      header={getHeader()}
      sx={{ border: "none", boxShadow: "none", p: 1 }}
    >
      <Stack gap={2} sx={{ width: "100%" }}>
        <label>
          <Stack
          // direction="row" alignItems="end"
          // gap={1}
          >
            <Typography variant="body1" sx={{ fontWeight: "bold" }}>
              Amount to {option}
            </Typography>
            <Typography variant="caption" alignSelf="end">
              (3만원 이상 50만원 이하)
            </Typography>
          </Stack>
          <TextField
            fullWidth
            name="amount"
            type={isFocused ? "number" : "text"}
            value={amount}
            onChange={handleChangeAmount}
            InputProps={{
              inputProps: {
                min: 0,
                // max: balance,
              },
              sx: {
                height: "48px",

                "& .MuiInputBase-input": {
                  textAlign: "end",
                },
                "& input[type=number]": {
                  "-moz-appearance": "textfield",
                  margin: 0.3,
                },
                "& input[type=number]::-webkit-outer-spin-button": {
                  "-webkit-appearance": "none",
                  margin: 0.3,
                },
                "& input[type=number]::-webkit-inner-spin-button": {
                  "-webkit-appearance": "none",
                  margin: 0.3,
                },
                "& input[type=text]": {
                  "-moz-appearance": "textfield",
                  margin: 1,
                },
                "& input[type=text]::-webkit-outer-spin-button": {
                  "-webkit-appearance": "none",
                  margin: 1,
                },
                "& input[type=text]::-webkit-inner-spin-button": {
                  "-webkit-appearance": "none",
                  margin: 1,
                },
              },
              endAdornment: intl.formatMessage({ id: "payment.koreanWon" }),
            }}
            onFocus={handleFocus}
            onBlur={handleBlur}
            placeholder="0"
          />
        </label>

        {/* TODO: Barcode 는 bottom sheet로 보여주기??  */}
        {barcodeSpec && (
          <Stack alignItems="center">
            <Barcode
              value={barcodeSpec}
              height={48}
              width={1}
              format="CODE128"
              // displayValue
              fontSize={12}
            />
          </Stack>
        )}

        <Stack direction="row" gap={1}>
          {option === "CHARGE" && (
            <CustomButton
              size="medium"
              variant="outlined"
              onClick={openCardChargeView}
              // onClick={() => {
              //   const getUrl = async () => {
              //     const url = await CardService.getCardChargeUrl();
              //     const params = new URLSearchParams({
              //       cardNo,
              //       isgRqsDscd: "101", // 101 | 104 | 106 | 901 | 902
              //       isgLanDscd: intl.locale,
              //       cardBalance: balance,
              //     } as any);
              //     console.log(`${url.data}&${params.toString()}`);
              //     return url;
              //   };
              //   getUrl();
              // }}
            >
              금액권 구매(충전)
            </CustomButton>
          )}
          <CustomButton
            size="medium"
            onClick={handleDepositWithdrawal}
            disabled={numericAmount < 30000 || numericAmount > 500000}
          >
            {option} {formatPrice(numericAmount)}
          </CustomButton>
        </Stack>
      </Stack>
    </CardLayout>
  );
};

export default DepositWithdraw;
