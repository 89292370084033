import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import TrainService, { CurrencyType } from "services/trainService";
import { ProviderUtils } from "utils/providerUtils";
import { StorageUtils } from "utils/storageUtils";

const getInitialCurrencyRate = () => {
  const key = ProviderUtils.isDollarProvider ? "USD" : "JPY";

  return Number(StorageUtils.CurrencyRate.get(key) ?? 0);
};

const useFormatPrice = () => {
  const intl = useIntl();
  const koreanWon: string = intl.formatMessage({ id: "payment.koreanWon" });
  const [currencyRate, setCurrencyRate] = useState(getInitialCurrencyRate);

  useEffect(() => {
    const getCurrencyRate = async (code: CurrencyType) => {
      let rate = 0;

      rate = await TrainService.getCurrency(code);
      StorageUtils.CurrencyRate.set(code, rate);
      setCurrencyRate(rate);
      return;
    };

    if (
      currencyRate === 0 &&
      (ProviderUtils.isDollarProvider || ProviderUtils.isMame)
    ) {
      const key = ProviderUtils.isDollarProvider ? "USD" : "JPY";
      getCurrencyRate(key);
    }
  }, [currencyRate]);

  const formatPrice = (price?: number | string): string => {
    if (ProviderUtils.isDollarProvider) {
      return `$${Number(
        (Number(price ?? 0) / currencyRate).toFixed(2)
      ).toLocaleString("en-US")}`;
    }

    if (ProviderUtils.isMame) {
      return `${Math.ceil(Number(price ?? 0) / currencyRate).toLocaleString(
        "ja-JP"
      )} JPY`;
    }

    return `${Number(price ?? 0).toLocaleString("ko-KR")} ${koreanWon}`;
  };

  return formatPrice;
};

export default useFormatPrice;
