import React, { useState } from "react";
import { Autocomplete, TextField, useTheme, Grid } from "@mui/material";
import { useIntl } from "react-intl";
import CustomButton from "components/button/CustomButton";
import useTranslateStation from "hooks/useTranslateStation";
import { TrainResponse } from "services/trainService";
import { useNavigate } from "react-router-dom";
import { StationOption } from "./QuickLinks";

interface SearchBarProps {
  stations: TrainResponse[];
  stationOption: StationOption;
  saveToRecentSearches: (link: TrainResponse) => void;
  closeWindow: () => void;
}

const SearchBar = ({
  stations,
  stationOption,
  saveToRecentSearches,
  closeWindow,
}: SearchBarProps) => {
  const theme = useTheme();
  const intl = useIntl();
  const navigate = useNavigate();

  const params = new URLSearchParams(window.location.search);
  const [searchTerm, setSearchTerm] = useState("");
  const translateStation = useTranslateStation();

  const handleSearch = (
    e: React.FormEvent<HTMLFormElement>,
    searchTerm: string
  ) => {
    e.preventDefault();

    const selectedStation = stations?.find(
      (station) => translateStation(station.stationName) === searchTerm
    );

    if (selectedStation) {
      const otherFieldName =
        stationOption === "departure" ? "arrival" : "departure";

      // 출발지와 도착지가 같은 경우 alert 표시
      if (selectedStation.stationCode === params.get(`${otherFieldName}Code`)) {
        alert(
          intl.formatMessage({ id: "stationSelectionPopup.sameStationError" })
        );
        return;
      }

      // Call saveToRecentSearches with the selected station
      saveToRecentSearches(selectedStation);

      params.set(`${stationOption}`, selectedStation.stationName);
      params.set(`${stationOption}Code`, selectedStation.stationCode);
      navigate(`?${params.toString()}`);
      closeWindow();
    }
  };

  return (
    <form
      onSubmit={(e) => handleSearch(e, searchTerm)}
      style={{
        margin: "16px 0",
        width: "100%",
      }}
    >
      <Grid container alignItems="center" justifyContent="center" spacing={1}>
        <Grid item xs={8}>
          <Autocomplete
            options={stations.map((station) =>
              translateStation(station.stationName)
            )}
            onInputChange={(event, newInputValue) => {
              setSearchTerm(newInputValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={intl.formatMessage({
                  id: "stationSelectionPopup.searchForCityOrStation",
                })}
                size="small"
                sx={{
                  bgcolor: theme.palette.white.main,
                  borderRadius: theme.shape.borderRadius,
                }}
              />
            )}
            freeSolo
            sx={{
              // border: "1px solid blue",
              "& .MuiOutlinedInput-root": {
                // border: "1px solid yellow",
                padding: 0,
              },
              "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                border: "1px solid #ebebeb",
              },
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <CustomButton
            id="stationSelectionPopup.searchButtonText"
            size="medium"
            disabled={!searchTerm}
            style={{ fontWeight: "500" }}
          />
        </Grid>
      </Grid>
    </form>
  );
};

export default SearchBar;
