import React from "react";
import Layout from "components/layout/Layout";
import LoginContent from "../../components/login/LoginContent";
import { useNavigate } from "react-router-dom";
import { useIntl } from "react-intl";

const LoginPage = () => {
  const navigate = useNavigate();
  const intl = useIntl();

  return (
    <Layout
      onBack={() => navigate("/")}
      text={intl.formatMessage({ id: "signup.login" })}
    >
      <LoginContent isModal={false} />
    </Layout>
  );
};

export default LoginPage;
