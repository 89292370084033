import {
  FormControl,
  Grid,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import CardLayout from "components/layout/CardLayout";
import Layout from "components/layout/Layout";
// import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import CustomButton from "components/button/CustomButton";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import StarIcon from "@mui/icons-material/Star";
import useFormatPrice from "hooks/useFormatPrice";

export const dummyActivities = [
  {
    id: 0,
    title: "[Fun∙Variety] 일본 유니버셜 스튜디오 재팬(오사카)",
    description:
      "익스프레스4 입장권 티켓(일본 오사카)",
    image: "https://picsum.photos/100",
    price: 10000,
    rate: 4.5,
  },
  {
    id: 1,
    title: "B activity",
    description: "B activity description",
    image: "https://picsum.photos/100",
    price: 10000,
    rate: 4.5,
  },
  {
    id: 2,
    title: "C activity",
    description: "C activity description",
    image: "https://picsum.photos/100",
    price: 10000,
    rate: 4.5,
  },
  {
    id: 3,
    title: "D activity",
    description: "D activity description",
    image: "https://picsum.photos/100",
    price: 10000,
    rate: 4.5,
  },
  {
    id: 4,
    title: "E activity",
    description: "E activity description",
    image: "https://picsum.photos/100",
    price: 10000,
    rate: 4.5,
  },
  {
    id: 5,
    title: "A activity",
    description: "A activity description",
    image: "https://picsum.photos/100",
    price: 10000,
    rate: 4.5,
  },
  {
    id: 6,
    title: "B activity",
    description: "B activity description",
    image: "https://picsum.photos/100",
    price: 10000,
    rate: 4.5,
  },
  {
    id: 7,
    title: "C activity",
    description: "C activity description",
    image: "https://picsum.photos/100",
    price: 10000,
    rate: 4.5,
  },
  {
    id: 8,
    title: "D activity",
    description: "D activity description",
    image: "https://picsum.photos/100",
    price: 10000,
    rate: 4.5,
  },
  {
    id: 9,
    title: "E activity",
    description: "E activity description",
    image: "https://picsum.photos/100",
    price: 10000,
    rate: 4.5,
  },
];

const ActivityHome = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const formatPrice = useFormatPrice();

  // TODO: state 말고 url로 filter 관리하기
  const [filter, setFilter] = useState("star");

  return (
    <Layout text="Activities">
      <Stack gap={1} sx={{ my: 2 }}>
        <Grid container alignItems="center" justifyContent="center" spacing={1}>
          <Grid item xs={8}>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              placeholder="Search"
              sx={{
                bgcolor: theme.palette.white.main,
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <CustomButton
              id="stationSelectionPopup.searchButtonText"
              size="medium"
              // disabled={!searchTerm}
              style={{ fontWeight: "500" }}
            />
          </Grid>
        </Grid>

        <FormControl size="small" sx={{ alignSelf: "flex-end" }}>
          {/* <InputLabel id="demo-select-small-label">Filter</InputLabel> */}
          <Select
            labelId="demo-select-small-label"
            id="demo-select-small"
            value={filter}
            // label="Filter"
            // onChange={handleChange}
            sx={{ bgcolor: theme.palette.white.main }}
          >
            <MenuItem value="star">인기순</MenuItem>
            <MenuItem value="price-high">높은가격순</MenuItem>
            <MenuItem value="price-low">낮은가격순</MenuItem>
            <MenuItem value="recent">최신순</MenuItem>
          </Select>
        </FormControl>

        {/* Activities list */}
        <Stack gap={1}>
          {dummyActivities.map((activity) => {
            const getHeader = () => {
              return (
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  sx={{ width: "100%" }}
                >
                  <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                    {activity.title}
                  </Typography>
                  {/* <CustomButton
                    // onClick={handleToggleDetails}
                    variant="text"
                    size="small"
                    fullWidth={false}
                    // disabled={isLoading}
                    style={{
                      padding: 0,
                      minHeight: "24px",
                    }}
                    onClick={() => {
                      navigate(`/activities/${activity.id}`);
                    }}
                  >
                    detail
                    <NavigateNextIcon fontSize="small" />
                  </CustomButton> */}
                </Stack>
              );
            };

            const getAdditionalInfo = () => {
              return (
                <Stack
                  direction="row"
                  alignItems="end"
                  justifyContent="space-between"
                  sx={{ width: "100%" }}
                >
                  <Stack direction="row" gap={1}>
                    <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                      {formatPrice(activity.price)}
                    </Typography>
                    <Stack direction="row">
                      <StarIcon fontSize="small" color="warning" />
                      <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                        {activity.rate}
                      </Typography>
                    </Stack>
                  </Stack>
                  <Stack direction="row" justifyContent="space-between" gap={1}>
                    {/* <CustomButton variant="outlined" size="small">
                      장바구니 담기
                    </CustomButton> */}
                    <CustomButton size="small">주문하기</CustomButton>
                  </Stack>
                </Stack>
              );
            };

            return (
              <CardLayout
                header={getHeader()}
                additionalInfo={getAdditionalInfo()}
              >
                <Stack
                  direction="row"
                  justifyContent="start"
                  gap={1.5}
                  sx={{ width: "100%" }}
                  onClick={() => {
                    navigate(`/activities/${activity.id}`);
                  }}
                >
                  <img
                    src={activity.image}
                    alt={activity.title}
                    style={{ borderRadius: `${theme.border.radius.medium}px` }}
                  />
                  <Typography
                    // noWrap
                    variant="caption"
                    sx={{ color: "text.secondary", textWrap: "keep-all" }}
                  >
                    {activity.description.length > 100
                      ? `${activity.description.slice(0, 130)}...`
                      : activity.description}
                  </Typography>
                </Stack>
              </CardLayout>
            );
          })}
        </Stack>
      </Stack>
    </Layout>
  );
};

export default ActivityHome;
