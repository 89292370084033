import { useAppDispatch } from "app/hooks";
import { fetchUser } from "app/userSlice";
import { isAxiosError } from "axios";
import LoadingSpinner from "components/LoadingSpinner";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AuthService from "services/authService";
import TokenService from "services/tokenService";
import UserService from "services/userService";
import { StorageUtils } from "utils/storageUtils";

const NaverCallback = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const searchParams = new URLSearchParams(window.location.search);
  const code = searchParams.get("code")!;
  const state = searchParams.get("state")!;
  const isModal = StorageUtils.IsLoginModal.get();

  useEffect(() => {
    const getToken = async () => {
      try {
        const response = await AuthService.naverLogin(code, state);
        if (response.status < 300) {
          TokenService.setAccessToken(response.data.accessToken);

          // 사용자 정보
          const userInfoResponse = await UserService.getMyInfo();
          dispatch(fetchUser(userInfoResponse));

          StorageUtils.RecentLoginMethod.set("NAVER");

          if (isModal) {
            navigate("/search-results");
            return;
          }

          navigate("/");
        }
      } catch (error) {
        if (isAxiosError(error)) {
          const userInfo = error.response?.data.userInfo;
          navigate("/auth/additional-info", {
            state: { userInfo, ssoName: "NAVER" },
          });
        }
      }
    };
    getToken();
  }, [code, dispatch, isModal, navigate, state]);

  return <LoadingSpinner />;
};

export default NaverCallback;
