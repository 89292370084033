import { Stack, Typography, Button, Card } from "@mui/material";
import HeadsetMicIcon from "@mui/icons-material/HeadsetMic";
import CampaignIcon from "@mui/icons-material/Campaign";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";

const MenuOptions = () => {
  const intl = useIntl();
  const navigate = useNavigate();

  return (
    <Stack
      direction="row"
      justifyContent="space-around"
      gap={2}
      sx={{ width: "100%", my: 1 }}
    >
      {[
        {
          Icon: HeadsetMicIcon,
          text: intl.formatMessage({ id: "user.customerCenter" }),
          link: "/customer-center",
        },
        {
          Icon: CampaignIcon,
          text: intl.formatMessage({ id: "user.notice" }),
          link: "./",
        },
      ].map(({ Icon, text, link }, index) => (
        <Card key={index} sx={{ width: "100%" }}>
          <Stack
            justifyContent="center"
            alignItems="center"
            sx={{ height: "30vw", maxHeight: "160px" }}
          >
            <Button
              onClick={() => navigate(link)}
              sx={{
                textTransform: "none",
                width: "100%",
                height: "100%",
              }}
              color="secondary"
            >
              <Stack
                gap={1}
                justifyContent="center"
                alignItems="center"
                sx={{ color: "text.primary" }}
              >
                <Icon fontSize="large" />
                <Typography variant="body2" align="center">
                  {text}
                </Typography>
              </Stack>
            </Button>
          </Stack>
        </Card>
      ))}
    </Stack>
  );
};

export default MenuOptions;
