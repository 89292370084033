import { Stack, Typography } from "@mui/material";
import React from "react";
import { useIntl } from "react-intl";

interface TicketAdditionalInfoProps {
  passengerDetails: string;
  seatDetails: string;
}

const TicketAdditionalInfo = ({
  passengerDetails,
  seatDetails,
}: TicketAdditionalInfoProps) => {
  const intl = useIntl();

  return (
    <Stack gap={1} sx={{ mt: 1, width: "100%" }}>
      {/* Passenger Details */}
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="body2" sx={{ fontWeight: "bold" }}>
          {intl.formatMessage({ id: "booking.passengers" })}
        </Typography>
        <Typography variant="body2">{passengerDetails}</Typography>
      </Stack>

      {/* Seat Details */}
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="body2" sx={{ fontWeight: "bold" }}>
          {intl.formatMessage({ id: "booking.seats" })}
        </Typography>
        <Typography variant="body2">{seatDetails}</Typography>
      </Stack>
    </Stack>
  );
};

export default TicketAdditionalInfo;
