import { useIntl } from "react-intl";
import CardLayout from "components/layout/CardLayout";

const DateInformation = () => {
  const intl = useIntl();
  const today = new Date();
  const adultThreshold = new Date(
    today.getFullYear() - 13,
    today.getMonth(),
    today.getDate()
  );
  const childThresholdStart = new Date(
    today.getFullYear() - 13,
    today.getMonth(),
    today.getDate() + 1
  );
  const childThresholdEnd = new Date(
    today.getFullYear() - 7,
    today.getMonth(),
    today.getDate()
  );

  const formatDate = (date: Date) =>
    `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;

  return (
    <CardLayout
      sx={{
        width: "100%",
        textAlign: "center",
        wordBreak: "keep-all",
        "&  label": { fontWeight: "bold" },
      }}
    >
      <label>
        {intl.formatMessage(
          { id: "passengerSelection.basedOnDepartureDay" },
          { date: formatDate(today) }
        )}
      </label>
      <p>
        {intl.formatMessage(
          { id: "passengerSelection.adultsBeforeDate" },
          { date: formatDate(adultThreshold) }
        )}
      </p>
      <p>
        {intl.formatMessage(
          { id: "passengerSelection.childrenBetweenDates" },
          {
            dateStart: formatDate(childThresholdStart),
            dateEnd: formatDate(childThresholdEnd),
          }
        )}
      </p>
    </CardLayout>
  );
};

export default DateInformation;
