import { Card, Stack, Typography, useTheme } from "@mui/material";
import StationListSectionHeader from "components/layout/StationListSectionHeader";
import { frequentStations, popularRoutes } from "const/linksData";
import { useCallback, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import TrainService, { TrainResponse } from "services/trainService";
import QuickLinkList from "./QuickLinkList";
import SearchBar from "./SearchBar";
import { StorageUtils } from "utils/storageUtils";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/system";

/**
 * A utility type that takes an existing type `T` and a set of keys `K` that are present in `T`.
 * It returns a new type that is identical to `T`, but with the specified keys `K` marked as required.
 *
 * @template T - The original type to be modified.
 * @template K - A set of keys that are present in `T`, which will be marked as required in the resulting type.
 * @returns A new type that is identical to `T`, but with the specified keys `K` marked as required.
 */
type WithRequired<T, K extends keyof T> = T & { [P in K]-?: T[P] };

export type SearchSection =
  | "recentSearches"
  | "popularRoutes"
  | "frequentStations"
  | "apiStations";

export type LinkItem = WithRequired<
  Partial<
    TrainResponse & {
      arrivalCode: string;
      departureCode: string;
      path: string;
    }
  >,
  "stationName"
>;

/**
 * Checks if the given item is a LinkItem.
 *
 * @param {LinkItem | TrainResponse} item - The item to check.
 * @return {item is LinkItem} - True if the item is a LinkItem, false otherwise.
 */
const isLink = (item: LinkItem | TrainResponse): item is LinkItem => {
  if ("arrivalCode" in item) {
    return true;
  }
  return false;
};

export type StationOption = "departure" | "arrival";

interface QuickLinksProps {
  stationOption: StationOption;
  closeWindow: () => void;
}

const QuickLinks = ({ stationOption, closeWindow }: QuickLinksProps) => {
  // hooks
  const intl = useIntl();
  const theme = useTheme();
  const navigate = useNavigate();

  // state
  const [stations, setStations] = useState<TrainResponse[]>([]);
  const [recentSearchesState, setRecentSearchesState] = useState<
    TrainResponse[]
  >(JSON.parse(StorageUtils.RecentSearches.get() || "[]"));

  // effect
  useEffect(() => {
    const fetchStations = async () => {
      try {
        const data = await TrainService.getStations();
        setStations(data);
      } catch (error) {
        console.error("역 목록을 가져오는데 실패했습니다.", error);
      }
    };

    fetchStations();
  }, []);

  const saveToRecentSearches = useCallback(
    (link: TrainResponse) => {
      const index = recentSearchesState.findIndex(
        (item) => item.stationCode === link.stationCode
      );

      if (index > -1) {
        // Station is already in the list
        setRecentSearchesState((prev) => {
          prev.splice(index, 1);
          const current = [link, ...prev];
          StorageUtils.RecentSearches.set(JSON.stringify(current));

          return current;
        });
      } else {
        // Station is not in the list
        setRecentSearchesState((prev) => {
          const current = [link, ...prev].slice(0, 6);
          StorageUtils.RecentSearches.set(JSON.stringify(current));

          return current;
        });
      }
    },
    [recentSearchesState]
  );

  const clearRecentSearches = () => {
    StorageUtils.RecentSearches.remove();
    setRecentSearchesState([]);
  };

  const handleLinkClick = useCallback(
    (link: TrainResponse | LinkItem) => {
      const params = new URLSearchParams(window.location.search);
      const arrivalCode = params.get("arrivalCode");
      const departureCode = params.get("departureCode");

      // 인기 노선 선택 처리
      if (isLink(link)) {
        const [departure, arrival] = link.stationName.split(" → ");
        const { departureCode, arrivalCode } = link;

        params.set("departure", departure);
        params.set("arrival", arrival);
        params.set("departureCode", departureCode!);
        params.set("arrivalCode", arrivalCode!);

        navigate(`?${params.toString()}`);
        closeWindow();
        return;
      }

      // 출발역과 도착역과 같은지 확인
      if (
        (stationOption === "departure" && link.stationCode === arrivalCode) ||
        (stationOption === "arrival" && link.stationCode === departureCode)
      ) {
        alert(
          intl.formatMessage({ id: "stationSelectionPopup.sameStationError" })
        );
        return;
      }

      saveToRecentSearches(link);

      // 기타 링크 선택 처리
      params.set(`${stationOption}`, link.stationName);
      params.set(`${stationOption}Code`, link.stationCode);

      navigate(`?${params.toString()}`);
      closeWindow();
    },
    [closeWindow, intl, navigate, saveToRecentSearches, stationOption]
  );

  return (
    <Stack
      direction="column"
      alignItems="center"
      sx={{ position: "relative", pt: "36px" }}
    >
      <SearchBar
        stations={stations}
        stationOption={stationOption}
        saveToRecentSearches={saveToRecentSearches}
        closeWindow={closeWindow}
      />

      <Stack gap={1.5} sx={{ mb: 1 }}>
        {/* Recent search */}
        {recentSearchesState.length > 0 && (
          <Card>
            <StationListSectionHeader
              id="recentlySearched"
              onClick={clearRecentSearches}
            />
            <QuickLinkList
              items={recentSearchesState}
              section="recentSearches"
              handleLinkClick={handleLinkClick}
            />
          </Card>
        )}

        {/* Popular routes */}
        <Card>
          <StationListSectionHeader id="popularRoutes" />
          <QuickLinkList
            items={popularRoutes}
            section="popularRoutes"
            handleLinkClick={handleLinkClick}
            gridColumn={1}
          />
        </Card>

        {/* Frequent stations */}
        <Card>
          <StationListSectionHeader id="frequentlyVisited" />
          <QuickLinkList
            items={frequentStations}
            section="frequentStations"
            handleLinkClick={handleLinkClick}
          />
        </Card>

        {/* all stations */}
        <Card>
          <StationListSectionHeader id="allStations" />
          <QuickLinkList
            items={stations}
            section="apiStations"
            handleLinkClick={handleLinkClick}
          />
        </Card>
      </Stack>
    </Stack>
  );
};

export default QuickLinks;
