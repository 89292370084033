import { Card, Stack, Typography } from "@mui/material";
import useLanguages from "hooks/useLanguages";
import { ProviderUtils } from "utils/providerUtils";

const LachaRewardBanner = () => {
  const { isKorean } = useLanguages();

  return (
    <Card
      sx={{
        m: 2,
        p: 2,
        px: 3,
        bgcolor: "homeBanner.bgColor",
        border: "none",
        position: "relative",
      }}
    >
      <Stack gap={1}>
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          라차 적립금 안내
        </Typography>
        <Typography
          variant="body2"
          sx={{
            fontSize: "1rem",
            fontWeight: "bold",
            textAlign: "start",
          }}
        >
          {ProviderUtils.isHanacard
            ? '트래블버킷에서 KTX 예매시 최대 10% "라차 적립금"을 받으실 수 있습니다'
            : `라차에서 KTX 예매하면, 최대 ${ProviderUtils.isLottecard ? 20 : 10}%의 적립금을 받으실 수 있습니다! 적립된 적립금은 다음 기차 예매 시 사용 가능합니다.`}
        </Typography>
        {isKorean && (
          <Typography
            variant="caption"
            color="text.secondary"
            sx={{
              fontSize: "0.9rem",
              fontWeight: "bold",
              textAlign: "start",
            }}
          >
            {ProviderUtils.isHanacard && (
              <>
                * 라차 적립금 : 트래블버킷에서 KTX 예매시 적립 및 사용 가능한
                적립금입니다
                <br />
                ※ 코레일 포인트와는 다른 적립금으로, 트래블버킷에서만
                사용가능합니다
                <br />
                <br />
              </>
            )}
            * 적립시기 : 기차 출발 다음날 적립됩니다
            <br />* 유효기간 : 적립금은 1년간 유효하며, 적립 후 1년이 지나면
            자동으로 소멸됩니다.
          </Typography>
        )}
      </Stack>
    </Card>
  );
};

export default LachaRewardBanner;
