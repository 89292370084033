import React from "react";
import Button from "@mui/material/Button";
import { useTheme } from "@mui/material/styles";
import {FormattedMessage} from "react-intl";

const SubmitButton = ({ handleSubmit, isSubmitDisabled }) => {
  const theme = useTheme();

  return (
    <div
      className="terms-agreement-footer"
      style={{ padding: theme.spacing(2) }}
    >
      <Button
        variant="contained"
        color="primary"
        onClick={handleSubmit}
        disabled={isSubmitDisabled}
        fullWidth
        disableElevation
      >
        <FormattedMessage id="signup.continue"/>
      </Button>
    </div>
  );
};

export default SubmitButton;
