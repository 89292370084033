import { useIntl } from "react-intl";
import { Grid } from "@mui/material";
import QuickLinkItem from "./QuickLinkItem";
import { TrainResponse } from "services/trainService";
import { RouteInfo } from "const/linksData";
import { LinkItem, SearchSection } from "./QuickLinks";

interface QuickLinkListProps {
  items: TrainResponse[] | RouteInfo[];
  section: SearchSection;
  handleLinkClick: (link: TrainResponse | LinkItem) => void;
  gridColumn?: number;
}

const QuickLinkList = ({
  items,
  section,
  handleLinkClick,
  gridColumn,
}: QuickLinkListProps) => {
  const intl = useIntl();
  const column = gridColumn ?? (["ko", "zh"].includes(intl.locale) ? 2 : 1);

  return (
    <Grid container spacing={1} sx={{ mb: 1, px: 1 }}>
      {items.map((item, index) => (
        <Grid item xs={12 / column} key={item.stationName || index}>
          <QuickLinkItem
            section={section}
            link={item}
            handleLinkClick={handleLinkClick}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default QuickLinkList;
