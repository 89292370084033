import { Card, CardContent, Divider, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import Layout from "components/layout/Layout";
import CustomButton from "components/button/CustomButton";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { Stack } from "@mui/system";
import { FormattedMessage, useIntl } from "react-intl";
import { AutoLoginProvider, Email, FindObjective } from "services/authService";
import useLanguages from "hooks/useLanguages";

// TODO: provider Utils에서 directLoginProviders, cardAppLoginProviders 만드는 방법 생각해보기
const directLoginProviders = [
  "Local",
  "Paybooc",
  "KTM",
  "TravelWallet",
  "Mame",
];

const cardAppLoginProviders = [
  "LotteCard",
  "HanaCard",
  "Hanpass",
  "GME",
  "RedTable",
];

interface LocationState {
  emails?: Email[]; // id 찾기 결과
  email: string; // pw 찾기 결과
  objective: FindObjective;
  isModal: boolean;
}

// Function to format the user ID
const formatEmail = (email: string): string => {
  const [prefix, domain] = email.split("@");
  const STAR_NUMBER = 3;
  return `${prefix.slice(0, -STAR_NUMBER)}${"*".repeat(STAR_NUMBER)}@${domain}`;
};

const ConfirmationPage = () => {
  const { emails, email, objective, isModal } = useLocation()
    .state as LocationState;
  const navigate = useNavigate();
  const intl = useIntl();
  const { isKorean } = useLanguages();

  const directLogin = emails?.filter(
    ({ ssoId, provider }) => !ssoId && directLoginProviders.includes(provider)
  );

  const socialLogin = emails?.filter(({ ssoId }) => Boolean(ssoId));
  const cardAppLogin = emails?.filter(({ provider }) =>
    cardAppLoginProviders.includes(provider)
  );

  const handleLoginClick = () => {
    navigate("/login", { state: { backToSearchResult: isModal } });
  };

  const handleFindPassword = () => {
    navigate("/find-password", { state: { isModal } });
  };

  const navigateToSignupPage = () => {
    navigate("/register", { state: { isModal: false } });
  };

  const formatSocialLoginMessage = () => {
    const ssoString = socialLogin
      ?.map((email) => email[isKorean ? "ssoName" : "ssoId"])
      .join(", ");

    const message = isKorean
      ? `가입된 ${ssoString} 계정이 있습니다.`
      : `You have signed in with ${ssoString}.`;

    return message;
  };

  const formatLoginCardAppMessage = () => {
    const appsString = cardAppLogin
      ?.map(({ provider }) => {
        switch (provider as AutoLoginProvider) {
          case "LotteCard":
            return isKorean ? "디지로카(롯데카드)" : "DIGI LOCA(Lottecard)";
          case "HanaCard":
            return isKorean ? "하나카드" : "Hanacard";
          case "Hanpass":
            return isKorean ? "한패스" : "Hanpass";
          case "GME":
            return "GME";
          case "RedTable":
            return isKorean ? "레드테이블" : "REDTABLE";
          default:
            const _exhaustiveCheck = provider;
            return _exhaustiveCheck;
        }
      })
      .join(", ");

    const message = isKorean
      ? `${appsString} 앱에서 라차 서비스를 이용했어요.`
      : `You have used Lacha service in ${appsString} apps.`;

    return message;
  };

  return (
    <Layout
      text={
        objective === "findId"
          ? intl.formatMessage({ id: "user.findId" })
          : intl.formatMessage({ id: "user.forgot" })
      }
      onBack={() => navigate(-2)}
    >
      <Card sx={{ m: 4, mx: 2, textAlign: "center" }}>
        <CardContent>
          <Stack gap={2}>
            <Stack alignItems={"center"} gap={1} sx={{ wordBreak: "keep-all" }}>
              {objective === "findId" ? (
                // id 찾기
                <>
                  {emails?.length === 0 ? (
                    <Typography gutterBottom variant="h6" component="div">
                      {isKorean
                        ? "가입하신 아이디가 없습니다."
                        : "Email not found."}
                    </Typography>
                  ) : (
                    <Stack alignItems="center" gap={1}>
                      {Boolean(directLogin?.length) && (
                        <>
                          <Typography variant="h6" color="text.secondary">
                            <FormattedMessage id="forgot.findIdSuccess2" />
                          </Typography>
                          {directLogin
                            ?.filter(
                              ({ loginId }) => !loginId.includes("anonymous")
                            )
                            .map((email, index) => (
                              <Typography
                                key={index}
                                variant="h6"
                                color="primary"
                              >
                                {` ${formatEmail(email.loginId)} `}
                              </Typography>
                            ))}
                          <Typography variant="h6" color="text.secondary">
                            <FormattedMessage id="forgot.findIdSuccess3" />
                          </Typography>
                        </>
                      )}
                      {Boolean(socialLogin?.length) && (
                        <>
                          <Divider flexItem />
                          <Typography variant="h6" color="text.secondary">
                            {isKorean ? "소셜 로그인 계정" : "Social Login"}
                          </Typography>
                          <Typography variant="h6" color="primary">
                            {formatSocialLoginMessage()}
                          </Typography>
                        </>
                      )}
                      {Boolean(cardAppLogin?.length) && (
                        <>
                          <Divider flexItem />
                          <Typography variant="h6" color="text.secondary">
                            {isKorean ? "라차 제휴 카드사" : "Card App"}
                          </Typography>
                          <Typography variant="h6" color="primary">
                            {formatLoginCardAppMessage()}
                          </Typography>
                        </>
                      )}
                    </Stack>
                  )}
                  <Divider flexItem />
                  <Typography variant="body2" color="text.secondary">
                    <FormattedMessage id="forgot.findIdSuccess4" />
                  </Typography>
                </>
              ) : (
                // password 찾기
                <>
                  <MailOutlineIcon sx={{ fontSize: 100, my: 2 }} />
                  <Typography
                    component="div"
                    variant="body1"
                    color="text.secondary"
                  >
                    <FormattedMessage
                      id="forgot.resetPassword"
                      values={{
                        email: (
                          <Typography component="span" color="text.secondary">
                            <Typography variant="h6" color="primary">
                              {email}
                            </Typography>
                          </Typography>
                        ),
                      }}
                    />
                  </Typography>
                </>
              )}
            </Stack>

            <Stack direction="row" spacing={1}>
              <CustomButton
                id="user.signIn"
                size="medium"
                onClick={handleLoginClick}
              />
              {objective === "findId" &&
                (emails?.length ? (
                  <CustomButton
                    id="user.forgot"
                    variant="outlined"
                    size="medium"
                    onClick={handleFindPassword}
                  />
                ) : (
                  <CustomButton
                    id="signup.title"
                    variant="outlined"
                    size="medium"
                    onClick={navigateToSignupPage}
                  />
                ))}
            </Stack>
          </Stack>
        </CardContent>
      </Card>
    </Layout>
  );
};

export default ConfirmationPage;
