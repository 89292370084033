import { Stack, Typography } from "@mui/material";
import CardLayout from "components/layout/CardLayout";
import useFormatPrice from "hooks/useFormatPrice";

interface LachaPointSectionProps {
  pointEarned?: number;
}

const LachaPointSection = ({ pointEarned = 0 }: LachaPointSectionProps) => {
  const formatPrice = useFormatPrice();

  return (
    <CardLayout>
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{ width: "100%" }}
      >
        <Typography variant="body1" sx={{ fontWeight: "bold" }}>
          적립 포인트:{" "}
        </Typography>
        <Typography variant="body1" sx={{ fontWeight: "bold" }}>
          {formatPrice(pointEarned)}
        </Typography>
      </Stack>
    </CardLayout>
  );
};

export default LachaPointSection;
