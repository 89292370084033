import ActivityDetailLayout from "./ActivityDetailLayout";
import CardLayout from "components/layout/CardLayout";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { Divider, Stack, Typography } from "@mui/material";
import { DateCalendar } from "@mui/x-date-pickers";
import useFormatPrice from "hooks/useFormatPrice";
import CustomButton from "components/button/CustomButton";
import { forwardRef } from "react";
import parse from "html-react-parser";

interface ProductTypeSectionProps {
  title: string;
  content: string;
}

const ProductTypeSection = forwardRef<HTMLDivElement, ProductTypeSectionProps>(
  ({ title, content }, ref) => {
    const formatPrice = useFormatPrice();

    return (
      <ActivityDetailLayout ref={ref} title={title}>
        <Stack gap={4}>
          <Stack gap={1}>
            <CardLayout
              header={
                <Stack
                  direction="row"
                  alignItems="center"
                  gap={1}
                  sx={{ px: 2, pt: 2 }}
                >
                  <CalendarMonthIcon />
                  <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                    사용일
                  </Typography>
                </Stack>
              }
              sx={{ p: 0 }}
            >
              <DateCalendar sx={{ m: 0 }} />
            </CardLayout>
            {[
              { name: "유니버셜스튜디오 재팬 1일 입장권", price: 55463 },
              { name: "유니버셜스튜디오 재팬 1.5일 입장권", price: 89328 },
              { name: "유니버셜스튜디오 재팬 2일 입장권", price: 105473 },
              {
                name: "유니버셜스튜디오 재팬 1일권 C시즌(성인권) + 간사이 조이패스",
                price: 115270,
              },
            ].map(({ name, price }, index) => (
              <CardLayout
                key={index}
                sx={{ width: "100%", textAlign: "start" }}
              >
                <Stack gap={1} sx={{ width: "100%" }}>
                  <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                    {name}
                  </Typography>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    sx={{ width: "100%" }}
                    gap={2}
                  >
                    <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                      {formatPrice(price)}
                    </Typography>
                    <CustomButton
                      variant="outlined"
                      size="small"
                      style={{ maxWidth: "64px" }}
                    >
                      선택
                    </CustomButton>
                  </Stack>
                </Stack>
              </CardLayout>
            ))}
          </Stack>

          <Divider />

          <Stack>{parse(content)}</Stack>
        </Stack>
      </ActivityDetailLayout>
    );
  }
);

export default ProductTypeSection;
