// export const recentSearches = [
//   { name: "서울", destination: "부산", path: "/seoul-busan" },
//   { name: "광명", destination: "동해", path: "/seoul-busan" },
//   { name: "대전", destination: "목포", path: "/seoul-busan" },
//   { name: "천안아산", destination: "강릉", path: "/seoul-busan" },
//   { name: "부산", destination: "울산", path: "/seoul-busan" },
//   { name: "울산", destination: "부산", path: "/seoul-busan" },
//   { name: "동대구", destination: "서울", path: "/seoul-busan" },
//   { name: "서울", destination: "엑스포", path: "/seoul-busan" },
//   { name: "인천", destination: "청주공항", path: "/seoul-busan" },
//   { name: "경주", destination: "용산", path: "/seoul-busan" },
//   { name: "수원", destination: "안동", path: "/seoul-busan" },
//   { name: "안동", destination: "수원", path: "/seoul-busan" },
//   { name: "용산", destination: "경주", path: "/seoul-busan" },
//   // more links...
// ];
export interface RouteInfo {
  stationName: string;
  path: string;
  departureCode: string;
  arrivalCode: string;
}

export interface StationInfo {
  stationName: string;
  stationCode: string;
}

export const popularRoutes: RouteInfo[] = [
  {
    stationName: "서울 → 부산",
    path: "/seoul-busan",
    departureCode: "0001",
    arrivalCode: "0020",
  },
  {
    stationName: "서울 → 동대구",
    path: "/seoul-dongdaegu",
    departureCode: "0001",
    arrivalCode: "0015",
  },
  {
    stationName: "용산 → 광주송정",
    path: "/yongsan-gwangjusongjeong",
    departureCode: "0104",
    arrivalCode: "0036",
  },
  {
    stationName: "청량리 → 강릉",
    path: "/cheongnyangni-gangneung",
    departureCode: "0090",
    arrivalCode: "0115",
  },
  {
    stationName: "용산 → 여수EXPO",
    path: "/yongsan-yeosuexpo",
    departureCode: "0104",
    arrivalCode: "0053",
  },
  {
    stationName: "용산 → 목포",
    path: "/yongsan-mokpo",
    departureCode: "0104",
    arrivalCode: "0041",
  },
];

export const frequentStations: StationInfo[] = [
  {
    stationName: "서울",
    stationCode: "0001",
  },
  {
    stationName: "청량리",
    stationCode: "0090",
  },
  {
    stationName: "용산",
    stationCode: "0104",
  },
  {
    stationName: "대전",
    stationCode: "0010",
  },
  {
    stationName: "강릉",
    stationCode: "0115",
  },
  {
    stationName: "동해",
    stationCode: "0113",
  },
  {
    stationName: "동대구",
    stationCode: "0015",
  },
  {
    stationName: "부산",
    stationCode: "0020",
  },
  {
    stationName: "목포",
    stationCode: "0041",
  },
  {
    stationName: "여수EXPO",
    stationCode: "0053",
  },
  {
    stationName: "광주송정",
    stationCode: "0036",
  },
  {
    stationName: "울산(통도사)",
    stationCode: "0509", //
  },
  {
    stationName: "안동",
    stationCode: "0526",
  },
  {
    stationName: "창원중앙",
    stationCode: "0512",
  },
  {
    stationName: "경주",
    stationCode: "0508",
  },
  {
    stationName: "포항",
    stationCode: "0515",
  },
];

// export const dummySchedules = [
//   {
//     discount: 30,
//     id: 1,
//     trainType: "KTX",
//     trainNumber: "101",
//     departureTime: "05:12",
//     arrivalTime: "07:49",
//     standardPrice: 84000, // 가격 in won (₩)
//     adultPrice: 84000, // 성인 가격
//     childPrice: 42000, // 어린이 가격
//   },
//   {
//     discount: 30,
//     id: 2,
//     trainType: "KTX",
//     trainNumber: "102",
//     departureTime: "06:15",
//     arrivalTime: "08:50",
//     standardPrice: 94000,
//     adultPrice: 94000,
//     childPrice: 47000,
//   },
//   {
//     discount: 30,
//     id: 3,
//     trainType: "KTX",
//     trainNumber: "103",
//     departureTime: "07:20",
//     arrivalTime: "09:55",
//     standardPrice: 104000,
//     adultPrice: 104000,
//     childPrice: 52000,
//   },
//   // ... more dummy schedules up to 10
// ];

// export const dummySeats = Array.from({ length: 6 }, (_, i) => ({
//   row: i + 1,
//   seats: [
//     { id: `${i + 1}A`, status: "forward" }, // Assuming seat in forward direction
//     { id: `${i + 1}B`, status: "backward" }, // Assuming seat in backward direction
//     { id: `${i + 1}C`, status: "unavailable" }, // Assuming seat is unavailable
//     { id: `${i + 1}D`, status: "selected" }, // Assuming seat is selected
//   ],
// }));
