import { Stack, Typography, useTheme } from "@mui/material";
import { forwardRef } from "react";

interface ActivityDetailLayoutProps {
  children: React.ReactNode;
  title: string;
}

const ActivityDetailLayout = forwardRef<
  HTMLDivElement,
  ActivityDetailLayoutProps
>(({ children, title }, ref) => {
  const theme = useTheme();

  return (
    <Stack
      ref={ref}
      sx={{
        bgcolor: theme.palette.white.main,
        p: 2,

        "& img": {
          width: "100% !important",
          height: "auto !important",
          maxWidth: "480px !important",
          borderRadius: "12px",
        },
      }}
      gap={1}
    >
      <Typography variant="h6" sx={{ fontWeight: "bold" }}>
        {title}
      </Typography>

      {children}
    </Stack>
  );
});

export default ActivityDetailLayout;
