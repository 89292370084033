import { useCallback, useRef } from "react";

/**
 * A custom hook that debounces a function call.
 * 
 * @param {number} delay - The amount of time to wait before calling the debounced function. Defaults to 300ms.
 * @returns {(callback: () => void) => number} - A function that takes a callback to debounce.
 *   The callback will be called after the specified delay, and can be canceled with clearTimeout.
 *   The return value is the timeoutId that can be used to cancel the debounced function.
 */
const useDebounce = (delay = 300) => {
  const timeoutId = useRef<ReturnType<typeof setTimeout>>();

  const debounce = useCallback(
    (callback: () => void) => {
      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
      }
      timeoutId.current = setTimeout(callback, delay);

      return timeoutId.current;
    },
    [delay]
  );

  return debounce;
};

export default useDebounce;
