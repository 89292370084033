import { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import AuthService from "services/authService";
import TokenService from "services/tokenService";
import { ProviderUtils } from "utils/providerUtils";

const AnonymousRoute = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isChecking, setIsChecking] = useState(true);

  useEffect(() => {
    if (ProviderUtils.isHanpass) {
      TokenService.removeAccessToken();
    }

    const checkLoggedIn = async () => {
      const response = await AuthService.isLoggedIn();
      setIsLoggedIn(response);
      setIsChecking(false);
    };
    checkLoggedIn();
  }, []);

  if (isLoggedIn) {
    // 이미 로그인되어있으면 홈페이지로 리다이렉트
    return <Navigate to="/" replace />;
  }

  return !isChecking && <Outlet />; // 로그인되어있지 않으면 그대로 진행
};

export default AnonymousRoute;
