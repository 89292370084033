import Header from "components/layout/Header";
import BookingForm from "./sections/BookingForm";
import Banner from "./sections/Banner";
import BackgroundImage from "./sections/bgImage/BackgroundImage";
import { Container } from "@mui/system";
import { useEffect, useState } from "react";
import Footer from "components/layout/Footer";
import { Checkbox, FormControlLabel, Stack, Typography } from "@mui/material";
import { StorageUtils } from "utils/storageUtils";
import { ProviderUtils } from "utils/providerUtils";
import history from "history/browser";
import { closeWebview } from "utils/lottecardUtils";
import { useAppDispatch } from "app/hooks";
import { deleteMyOrders, resetFilterSetting } from "app/myOrdersSlice";
import useModalSheet from "hooks/overlay/useModalSheet";
import LachaRewardBanner from "./sections/LachaRewardBanner";

const HomePage = () => {
  const dispatch = useAppDispatch();

  // const [NoticeModal] = useModalSheet({
  //   defaultVisible: sessionStorage.getItem("modalRead") !== "true",
  //   callbackOk: () => {
  //     sessionStorage.setItem("modalRead", "true");
  //   },
  // });

  const [pointModalVisible] = useState(
    localStorage.getItem("pointModalVisible") == null
      ? true
      : localStorage.getItem("pointModalVisible") === "true"
  );
  const [checkBoxStatus, setCheckBoxStatus] = useState(false);
  const [PointNoticeModal] = useModalSheet({
    // defaultVisible: localStorage.getItem("pointModalRead") !== "true",
    defaultVisible: pointModalVisible,
    callbackOk: () => {
      localStorage.setItem("pointModalVisible", (!checkBoxStatus).toString());
    },
  });

  useEffect(() => {
    dispatch(deleteMyOrders());
    dispatch(resetFilterSetting());

    StorageUtils.TrainTab.remove();
    StorageUtils.ScrollY.remove();
  }, [dispatch]);

  useEffect(() => {
    // navigate(window.location.pathname); 이 코드 있으면 안 됨 - url 복붙 하면 / 로 이동함

    const listenBackEvent = () => {
      if (ProviderUtils.isHanpass) {
        StorageUtils.HanpassBack.remove();
        window.location.href = "http://finish";
      }
      if (ProviderUtils.isLottecard) {
        closeWebview();
      }
    };

    const historyEvent = history.listen(({ action }) => {
      if (action === "POP") {
        listenBackEvent();
      }
    });

    return () => {
      historyEvent();
      // window.history.replaceState(null, "");
    };
  }, []);

  return (
    <>
      <Header />
      <BackgroundImage />
      <Stack justifyContent="space-between" sx={{ minHeight: "80vh" }}>
        <Container maxWidth="md" sx={{ position: "relative", top: "-10px" }}>
          <BookingForm />
          <LachaRewardBanner />
          {ProviderUtils.provider?.provider !== "lottecard" && <Banner />}
        </Container>
      </Stack>
      {/* {!ProviderUtils.isGME && (
        <NoticeModal modal>
          <Stack gap={2}>
            <Typography variant="h6">승차권 예약발매일 조정 알림</Typography>
            <Typography variant="body1">
              연말 신규 노선 개통계획에 따라 조정한 KTX 및 일반열차 승차권
              예약발매일을 다음과 같이 알려드리니 열차이용에 참고하시기
              바랍니다.
            </Typography>
            <Stack gap={1} sx={{ width: "100%", textAlign: "start" }}>
              <p>
                □ 대상열차 : <b>2024. 12. 20.(금)</b> ~ <b>12.31.(화)</b> 이후
                운행하는 KTX 열차
              </p>
              <p style={{ fontSize: "0.8em", color: "#ee1100" }}>
                * 신규 노선 개통과 관련된 열차의 예약발매일은 2024.12.12(목)
                15:00 이후 재공지
              </p>
              <p>
                □ 대상열차 승차권 예약발매일 :{" "}
                <b>2024. 12. 05.(목) 15:00 부터</b>
              </p>
            </Stack>
          </Stack>
        </NoticeModal>
      )} */}


      <PointNoticeModal modal>
        <Stack gap={2}>
          <Typography variant="h6">라차 적립금 정책 시행 안내</Typography>
          <Stack gap={1}>
            <Typography variant="body1">
              2024년 12월 4일부터 코레일의 정책 변경에 따라, 기존에 제공되던 KTX{" "}
              <b>최대 20% 할인</b> 혜택이{" "}
              <b>
                "
                {ProviderUtils.isLottecard
                  ? "최대 20% 적립"
                  : "최대 10% 적립 + 카카오T이용권 최대 10,000원 지급"}
                "
              </b>
              으로 변경되었음을 안내드립니다.
            </Typography>
            <Typography variant="body1">
              관련된 문의사항은 (주)라카쿠라차 고객센터를 통해 처리될
              예정입니다.
            </Typography>
          </Stack>
          <FormControlLabel
            onClick={() => {
              setCheckBoxStatus(!checkBoxStatus);
            }}
            control={<Checkbox checked={checkBoxStatus} />}
            label="다시 보지 않기"
            sx={{ textAlign: "end", alignSelf: "end", m: 0, p: 0 }}
          />
        </Stack>
      </PointNoticeModal>
      <Footer />
    </>
  );
};

export default HomePage;
