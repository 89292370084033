import { useRef, useState } from "react";
import {
  TextField,
  Grid,
  IconButton,
  InputAdornment,
  FormHelperText,
  MenuItem,
  FormControl,
  Select,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import CustomButton from "components/button/CustomButton";
import { Stack } from "@mui/system";
import { FormattedMessage, useIntl } from "react-intl";
import { FormType } from "hooks/useInputs";
import { ValidStateType } from "./RegistrationTab";
import { SelectChangeEvent } from "@material-ui/core";

interface InformationEntryFormProps {
  form: FormType;
  validState: ValidStateType;
  isIdAvailable: boolean | null;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleCheckUsername: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => Promise<void>;
  handleSelect: (_e: SelectChangeEvent) => void;
}

const InformationEntryForm = ({
  form,
  validState,
  isIdAvailable,
  handleChange,
  handleCheckUsername,
  handleSelect,
}: InformationEntryFormProps) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const domainInput = useRef<HTMLInputElement>(null);
  const intl = useIntl();

  return (
    <Stack gap={1.5}>
      <TextField
        fullWidth
        name="name"
        label={intl.formatMessage({ id: "signup.name" })}
        value={form.name}
        onChange={handleChange}
        autoComplete="off"
      />
      {/* 이메일 */}
      <Grid container spacing={1} justifyContent="end">
        <Grid item xs={7} sx={{ position: "relative" }}>
          <TextField
            type="text"
            name="prefix"
            label={intl.formatMessage({ id: "signup.email" })}
            value={form.prefix}
            onChange={handleChange}
            autoComplete="off"
            InputProps={{
              endAdornment: <InputAdornment position="end">@</InputAdornment>,
            }}
            sx={{ width: "100%" }}
            error={!!form.prefix && !validState.prefix}
          />
        </Grid>
        <Grid item xs={5}>
          <TextField
            inputRef={domainInput}
            type="text"
            name={form.domain === "typing" ? "typedDomain" : "domain"}
            disabled={form.domain !== "typing"}
            value={form.domain === "typing" ? form.typedDomain : form.domain}
            onChange={handleChange}
            autoComplete="off"
            sx={{ width: "100%" }}
            error={!!form.typedDomain && !validState.typedDomain}
          />
        </Grid>
        <Grid item xs={4}>
          {(form.prefix && !validState.prefix) ||
          (form.typedDomain && !validState.typedDomain) ? (
            <FormHelperText
              sx={{ px: 1.7, color: "#d32f2f", position: "absolute" }}
            >
              <FormattedMessage id="signup.emailInvalid" />
            </FormHelperText>
          ) : (
            isIdAvailable !== null && (
              <FormHelperText
                error={!isIdAvailable}
                sx={{
                  marginInlineStart: 1,
                  color: `${isIdAvailable ? "green" : ""}`,
                }}
              >
                {isIdAvailable ? (
                  <FormattedMessage id="signup.available" />
                ) : (
                  <span>
                    {intl.formatMessage({ id: "signup.unavailable" })}
                  </span>
                )}
              </FormHelperText>
            )
          )}
        </Grid>
        <Grid item xs={3} alignSelf="center">
          <CustomButton onClick={handleCheckUsername} disabled={isIdAvailable!}>
            <FormattedMessage id="signup.duplicate" />
          </CustomButton>
        </Grid>

        <Grid item xs={5}>
          <FormControl sx={{ width: "100%" }}>
            <Select
              name="domain"
              value={String(form.domain)}
              onChange={handleSelect}
              // blur select tag and focus domain input field after selecting option
              onClose={() => {
                setTimeout(() => {
                  if (document.activeElement) {
                    (document.activeElement as HTMLElement).blur();
                  }
                  if (domainInput.current) {
                    domainInput.current.focus();
                  }
                }, 0);
              }}
            >
              <MenuItem selected divider dense disabled value="domain.com">
                <FormattedMessage id="signup.domainSelect" />
              </MenuItem>
              {/* 직접 입력 선택하면 domain 입력창으로 focus하도록 수정 */}
              <MenuItem dense value="typing">
                <FormattedMessage id="signup.domainCustom" />
              </MenuItem>
              {/* Add more domains as needed */}
              {[
                "gmail.com",
                "naver.com",
                "daum.net",
                "nate.com",
                "outlook.com",
                "yahoo.com",
              ].map((domain) => (
                <MenuItem dense key={domain} value={domain}>
                  {domain}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      {/* 비밀번호 */}
      <TextField
        fullWidth
        type={showPassword ? "text" : "password"}
        name="password"
        label={intl.formatMessage({ id: "user.password" })}
        value={form.password}
        onChange={handleChange}
        autoComplete="off"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowPassword(!showPassword)}
                edge="end"
                tabIndex={-1}
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        error={!!form.password && !validState.password}
        helperText={
          form.password &&
          !validState.password && (
            <>
              <span>
                <FormattedMessage id="signup.passwordInvalid1" />
              </span>
              <br />
              <span>
                <FormattedMessage id="signup.passwordInvalid2" />
              </span>
            </>
          )
        }
      />
      <TextField
        fullWidth
        type={showConfirmPassword ? "text" : "password"}
        name="confirmPassword"
        label={intl.formatMessage({ id: "signup.password2" })}
        // disabled={!isPasswordValid}
        value={form.confirmPassword}
        onChange={handleChange}
        error={!!form.confirmPassword && form.password !== form.confirmPassword}
        helperText={
          form.confirmPassword &&
          form.password !== form.confirmPassword && (
            <FormattedMessage id="signup.passwordInvalid3" />
          )
        }
        autoComplete="off"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle confirm password visibility"
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                edge="end"
                tabIndex={-1}
              >
                {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      {/* 휴대폰 번호 */}
      <TextField
        fullWidth
        type="tel"
        name="phone"
        label={intl.formatMessage({ id: "signup.phone" })}
        value={form.phone}
        onChange={handleChange}
        // autoComplete="off"
        error={!!form.phone && !validState.phone}
        helperText={
          form.phone &&
          !validState.phone && (
            <span>
              <FormattedMessage id="signup.phoneInvalid" />
            </span>
          )
        }
      />
    </Stack>
  );
};

export default InformationEntryForm;
