import Layout from "components/layout/Layout";
import Ticket from "components/reservation/Ticket";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";

const TicketPage = () => {
  const intl = useIntl();
  const navigate = useNavigate();

  return (
    <Layout
      text={intl.formatMessage({ id: "orders.showTrainTicket" })}
      onBack={() => navigate(-1)}
    >
      <Ticket />
    </Layout>
  );
};

export default TicketPage;
