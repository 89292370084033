import React from "react";
import { Step } from "./Step";

// StepperContext.js
export const StepperContext = React.createContext();

export const useStepperContext = () => React.useContext(StepperContext);

// Example usage of the Step component within a Stepper component
const Stepper = ({ activeStep }) => {
  return (
    <StepperContext.Provider value={{ activeStep }}>
      <div className="stepper">
        <Step id={1}>약관동의</Step>
        <Step id={2}>정보입력</Step>
        <Step id={3}>가입완료</Step>
      </div>
    </StepperContext.Provider>
  );
};

export default Stepper;