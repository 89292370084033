import { Typography, Chip, Stack, useTheme } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import useIntlFormatPrice from "hooks/useFormatPrice";
import useFormatDateToLocaleString from "hooks/useFormatDateToLocaleString";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { OrderStatus, OrderType } from "types/orderType";
import useLanguages from "hooks/useLanguages";
import CardLayout from "./layout/CardLayout";
import useOrderStatusText from "hooks/useOrderStatusText";
import TicketPreview from "./reservation/TicketPreview";

type OrderStationSectionType = {
  order: OrderType;
};

const getChipColor = (status: OrderStatus) => {
  switch (status) {
    case "CANCELED":
      return "cancelled";
    case "PENDING":
      return "pending";
    case "COMPLETE":
    default:
      return "complete";
  }
};

const TrainTicket = ({ order }: OrderStationSectionType) => {
  // hooks
  const { isKorean } = useLanguages();
  const navigate = useNavigate();
  const theme = useTheme();
  const { pathname } = useLocation();
  const formatPrice = useIntlFormatPrice();
  const formatDateTime = useFormatDateToLocaleString();

  // Actions
  // 상세보기 클릭 핸들러
  const handleDetailClick = () => {
    navigate(`/order-details/${order.orderId}`);
  };

  // 주문 상태 코드를 텍스트로 변환하는 함수
  const getOrderStatusText = useOrderStatusText();

  const getHeader = () => {
    // 주문 상태와 생성 날짜 표시
    return (
      <Stack sx={{ width: "100%" }} gap={1}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          gap={1}
        >
          <Stack
            direction={isKorean ? "row" : "column"}
            alignItems={isKorean ? "center" : "start"}
            gap={1}
          >
            <Chip
              label={getOrderStatusText(order.orderStatus)}
              color={`${getChipColor(order.orderStatus)}`}
              variant={order.orderStatus === "COMPLETE" ? "filled" : "outlined"}
              sx={{
                fontWeight: "bold",
                alignSelf: "flex-start",
              }}
            />

            {isKorean && (
              <Typography
                variant="subtitle2"
                color="text.secondary"
                sx={{ wordBreak: "break-word" }}
              >
                {formatDateTime(order.orderCreatedDate)}
              </Typography>
            )}
          </Stack>

          {/* 상세보기 링크 */}
          <NavigateNextIcon
            style={{
              cursor: "pointer",
              whiteSpace: "nowrap",
            }}
            onClick={handleDetailClick}
            sx={{ alignSelf: "center" }}
          />
        </Stack>
        {!isKorean && (
          <Typography
            variant="subtitle2"
            color="text.secondary"
            sx={{ wordBreak: "break-word", alignSelf: "flex-end" }}
          >
            {formatDateTime(order.orderCreatedDate)}
          </Typography>
        )}
      </Stack>
    );
  };

  const getAdditionalInfo = () => {
    // 총 가격 표시
    return (
      <Typography
        variant={pathname.includes("order-history") ? "h6" : "body1"}
        color={
          theme.palette.mode === "light" ? "primary.main" : "primary.light"
        }
        sx={{
          width: "100%",
          textAlign: "right",
          fontWeight: "bold",
        }}
      >
        {/* {formatPrice(order.totalPrice)} // 할인금액  */}
        {formatPrice(order.originalPrice)}
      </Typography>
    );
  };

  return (
    <CardLayout header={getHeader()} additionalInfo={getAdditionalInfo()}>
      <TicketPreview reservationDetails={order} />
    </CardLayout>
  );
};

export default TrainTicket;
