import moment from "moment";
import DateWrapper from "components/layout/DateWrapper";
import { Stack } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import { styled } from "@mui/material/styles";
import CardLayout from "components/layout/CardLayout";

const onwDayFromToday = moment().add(1, "days");
const oneMonthFromToday = moment().add(1, "months");

interface DateSelectorProps {
  date: string;
  onPrev: () => void;
  onNext: () => void;
}

const DateSelector = ({ date, onPrev, onNext }: DateSelectorProps) => {
  const isPreviousDisabled = moment(date).isSameOrBefore(
    onwDayFromToday,
    "day"
  );
  const isNextDisabled = moment(date).isSameOrAfter(oneMonthFromToday, "day");

  return (
    <CardLayout
      sx={{
        padding: 0,
        borderStartEndRadius: 0,
        borderStartStartRadius: 0,
      }}
    >
      <DateWrapper>
        <Stack direction="row" alignItems="center" justifyContent="center">
          <ButtonWrapper disabled={isPreviousDisabled}>
            <NavigateBeforeIcon
              onClick={
                moment(date).isAfter(onwDayFromToday, "day")
                  ? onPrev
                  : undefined
              }
              color={isPreviousDisabled ? undefined : "primary"}
            />
          </ButtonWrapper>
          <p>{date}</p>
          <ButtonWrapper disabled={isNextDisabled}>
            <NavigateNextIcon
              onClick={
                moment(date).isBefore(oneMonthFromToday, "day")
                  ? onNext
                  : undefined
              }
              color={isNextDisabled ? undefined : "primary"}
            />
          </ButtonWrapper>
        </Stack>
      </DateWrapper>
    </CardLayout>
  );
};

export default DateSelector;

const ButtonWrapper = styled("button")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",

  button: {
    padding: 0,
  },
}));
