export const getMobileOperatingSystem = () => {
  var userAgent = navigator.userAgent;

  // Android detection
  if (/android/i.test(userAgent)) {
    return "Android";
  }

  // iOS detection
  if (/iPad|iPhone|iPod/i.test(userAgent)) {
    return "iOS";
  }

  // Other mobile OS or not mobile
  return "unknown";
};

export const getUserAgent = () => {
  var userAgent = navigator.userAgent;

  if (/Mobile|unionpay/i.test(userAgent)) {
    return "mobile";
  }

  return "desktop";
};
