import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useIntl, FormattedMessage } from "react-intl";
import parse from "html-react-parser";

const CancelTermSection = () => {
  const intl = useIntl();

  return (
    <Stack sx={{ width: "100%" }}>
      {["1", "4"].map((element) => {
        return (
          <Accordion
            key={element}
            disableGutters
            sx={{
              boxShadow: "none",
              "& .MuiButtonBase-root": { px: 0 },
              "& .MuiAccordionSummary-content": { my: 0 },
              width: "100%",
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${element}-content`}
              id={`panel${element}-header`}
            >
              <Typography variant="body2">
                <FormattedMessage id={`term${element}.title`} />
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                fontSize: "0.1rem",
              }}
            >
              <Divider />
              {parse(`${intl.formatMessage({ id: `term${element}.content` })}`)}
            </AccordionDetails>
          </Accordion>
        );
      })}
    </Stack>
  );
};

export default CancelTermSection;
