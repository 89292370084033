import React from "react";
import { Menu, MenuItem } from "@mui/material";
import AuthService from "services/authService";
import { LanguageCodeType, languageCodes } from "const/languageCodes";
import { changeLanguage, deleteUser } from "app/userSlice";
import { useAppDispatch, useAppSelector } from "app/hooks";
import UserService from "services/userService";

interface LanguageSelectorProps {
  anchorEl: SVGSVGElement | null;
  handleClose: () => void;
  type?: "ALL" | "ENG";
}

const LanguageSelector = ({
  anchorEl,
  handleClose,
  type = "ALL",
}: LanguageSelectorProps) => {
  // redux state
  const dispatch = useAppDispatch();
  const { userId, language: currentLanguage } = useAppSelector(
    (state) => state.user
  );

  const languageOptions =
    type === "ALL"
      ? languageCodes
      : ([
          { code: "en", name: "English" },
          { code: "ko", name: "한국어" },
        ] as const);

  // Action
  const handleLanguageChange = async (language: LanguageCodeType) => {
    // 페이지 언어 설정 변경
    handleClose();
    const updatedUserInfo = {
      // ...user, // assuming 'user' contains the current user's information
      language,
    };
    if (userId) {
      if (await AuthService.isLoggedIn()) {
        UserService.update(updatedUserInfo)
          .then((response) => {
            console.log("Language updated successfully", response);
          })
          .catch((error) => {
            console.error("Error updating language", error);
          });
      } else {
        dispatch(deleteUser());
      }
    }

    dispatch(changeLanguage(language));
  };

  return (
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleClose}
      sx={{ zIndex: 100000000 }}
    >
      {languageOptions.map(
        (language): React.ReactElement => (
          <MenuItem
            key={language.code}
            onClick={() => handleLanguageChange(language.code)}
            autoFocus={language.code === currentLanguage}
            sx={
              language.code === currentLanguage
                ? {
                    color: "primary.main",
                    fontWeight: "bold",
                    bgcolor: "primary.light",
                  }
                : {}
            }
          >
            {language.name}
          </MenuItem>
        )
      )}
    </Menu>
  );
};

export default LanguageSelector;
