import { Grid, Stack, Typography } from "@mui/material";
import Arrow from "components/Arrow";
import StationInfo from "components/booking_info/StationInfo";
import { useGetTicketDetails } from "hooks/bookingInfos/hooks";
import useFormatDateToLocaleString from "hooks/useFormatDateToLocaleString";
import useFormatSeatDetails from "hooks/useFormatSeatDetails";
import { FormattedMessage, useIntl } from "react-intl";
import { OrderType } from "types/orderType";
import { getSeatDetailsFromOrder } from "utils/formatUtils";

interface ReservationDetailsProps {
  reservationDetails: OrderType;
}
const ReservationDetails = ({
  reservationDetails,
}: ReservationDetailsProps) => {
  const intl = useIntl();
  const formatDateTime = useFormatDateToLocaleString();
  const formatSeatDetails = useFormatSeatDetails();

  const seatDetails = getSeatDetailsFromOrder(reservationDetails);
  const ticketDetails = useGetTicketDetails(reservationDetails);
  const stationDetail = {
    departure: ticketDetails.departure,
    arrival: ticketDetails.arrival,
    startDate: ticketDetails.departureTime,
    endDate: ticketDetails.arrivalTime,
  };

  return (
    <Stack gap={0.5} sx={{ width: "100%" }}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        gap={2.5}
        sx={{ width: "100%", mt: 1 }}
      >
        <StationInfo status="DEPARTURE" detail={stationDetail} />
        <Arrow />
        <StationInfo status="ARRIVAL" detail={stationDetail} />
      </Stack>
      <Typography variant="body1" sx={{ mb: 1, textAlign: "center" }}>
        {ticketDetails.duration}
      </Typography>
      <Grid container>
        <DetailGridItem
          id="orders.reserveNumber"
          text={reservationDetails.apiReserveNumber}
        />
        <DetailGridItem
          id="trainMain.dateOfDeparture"
          text={formatDateTime(reservationDetails.startDate, {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          })}
        />
        <DetailGridItem
          id="orders.trainNumber"
          text={`${reservationDetails.trainList[0].trainType} ${parseInt(
            reservationDetails.trainList[0].trainCode
          )}`}
        />
        <DetailGridItem
          id="booking.passengers"
          text={intl.formatMessage(
            { id: "booking.passengersDetail" },
            {
              total:
                reservationDetails.adultCount +
                reservationDetails.childrenCount,
              adults: reservationDetails.adultCount,
              children: reservationDetails.childrenCount,
            }
          )}
        />
        <DetailGridItem
          id="booking.seats"
          text={formatSeatDetails(seatDetails)}
        />
      </Grid>
    </Stack>
  );
};

export default ReservationDetails;

const DetailGridItem = ({ id, text }: { id: string; text?: string }) => {
  return (
    <Grid
      item
      xs={12}
      gap={2}
      display="flex"
      justifyContent="space-between"
      sx={{
        "& *": {
          wordBreak: "keep-all",
        },
      }}
    >
      <Typography variant="caption" color="text.secondary">
        <FormattedMessage id={id} />:
      </Typography>
      <Typography
        variant="caption"
        color="text.secondary"
        sx={{ textAlign: "end" }}
      >
        {text}
      </Typography>
    </Grid>
  );
};
