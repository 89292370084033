import { Stack, TextField, Typography, useTheme } from "@mui/material";
import { Container } from "@mui/system";
import CardLayout from "components/layout/CardLayout";
import Layout from "components/layout/Layout";
import TransactionList from "./components/TransactionList";
import { useEffect, useState } from "react";
import PeriodFilter from "components/PeriodFilter";
import usePeriodFilter from "hooks/usePeriodFilter";
import CustomButton from "components/button/CustomButton";
import CardService, { TransactionInterface } from "services/cardService";
import { useParams } from "react-router-dom";
import { NumericString } from "types/numericString";

const CardTransactions = () => {
  const {
    period,
    handlePeriodChange,
    // fromDate,
    // setFromDate,
    // toDate,
    // setToDate,
  } = usePeriodFilter("1");
  const theme = useTheme();
  const { cardNumber } = useParams() as { cardNumber: NumericString };

  const [searchTerm, setSearchTerm] = useState("");

  const [transactionList, setTransactionList] = useState<
    TransactionInterface[]
  >([]);

  useEffect(() => {
    // fetch transactions using infinite scroll
  });

  const handleChangeSearchTerm = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const handleSearch = () => {
    // TODO: Filter transactions results based on searchTerm
    console.log(searchTerm);
  };

  useEffect(() => {
    if (cardNumber) {
      const getTransactionList = async () => {
        const { data } = await CardService.getTransactionList(cardNumber);
        if (data) {
          setTransactionList(data.list);
        }
      };

      getTransactionList();
    }
  }, [cardNumber]);

  return (
    <Layout text={`Card ${cardNumber}`}>
      <PeriodFilter period={period} handlePeriodChange={handlePeriodChange} />
      {/* Search Bar */}
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        gap={1}
        sx={{ width: "98%", mx: "auto" }}
      >
        <TextField
          value={searchTerm}
          onChange={handleChangeSearchTerm}
          color="primary"
          sx={{
            width: "100%",
            "& .MuiInputBase-input": {
              py: 0,
              height: "40px",
              borderRadius: "16px",
              bgcolor: theme.palette.white.main,
            },
          }}
        />
        <CustomButton
          id="stationSelectionPopup.searchButtonText"
          size="medium"
          fullWidth={false}
          onClick={handleSearch}
        />
      </Stack>

      <Container maxWidth="sm" sx={{ width: "100%", mt: 1, px: 0 }}>
        <CardLayout>
          <Stack gap={1} sx={{ width: "100%" }}>
            <Typography variant="h5" sx={{ fontWeight: "bold", mb: 1 }}>
              Transactions
            </Typography>

            <TransactionList transactions={transactionList} />
          </Stack>
        </CardLayout>
      </Container>
    </Layout>
  );
};

export default CardTransactions;
