import { useState } from "react";
import { Box, Tab, Tabs, Card, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Layout from "components/layout/Layout";
import AuthService from "services/authService";
import BasicInfoUpdate from "./BasicInfoUpdate";
import ConsentInfoUpdate from "./ConsentInfoUpdate";
import { deleteUser, updateUser } from "app/userSlice";
import { deleteReservation } from "app/reservationSlice";
import { deleteMyOrders } from "app/myOrdersSlice";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { useIntl } from "react-intl";
import DeleteAccountButton from "components/button/DeleteAccountButton";
import useLanguages from "hooks/useLanguages";
import { ProviderUtils } from "utils/providerUtils";
import useModalSheet from "hooks/overlay/useModalSheet";

export interface InfoUpdateProps {
  userInfo: {
    name: string;
    phone: string;
    birthDate: string;
    isReceivingEmail: boolean;
    isReceivingSms: boolean;
    isReceivingTalk: boolean;
    password?: string;
    currentPassword?: string;
    confirmPassword?: string;
  };
  setUserInfo: React.Dispatch<
    React.SetStateAction<InfoUpdateProps["userInfo"]>
  >;
  setModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const PersonalInfoFormPage = () => {
  const intl = useIntl();
  const { isKorean } = useLanguages();
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const {
    name,
    phone,
    birthDate,
    isReceivingEmail,
    isReceivingSms,
    isReceivingTalk,
  } = useAppSelector((state) => state.user);
  const [userInfo, setUserInfo] = useState({
    name,
    phone,
    birthDate,
    isReceivingEmail,
    isReceivingSms,
    isReceivingTalk,
  });

  const [selectedTab, setSelectedTab] = useState(0);

  const handleModalClose = () => {
    dispatch(updateUser(userInfo));
  };
  const [Modal, setModalVisible] = useModalSheet({
    callbackOk: handleModalClose,
  });

  const handleDeleteAccount = async () => {
    let conf = window.confirm(
      isKorean
        ? "정말 회원 탈퇴하시겠습니까?"
        : "Are you sure you want to delete your account?"
    );
    if (!conf) {
      return;
    }
    await AuthService.withdraw();
    dispatch(deleteUser());
    dispatch(deleteReservation());
    dispatch(deleteMyOrders());
    navigate("/");
  };

  const tabLabels = [
    intl.formatMessage({ id: "user.info" }),
    intl.formatMessage({ id: "user.consent" }),
  ];

  return (
    <>
      <Layout
        text={intl.formatMessage({ id: "user.info.change" })}
        onBack={() => navigate(-1)}
      >
        <Stack justifyContent="space-between">
          <Stack>
            <Box sx={{ borderBottom: 1, borderColor: "grey.500" }}>
              <Tabs
                value={selectedTab}
                onChange={(_e, newValue) => setSelectedTab(newValue)}
                variant="fullWidth"
                indicatorColor="primary"
                textColor="primary"
                role="navigation"
              >
                {tabLabels.map((label, index) => (
                  <Tab key={index} label={label} sx={{ fontWeight: "bold" }} />
                ))}
              </Tabs>
            </Box>
            <Card
              sx={{
                mt: 3,
                mb: 2,
                p: 2,
                marginInlineStart: 0,
                wordBreak: "keep-all",
              }}
            >
              {selectedTab === 0 && (
                <BasicInfoUpdate
                  userInfo={userInfo}
                  setUserInfo={setUserInfo}
                  setModalVisible={setModalVisible}
                />
              )}
              {selectedTab === 1 && (
                <ConsentInfoUpdate
                  userInfo={userInfo}
                  setUserInfo={setUserInfo}
                  setModalVisible={setModalVisible}
                />
              )}
            </Card>
          </Stack>

          {selectedTab === 0 && ProviderUtils.loginAvailable && (
            <DeleteAccountButton onClick={handleDeleteAccount} />
          )}
        </Stack>
      </Layout>

      <Modal modal>
        <Typography variant="h6" sx={{ fontWeight: "normal" }}>
          {intl.formatMessage({ id: "user.save.complete" })}
        </Typography>
      </Modal>
    </>
  );
};

export default PersonalInfoFormPage;
