import { Divider, Stack } from "@mui/material";
import Transaction from "./Transaction";
import { TransactionInterface } from "services/cardService";

interface TransactionListProps {
  transactions?: TransactionInterface[];
}

const TransactionList = ({ transactions }: TransactionListProps) => {
  return (
    <Stack gap={1}>
      {transactions?.map((transaction) => (
        <Stack gap={1} key={transaction.trDt + transaction.trTm}>
          <Divider />
          <Transaction transaction={transaction} />
        </Stack>
      ))}
    </Stack>
  );
};

export default TransactionList;
