import { forwardRef } from "react";
import ActivityDetailLayout from "./ActivityDetailLayout";
import parse from "html-react-parser";
import { Stack } from "@mui/material";

interface ProductInfoSectionProps {
  title: string;
  content: string;
}

const ProductInfoSection = forwardRef<HTMLDivElement, ProductInfoSectionProps>(
  ({ title, content }, ref) => {
    return (
      <ActivityDetailLayout ref={ref} title={title}>
        <Stack
     
        >
          {parse(content)}
        </Stack>
      </ActivityDetailLayout>
    );
  }
);

export default ProductInfoSection;
