import { Tab, Tabs, useTheme } from "@mui/material";
import { HEADER_HEIGHT } from "const/layout";

interface ActivityDetailTabProps {
  tabValue: number;
  handleTabChange: (event: React.SyntheticEvent, newValue: number) => void;
  tabLabels: string[];
}

const ActivityDetailTab = ({
  tabValue,
  handleTabChange,
  tabLabels,
}: ActivityDetailTabProps) => {
  const theme = useTheme();

  return (
    <Tabs
      value={tabValue}
      onChange={handleTabChange}
      indicatorColor="primary"
      textColor="primary"
      variant="scrollable"
      sx={{
        position: "sticky",
        top: `${HEADER_HEIGHT - 2}px`,
        bgcolor: theme.palette.white.main,
        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
        zIndex: 1,
      }}
    >
      {tabLabels.map((label, index) => (
        <Tab key={index} label={label} sx={{ fontWeight: "bold" }} />
      ))}
    </Tabs>
  );
};

export default ActivityDetailTab;
