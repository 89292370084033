import { useEffect, useState } from "react";
import { Grid, useMediaQuery } from "@mui/material";
import CenterAisle from "components/seat/CenterAisle";
import Seat, { SeatDetails } from "components/seat/Seat";
import { CarType, SeatDirection } from "types/carType";

export type SeatStatus = "available" | "unavailable" | "selected";

interface SeatStatusInfo {
  [key: string]: SeatStatus;
}

export interface SeatInfo {
  [key: string]: string;
  alcSeatDmnPsDvCd: string;
  dirSeatAttCd: "009" | "010"; // 009: 순방, 010: 역방
  drwySeatAttCd: string;
  etcSeatAttCd: string;
  fulSegFlg: string;
  gdMrkGpId: string;
  gdNo: string;
  locSeatAttCd: "011" | "012" | "013"; // 011: 1인, 012: 창측, 013: 내측
  rqSeatAttCd: string;
  salePsbFlg: string;
  seatNo: string;
  seatSpec: string;
  sgrNo: string;
  smkSeatAttCd: string;
}

interface SeatMapProps {
  seats: SeatInfo[];
  onSeatSelect: (seat: SeatInfo, newStatus: SeatStatus) => void;
  selectedSeats: SeatDetails[];
  scar: CarType;
}

// Type guard using type predicates
const isSeatSection = (section: string | SeatInfo[]): section is SeatInfo[] => {
  return section instanceof Object;
};

const SeatMap = ({
  seats,
  onSeatSelect,
  selectedSeats,
  scar,
}: SeatMapProps) => {
  // 좌석 상태 초기화
  // seats 배열이 변경될 때마다 seatStatuses를 업데이트하는 useEffect
  const [seatStatuses, setSeatStatuses] = useState<SeatStatusInfo>({});
  const [sortedSeats, setSortedSeats] = useState<SeatInfo[]>([]);
  const direction = scar.seatDirDvCd;

  // Galaxy Z-Flip
  const matches = useMediaQuery("(max-width:362px)");

  useEffect(() => {
    const newSeatStatus = seats.reduce<SeatStatusInfo>(
      (acc, seat: SeatInfo): SeatStatusInfo => {
        //   // 판매 가능 여부에 따라 좌석 상태 설정
        acc[seat.seatNo] =
          seat.salePsbFlg === "Y" ? "available" : "unavailable";
        return acc;
      },
      {}
    );
    setSeatStatuses(newSeatStatus);

    // 좌석 배열 정렬
    sortSeatsByDirection(seats, direction);
  }, [seats, direction]); // seats 배열이 변경될 때만 실행

  // const [seatStatuses, setSeatStatuses] = useState(
  //   seats.reduce((acc, seat) => {
  //     // 판매 가능 여부에 따라 좌석 상태 설정
  //     acc[seat.seatNo] = seat.salePsbFlg === "Y" ? "available" : "unavailable";
  //     return acc;
  //   }, {})
  // );

  const sortSeatsByDirection = (
    seats: SeatInfo[],
    direction: SeatDirection
  ) => {
    // Assuming the seatSpec encodes the seat's physical position in some manner
    // Adjust the logic based on how seatSpec or another property indicates order
    const sorted = [...seats].sort((a, b) => {
      // 순방향일 때
      if (direction === "1") {
        return Number(a.seatNo) - Number(b.seatNo);
      }
      // 역방향일 때
      else if (direction === "2") {
        return Number(b.seatNo) - Number(a.seatNo);
      }
      return 0;
    });

    setSortedSeats(sorted);
  };

  const handleSeatClick = (seat: SeatInfo) => {
    if (seat.salePsbFlg !== "Y") {
      return; // 판매 불가능한 좌석은 클릭 불가
    }

    const seatKey = `${seat.scarNo}-${seat.seatNo}`;
    const newStatus: SeatStatus =
      seatStatuses[seatKey] === "selected" ? "available" : "selected";
    const updatedSeatStatuses = { ...seatStatuses, [seatKey]: newStatus };

    // 좌석 상태 업데이트 및 선택 이벤트 처리
    setSeatStatuses(updatedSeatStatuses);
    onSeatSelect(seat, newStatus);
  };

  // 좌석 배열을 두 그룹으로 나누는 로직
  // const leftSeats = seats.slice(0, seats.length / 2);
  // const rightSeats = seats.slice(seats.length / 2);

  // Utility function to filter and sort seats based on seatSpec ending and order
  const filterAndSortSeats = (
    seatSpecEnding: string,
    direction: SeatDirection
  ) => {
    return (
      sortedSeats
        // seats
        .filter((seat) => seat.seatSpec.endsWith(seatSpecEnding))
        .sort((a, b) => {
          // direction이 "1"이면 내림차순, "2"이면 오름차순 정렬
          if (direction === "1") {
            return parseInt(b.seatSpec) - parseInt(a.seatSpec);
          } else if (direction === "2") {
            return parseInt(a.seatSpec) - parseInt(b.seatSpec);
          }
          return 0;
        })
    );
  };

  // Filter and sort seats for each section
  const leftWindowSeats = filterAndSortSeats("A", direction);
  const leftAisleSeats = filterAndSortSeats("B", direction);
  const rightWindowSeats = filterAndSortSeats("C", direction);
  const rightAisleSeats = filterAndSortSeats("D", direction);
  const seatSections = [
    leftWindowSeats,
    leftAisleSeats,
    "centerAisle",
    rightWindowSeats,
    rightAisleSeats,
  ];

  // 좌석 맵 렌더링
  return (
    <Grid
      container
      spacing={matches ? 0 : 0.5}
      justifyContent="space-between"
      style={matches ? {} : { margin: "16px 0" }}
    >
      {(direction === "1" ? seatSections : seatSections.reverse()).map(
        (section, index) => {
          if (!isSeatSection(section)) {
            return (
              <Grid
                item
                key={section}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CenterAisle />
              </Grid>
            );
          }
          return (
            <Grid item key={index}>
              <Seat
                seatGroup={section}
                selectedSeats={selectedSeats}
                seatStatuses={seatStatuses}
                scarNo={scar.scarNo}
                handleSeatClick={handleSeatClick}
              />
            </Grid>
          );
        }
      )}
    </Grid>
  );
};

export default SeatMap;
