import CustomButton from "components/button/CustomButton";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useTheme } from "@mui/material";
import TRAVELPACK_LINK from "const/lottecard";

interface TravelPackCardProps {
  text: string;
  chevron?: boolean;
}

const TravelPackCard = ({ text, chevron = false }: TravelPackCardProps) => {
  const theme = useTheme();

  const navigateToTravelPack = () => {
    // window.location.href = TRAVELPACK_LINK;
    window.open(TRAVELPACK_LINK, "_blank");
  };

  return (
    <CustomButton
      size="medium"
      variant="outlined"
      style={{ backgroundColor: theme.palette.white.main }}
      onClick={navigateToTravelPack}
    >
      {text}
      {chevron && <ChevronRightIcon />}
    </CustomButton>
  );
};

export default TravelPackCard;
