import { Stack, Typography } from "@mui/material";
import useFormatDateToLocaleString from "hooks/useFormatDateToLocaleString";
import React from "react";
import { useIntl } from "react-intl";

interface TicketHeaderProps {
  dateTime: string;
  qrCode: React.ReactNode;
}

const TicketHeader = ({ dateTime, qrCode }: TicketHeaderProps) => {
  const intl = useIntl();
  const formatDateTime = useFormatDateToLocaleString();

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      gap={2}
      sx={{ flexWrap: "nowrap", width: "100%" }}
    >
      <Stack gap={0.5}>
        <Typography
          variant="subtitle2"
          sx={{
            textAlign: "start",
            lineHeight: "0.9",
            fontWeight: "bold",
          }}
        >
          {intl.formatMessage({ id: "trainMain.dateOfDeparture" })}
        </Typography>
        <Typography
          variant="subtitle1"
          sx={{ textAlign: "start", lineHeight: "1.6" }}
        >
          {dateTime} (
          {formatDateTime(dateTime, {
            weekday: "short",
          })}
          )
        </Typography>
      </Stack>
      <Stack direction="row" justifyContent="end">
        {qrCode}
      </Stack>
    </Stack>
  );
};

export default TicketHeader;
