import centralImage from "assets/images/seat/seat_arrow.png";

const CenterAisle = () => {
  return (
    <div
      style={{
        height: "100%", // Adjust the height as needed
        width: "24px",
        // width: "100%",
        backgroundImage: `url(${centralImage})`,
        backgroundRepeat: "repeat-y",
        backgroundPosition: "center",
        backgroundSize: "contain", // Adjust size as necessary
      }}
    ></div>
  );
};

export default CenterAisle;
