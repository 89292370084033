import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { MyOrder } from 'services/authService';
import { OrderStatus, OrderType } from "types/orderType";
import { calculateDateBeforeOneMonth } from "utils/dateUtils";

interface FilterSettings {
  status: "ALL" | OrderStatus;
  period: string;
  fromDate: string;
  toDate: string;
}

interface MyOrderState {
  [key: string]: MyOrderState[keyof MyOrderState];
  recentOrders: OrderType[];
  orders: OrderType[];
  number: number;
  totalPages: number;
  last: boolean;
  filterSettings: FilterSettings;
  // filteredOrders: [], // 필터링된 주문들
}

const initialState: MyOrderState = {
  recentOrders: [],
  orders: [], // 주문들 (초기 값은 빈 배열)
  number: -1, // 전달받은 페이지 number
  totalPages: 1,
  last: false,
  filterSettings: {
    // 필터 설정
    status: "ALL", // 상태
    period: "1", // 기간
    fromDate: calculateDateBeforeOneMonth(), // 시작 날짜
    toDate: "", // 종료 날짜
  },
  // filteredOrders: [], // 필터링된 주문들
};

const myOrdersSlice = createSlice({
  name: "myOrders",
  initialState,
  reducers: {
    fetchRecentOrders: {
      reducer: (state, action: PayloadAction<OrderType[]>) => {
        state.recentOrders = action.payload;
      },
      prepare: (recentOrders: OrderType[]) => {
        return {
          payload: recentOrders,
        };
      },
    },
    fetchMyOrders: {
      reducer: (
        state,
        action: PayloadAction<{
          orders: OrderType[];
          totalPages: number;
          last: boolean;
          number: number;
        }>
      ) => {
        return { ...state, ...action.payload };
      },
      prepare: (response: {
        content: OrderType[];
        totalPages: number;
        first: boolean;
        last: boolean;
        number: number;
      }) => {
        return {
          payload: {
            orders: response.content,
            totalPages: response.totalPages,
            last: response.last,
            number: response.number,
          },
        };
      },
    },
    appendMyOrders: (
      state,
      action: PayloadAction<MyOrder>
    ) => {
      const { content, totalPages, first, last, number } = action.payload;
      const orders = first ? [...content] : [...state.orders, ...content];

      state.orders = orders;
      state.totalPages = totalPages;
      state.last = last;
      state.number = number;
    },

    updateMyOrders: (state, action: PayloadAction<Partial<MyOrderState>>) => {
      for (const key in action.payload) {
        const value = action.payload[key];
        if (value) {
          state[key] = value;
        }
      }
    },

    resetFilterSetting: (state) => {
      state.filterSettings = initialState.filterSettings;
    },

    deleteMyOrders: (state) => {
      for (const key in state) {
        if (key === "recentOrders") {
          continue;
        }
        state[key] = initialState[key];
      }
    },
  },
});

export const {
  fetchRecentOrders,
  fetchMyOrders,
  updateMyOrders,
  appendMyOrders,
  resetFilterSetting,
  deleteMyOrders,
} = myOrdersSlice.actions;

export default myOrdersSlice.reducer;
