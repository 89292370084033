import { useNavigate } from "react-router-dom";
import { Typography, Button, Stack } from "@mui/material";
import { FormattedMessage } from "react-intl";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import useLanguages from "hooks/useLanguages";

const RecentOrderHeader = () => {
  const { isLanguage } = useLanguages();

  const navigate = useNavigate();

  const handleMoreClick = () => {
    navigate("/order-history");
  };

  return (
    <Stack alignItems="center" justifyContent="space-between" direction="row">
      <Typography variant="h6" sx={{ fontWeight: "bold", mx: 1 }}>
        <FormattedMessage id="user.recentOrders" />
      </Typography>
      <Button
        endIcon={isLanguage("ur") ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        color="secondary"
        onClick={handleMoreClick}
        sx={{ fontWeight: "bold", alignSelf: "flex-end" }}
      >
        <FormattedMessage id="user.more" />
      </Button>
    </Stack>
  );
};

export default RecentOrderHeader;
