import { FormattedMessage, useIntl } from "react-intl";
import {
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import styled from "styled-components";
import { CostResponse } from "services/trainService";
import InfoIcon from "@mui/icons-material/Info";
import { useEffect, useState } from "react";
import CardLayout from "components/layout/CardLayout";
import useFormatPrice from "hooks/useFormatPrice";

interface CostDetailsProps {
  costDetails: CostResponse[];
}

const CostDetailsSection = ({ costDetails }: CostDetailsProps) => {
  const intl = useIntl();
  const theme = useTheme();
  const formatPrice = useFormatPrice();

  const matches = useMediaQuery("(max-width: 512px)");

  // price/kakaoT data
  const adultPriceDetail = costDetails?.find(
    (detail) => detail.trnPsrmClCd === "1" && detail.trnPsgTpCd === "1"
  );
  const childPriceDetail = costDetails?.find(
    (detail) => detail.trnPsrmClCd === "1" && detail.trnPsgTpCd === "3"
  );
  const pointSavingRate = costDetails?.[0]?.pointSavingRt;
  const kakaoCouponAmount = costDetails?.[0]?.couponAmount;

  const [showInfo, setShowInfo] = useState(false);
  const toggleInfo = (e: React.MouseEvent) => {
    e.stopPropagation();
    setShowInfo(!showInfo);
  };

  const hideInfo = () => {
    setShowInfo(false);
  };

  useEffect(() => {
    window.addEventListener("click", hideInfo);

    return () => {
      window.removeEventListener("click", hideInfo);
    };
  }, []);

  return (
    <MediaQueryWrapper>
      {/* 카카오 Chip */}
      <Stack
        direction="row"
        alignItems="center"
        gap={0.5}
        sx={{ position: "relative" }}
      >
        {Boolean(pointSavingRate) && (
          <Typography
            variant="body2"
            sx={{
              backgroundColor: theme.palette.error.main,
              color: theme.palette.error.contrastText,
              borderRadius: theme.shape.borderRadius,
              px: 1,
              py: 0.5,
              mb: 0.5,
              fontWeight: "bold",
              fontSize: "0.8em",
            }}
          >
            {pointSavingRate}% 적립
          </Typography>
        )}
        {Boolean(kakaoCouponAmount) && (
          <>
            <Typography
              variant="body2"
              sx={{
                backgroundColor: theme.palette.error.main,
                color: theme.palette.error.contrastText,
                borderRadius: theme.shape.borderRadius,
                px: 1,
                py: 0.5,
                mb: 0.5,
                fontWeight: "bold",
                fontSize: "0.8em",
              }}
            >
              카카오T {kakaoCouponAmount?.toLocaleString()}원 혜택 제공
            </Typography>

            <InfoIcon
              fontSize="small"
              color="disabled"
              onClick={toggleInfo}
              sx={{ position: "absolute", right: -22, bottom: 4 }}
            />
            {showInfo && (
              <Tooltip title="purchase information" arrow>
                <CardLayout
                  sx={{
                    p: 1,
                    position: "absolute",
                    top: matches ? 28 : 8,
                    left: 48,
                    maxWidth: "80%",
                    zIndex: 10,
                  }}
                >
                  <Typography
                    variant="caption"
                    sx={{
                      lineHeight: 1.3,
                      textAlign: "center",
                      wordBreak: "keep-all",
                    }}
                  >
                    <FormattedMessage
                      id="train.costDetailedInformation"
                      defaultMessage={intl.formatMessage({
                        id: "This train ticket is part of a Korail travel package and can only be used when purchased in conjunction with a Kakao T coupon",
                      })}
                    />
                  </Typography>
                </CardLayout>
              </Tooltip>
            )}
          </>
        )}
      </Stack>

      {/* 기차 가격 표시 */}
      <Stack
        direction="row"
        gap={0.5}
        style={{
          color: theme.palette.mode === "light" ? grey[700] : grey[300],
        }}
      >
        <Typography component="span" variant="body2">
          {
            intl
              .formatMessage(
                { id: "fareInquiry.adultPrice" },
                { price: formatPrice(adultPriceDetail?.csmrFare) }
              )
              .split(":")[0]
          }
          :{" "}
        </Typography>

        <Stack
          direction="row"
          alignItems="center"
          gap={1}
          sx={{ position: "relative" }}
        >
          <Typography
            component="span"
            variant="body2"
            sx={{
              textDecorationColor: theme.palette.error.main,
            }}
          >
            {
              intl
                .formatMessage(
                  { id: "fareInquiry.adultPrice" },
                  { price: formatPrice(adultPriceDetail?.csmrFare) }
                )
                .split(":")[1]
            }
          </Typography>
        </Stack>
      </Stack>

      <Typography
        component="span"
        variant="body2"
        style={{
          color: theme.palette.mode === "light" ? grey[700] : grey[300],
        }}
      >
        {intl.formatMessage(
          { id: "fareInquiry.childPrice" },
          { price: formatPrice(childPriceDetail?.mrkFare) }
        )}
      </Typography>
    </MediaQueryWrapper>
  );
};

export default CostDetailsSection;

const MediaQueryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media screen and (max-width: 512px) {
    align-items: center;
  }
`;
