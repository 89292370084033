import { Typography, Stack } from "@mui/material";
import ScrollToTopButton from "components/ScrollToTopButton";
// import PeriodFilter from "components/PeriodFilter";
import CardLayout from "components/layout/CardLayout";
import Layout from "components/layout/Layout";
import useFormatPrice from "hooks/useFormatPrice";
import useIntersect from "hooks/useIntersect";
import useLanguages from "hooks/useLanguages";
import { useCallback, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import UserService, { PointHistoryType } from "services/userService";

const getPointEventType = (type: PointHistoryType["eventType"]) => {
  switch (type) {
    case "SAVING":
      return <FormattedMessage id="point.earn" />;
    case "USING":
      return <FormattedMessage id="point.use" />;
    case "EXPIRY":
      return <FormattedMessage id="point.expiration" />;
    case "RETURN":
      return <FormattedMessage id="point.refund" />;
    default:
      const _exhaustiveCheck: never = type;
      return _exhaustiveCheck;
  }
};

const PointHistory = () => {
  const intl = useIntl();
  const { isKorean } = useLanguages();

  const [pointHistories, setPointHistories] = useState<PointHistoryType[]>([]);
  const formatPrice = useFormatPrice();

  const [last, setLast] = useState(false);
  const [number, setNumber] = useState(-1);
  const [isFetching, setIsFetching] = useState(false);
  const [isScrollToTopButtonShow, setIsScrollToTopButtonShow] = useState(false);

  const infiniteScrollingTarget = useIntersect(async (entry, observer) => {
    observer.unobserve(entry.target);
    if (!last && !isFetching) {
      fetchHistories();
    }
  });

  const scrollToTopButtonTarget = useIntersect(
    () => setIsScrollToTopButtonShow(false),
    () => setIsScrollToTopButtonShow(true)
  );

  const fetchHistories = useCallback(async () => {
    setIsFetching(true);

    try {
      const response = await UserService.getPointHistory(number + 1);
      setPointHistories((prev) => [...prev, ...response.content]);
      setNumber(response.number);
      setLast(response.last);
    } catch (error) {
      console.error("Error while fetching point history", error);
    } finally {
      setIsFetching(false);
    }
  }, [number]);

  useEffect(() => {
    // First history load
    if (number === -1) {
      fetchHistories();
    }
  }, [fetchHistories, number]);

  const getHeader = (
    createdDate: PointHistoryType["createdDate"],
    type: PointHistoryType["eventType"]
  ) => {
    return (
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{ width: "100%", wordBreak: "keep-all" }}
        gap={1}
      >
        <Typography
          variant="body1"
          color="secondary"
          sx={{ fontWeight: "bold" }}
        >
          {getPointEventType(type)}
        </Typography>
        <Typography
          variant="body1"
          color="text.secondary"
          sx={{ fontWeight: "bold" }}
        >
          {new Date(createdDate).toLocaleDateString(intl.locale)}
        </Typography>
      </Stack>
    );
  };

  const getAdditionalInfo = (expireDate: string) => {
    return (
      <Stack
        direction="row"
        justifyContent="end"
        alignItems="center"
        sx={{ width: "100%" }}
      >
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{ fontWeight: "bold" }}
        >
          {intl.formatMessage({ id: "point.expiration_date" })}:{" "}
          {new Date(expireDate).toLocaleDateString(intl.locale)}
        </Typography>
      </Stack>
    );
  };

  return (
    <Layout text={intl.formatMessage({ id: "point.points_earning_history" })}>
      <div ref={scrollToTopButtonTarget}></div>
      {/* <PeriodFilter period={period} handlePeriodChange={handlePeriodChange} /> */}

      <Stack gap={1} sx={{ py: 1 }}>
        {pointHistories.length > 0 ? (
          pointHistories.map(
            ({
              createdDate,
              eventType,
              amount,
              savingDesc,
              orderId,
              expireDate,
            }) => (
              <CardLayout
                header={getHeader(createdDate, eventType)}
                key={createdDate}
                additionalInfo={expireDate && getAdditionalInfo(expireDate)}
              >
                {savingDesc && (
                  <Stack
                    direction="row"
                    justifyContent="start"
                    sx={{ width: "100%" }}
                  >
                    {savingDesc}
                  </Stack>
                )}
                {orderId && (
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    sx={{ width: "100%" }}
                  >
                    <FormattedMessage id="orders.orderId" />: <p>{orderId}</p>
                  </Stack>
                )}
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  sx={{ width: "100%" }}
                >
                  <FormattedMessage id="point.point" />:
                  <p>{formatPrice(amount)}</p>
                </Stack>
              </CardLayout>
            )
          )
        ) : (
          <Stack
            justifyContent="center"
            alignItems="center"
            sx={{ minHeight: "50vh", width: "100%" }}
          >
            <Typography variant="h5">
              {/* {intl.formatMessage({ id: "point.empty" })} */}
              {isKorean ? "적립 내역이 없습니다." : "No history found."}
            </Typography>
          </Stack>
        )}
      </Stack>
      {isScrollToTopButtonShow && <ScrollToTopButton />}
      <div ref={infiniteScrollingTarget}></div>
    </Layout>
  );
};

export default PointHistory;
