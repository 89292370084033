import React, { useEffect, useRef } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Container,
  useTheme,
  Stack,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import ThemeToggle from "components/ThemeToggle";
import Footer from "./Footer";
import { ProviderUtils } from "utils/providerUtils";
import CloseIcon from "@mui/icons-material/Close";
import { closeWebview } from "utils/lottecardUtils";
import { HEADER_HEIGHT } from "const/layout";

interface LayoutProps {
  children: React.ReactNode;
  text?: string | React.ReactElement;
  onBack?: () => void;
  footer?: boolean;
}

const Layout = ({ children, text, onBack, footer = true }: LayoutProps) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const navBar = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const id = setTimeout(() => {
      navBar.current?.scrollIntoView({
        behavior: "instant",
        block: "start",
      });
    }, 0);

    return () => {
      clearTimeout(id);
    };
  }, []);

  return (
    <Stack
      justifyContent="space-between"
      sx={{
        overflow: "hidden",
        minHeight: "calc(100vh - env(safe-area-inset-top))",
      }}
    >
      {/* TODO: boxShadow Header와 동일 -> theme으로 추출하기 */}
      <AppBar
        ref={navBar}
        position="sticky"
        sx={{
          bgcolor: theme.palette.white.main,
          boxShadow: `0 2px 4px rgba(0,0,0,0.1)`,
          direction: "ltr",
          position: "fixed",

          width: "100%",
          height: `calc(env(safe-area-inset-top) + ${HEADER_HEIGHT}px)`,
          paddingTop: `env(safe-area-inset-top)`,
        }}
      >
        <Toolbar
          variant="dense"
          style={{
            display: "flex",
            justifyContent: "space-between",
            position: "relative",
          }}
        >
          <IconButton
            onClick={onBack ? onBack : () => navigate(-1)}
            size="small"
            edge="start"
            aria-label="back"
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography
            variant="h6"
            component="div"
            color="black.main"
            sx={{
              flexGrow: 1,
              textAlign: "center",
              fontWeight: "bold",
              fontSize: "1rem",
              position: "absolute",
              left: "50%",
              transform: "translateX(-50%)",
            }}
          >
            {text}
          </Typography>
          {ProviderUtils.darkModeAvailable && <ThemeToggle />}
          {ProviderUtils.isLottecard &&
            window.location.pathname === "/order-history" && (
              <Stack color={"black.main"} onClick={closeWebview}>
                <CloseIcon />
              </Stack>
            )}
        </Toolbar>
      </AppBar>

      <Container
        maxWidth={"md"}
        sx={{
          display: "flex",
          flexDirection: "column",
          p: 2,
          mt: 4,
          width: "100%",
        }}
      >
        {children}
      </Container>
      {footer && <Footer />}
    </Stack>
  );
};

export default Layout;
