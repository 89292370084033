import { Box, Grid, Modal, Stack, Typography, useTheme } from "@mui/material";
import CustomButton from "components/button/CustomButton";
import CardLayout from "components/layout/CardLayout";
import { forwardRef, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import useLanguages from "../useLanguages";
import { ProviderUtils } from "utils/providerUtils";
import { GME_BOTTOM_PADDING } from "const/layout";

interface CustomBottomSheetProps {
  children: React.ReactNode;
  variant?: "button" | "icon"; // button - 확인/취소 버튼 있는 모달, icon - 취소 아이콘 있는 모달
  height?: string;
  modal?: boolean;
  okText?: string | React.ReactElement;
  cancellable?: boolean;
  cancelText?: string;
  cover?: boolean;
  title?: string;
  buttonDirection?: "row" | "column";
}

interface useModalSheetProps {
  defaultVisible?: boolean;
  callbackOk?: () => void; // modal 확인 버튼 클릭 시 실행할 콜백
  callbackCancel?: () => void; // modal 취소 버튼 클릭 시 실행할 콜백
}

const useModalSheet = ({
  defaultVisible = false,
  callbackOk,
  callbackCancel,
}: useModalSheetProps = {}) => {
  const theme = useTheme();
  const { isKorean } = useLanguages();
  const [visible, setVisible] = useState(defaultVisible);

  // TODO: css height %로 지정하는 방법(?)
  const CustomBottomSheet = ({
    children,
    variant = "button",
    modal,
    cancellable = false,
    okText = isKorean ? "확인" : "Ok",
    cancelText = isKorean ? "취소" : "Cancel",
    cover = false,
    title,
    buttonDirection = "row",
  }: CustomBottomSheetProps) => {
    const hideModalSheet = () => {
      setVisible(false);
    };

    const handleOk = () => {
      callbackOk?.();
      hideModalSheet();
    };

    const handleCancel = () => {
      callbackCancel?.();
      hideModalSheet();
    };

    const ModalContent = forwardRef<HTMLDivElement>((props, ref) => {
      /* 실제 모달 content */
      const cardStyle = modal
        ? ({
            width: "clamp(280px, 80vw, 440px)",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          } as const)
        : ({
            width: "100vw",
            position: "fixed",
            bottom: `calc(env(safe-area-inset-bottom))`,
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            maxHeight: cover
              ? `calc(100dvh - env(safe-area-inset-top) - ${0}px)`
              : "80dvh",
            pb: `${ProviderUtils.isGME ? GME_BOTTOM_PADDING : 0}px`,
          } as const);

      return (
        <Box {...props} ref={ref}>
          {visible && (
            <CardLayout
              sx={{
                ...cardStyle,
                overflow: "auto",
              }}
            >
              {modal
                ? variant === "icon" && (
                    <CloseIcon
                      onClick={handleCancel}
                      sx={{ position: "fixed", right: "16px", zIndex: 10 }}
                    />
                  )
                : (title || variant === "icon") && (
                    <Stack
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      sx={{
                        position: "fixed",
                        width: "100%",
                        height: "56px",
                        transform: "translateY(-16px)",
                        bgcolor: theme.palette.white.main,
                        borderRadius: "8px 8px 0 0",
                        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                        zIndex: 5,
                      }}
                    >
                      {title && (
                        <Typography
                          variant="body1"
                          sx={{
                            fontWeight: "bold",
                            textAlign: "center",
                            width: "80%",
                          }}
                        >
                          {title}
                        </Typography>
                      )}
                      {variant === "icon" && (
                        <CloseIcon
                          onClick={handleCancel}
                          sx={{ position: "fixed", right: "16px", zIndex: 10 }}
                        />
                      )}
                    </Stack>
                  )}
              <Grid
                container
                direction="column"
                justifyContent="space-between"
                alignItems="stretch"
                sx={{ position: "relative" }}
              >
                <Grid
                  item
                  xs
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "center",
                    wordBreak: "keep-all",
                    position: "relative",
                  }}
                >
                  {children}
                </Grid>
                {variant === "button" && (
                  <Grid item xs={1} gridAutoRows="1fr">
                    <Stack
                      direction={buttonDirection}
                      justifySelf="flex-end"
                      sx={{
                        mt: 2,
                        width: "100%",
                      }}
                      gap={1}
                    >
                      {cancellable && buttonDirection === "row" && (
                        <CustomButton
                          type="button"
                          variant="outlined"
                          color="error"
                          size="medium"
                          onClick={handleCancel}
                          sx={{ width: "100%" }}
                        >
                          {cancelText}
                        </CustomButton>
                      )}
                      <CustomButton
                        type="button"
                        size="medium"
                        onClick={handleOk}
                        sx={{ width: "100%" }}
                      >
                        {okText}
                      </CustomButton>
                      {cancellable && buttonDirection === "column" && (
                        <CustomButton
                          type="button"
                          variant="outlined"
                          color="error"
                          size="medium"
                          onClick={handleCancel}
                          sx={{ width: "100%" }}
                        >
                          {cancelText}
                        </CustomButton>
                      )}
                    </Stack>
                  </Grid>
                )}
              </Grid>
            </CardLayout>
          )}
        </Box>
      );
    });

    return (
      <Modal
        open={visible}
        onClose={handleCancel}
        aria-labelledby="modal"
        // aria-describedby="modal-modal-description"
        sx={{ zIndex: 100000001 }}
      >
        <ModalContent />
      </Modal>
    );
  };

  return [CustomBottomSheet, setVisible] as const;
};

export default useModalSheet;
