import { Stack, Typography } from "@mui/material";
import { OrderType } from "types/orderType";
import EastIcon from "@mui/icons-material/East";
import WestIcon from "@mui/icons-material/West";
import useGetOrderName from "hooks/useGetOrderName";
import useLanguages from "hooks/useLanguages";
import useFormatDateToLocaleString from "hooks/useFormatDateToLocaleString";

interface TicketPreviewProps {
  reservationDetails: OrderType;
}

const TicketPreview = ({ reservationDetails }: TicketPreviewProps) => {
  const getOrderName = useGetOrderName();
  const formatDateTime = useFormatDateToLocaleString();
  const { isLanguage } = useLanguages();

  return (
    <Stack
      alignItems="start"
      justifyContent="center"
      gap={0.5}
      sx={{ py: 1, width: "100%" }}
    >
      {/* 기차 아이콘과 주문 이름 */}
      <Typography
        variant="subtitle1"
        color="text.primary"
        sx={{ position: "block", width: "100%", fontWeight: "bold" }}
      >
        {getOrderName(reservationDetails?.orderName)}
      </Typography>
      <Typography variant="caption" color="text.secondary" gutterBottom>
        <Stack component="span" direction="row" alignItems="center" gap={0.8}>
          <span style={{ textAlign: "center" }}>
            {formatDateTime(reservationDetails?.startDate)}
          </span>
          {isLanguage("ur") ? (
            <WestIcon fontSize="inherit" />
          ) : (
            <EastIcon fontSize="inherit" />
          )}
          <span style={{ textAlign: "center" }}>
            {formatDateTime(reservationDetails?.endDate)}
          </span>
        </Stack>
      </Typography>
    </Stack>
  );
};

export default TicketPreview;
