import { Checkbox, Grid, Stack, Typography, useTheme } from "@mui/material";
import CustomButton from "components/button/CustomButton";
import CardLayout from "components/layout/CardLayout";
import Layout from "components/layout/Layout";
import { BOTTOM_NAVIGATION_HEIGHT } from "const/layout";
import useModalSheet from "hooks/overlay/useModalSheet";
import useFormatPrice from "hooks/useFormatPrice";
import { useRef, useState } from "react";

const dummyCart = [
  {
    id: 1,
    name: "title1",
    description: "description1",
    image: "https://picsum.photos/100",
    price: 130000,
    count: 1,
  },
  {
    id: 2,
    name: "title2",
    description: "description2",
    image: "https://picsum.photos/100",
    price: 210500,
    count: 1,
  },
  {
    id: 3,
    name: "title3",
    description: "description3",
    image: "https://picsum.photos/100",
    price: 170000,
    count: 1,
  },
  {
    id: 4,
    name: "title4",
    description: "description4",
    image: "https://picsum.photos/100",
    price: 57500,
    count: 1,
  },
  {
    id: 5,
    name: "title5",
    description: "description5",
    image: "https://picsum.photos/100",
    price: 20500,
    count: 1,
  },
  // {
  //   id: 6,
  //   name: "title6",
  //   description: "description6",
  //   image: "https://picsum.photos/100",
  //   price: 10000,
  //   count: 1,
  // },
  // {
  //   id: 7,
  //   name: "title7",
  //   description: "description7",
  //   image: "https://picsum.photos/100",
  //   price: 10000,
  //   count: 1,
  // },
  // {
  //   id: 8,
  //   name: "title8",
  //   description: "description8",
  //   image: "https://picsum.photos/100",
  //   price: 10000,
  //   count: 1,
  // },
];

type CartItem = (typeof dummyCart)[0];

const ShoppingCartPage = () => {
  const theme = useTheme();
  const formatPrice = useFormatPrice();
  const [selectedItems, setSelectedItems] = useState<CartItem[]>([]);

  const removeItemName = useRef("");
  const [Modal, setModalVisible] = useModalSheet({
    callbackOk: () => {
      // 장바구니 목록에서 삭제하기
      console.log("장바구니 목록에서 삭제하기");

      // 선택된 항목에서도 삭제하기
    },
    callbackCancel: () => {
      removeItemName.current = "";
    },
  });

  const handleRemoveItem = (item: CartItem) => {
    removeItemName.current = item.name;
    // setSelectedItems(selectedItems.filter((i) => i.id !== item.id));
    setModalVisible(true);
  };

  return (
    <Layout text="My Cart" footer={false}>
      <Stack
        gap={1}
        sx={{
          mt: 1,
          mb: `${BOTTOM_NAVIGATION_HEIGHT * 3}px`,
        }}
      >
        {dummyCart.length === 0 ? (
          <CardLayout>
            <Stack gap={2}>
              <Stack alignItems="center">
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                  장바구니에 담긴 상품이 없습니다.
                </Typography>
                <Typography variant="h6" color="text.secondary">
                  원하는 상품을 장바구니에 담아보세요!
                </Typography>
              </Stack>

              <Stack gap={1} sx={{ width: "100%" }}>
                <CustomButton variant="outlined" size="large">
                  KTX 예매하기
                </CustomButton>
                <CustomButton variant="outlined" size="large">
                  유니버셜 스튜디오 재팬 예매하기
                </CustomButton>
              </Stack>
            </Stack>
          </CardLayout>
        ) : (
          dummyCart.map((item) => (
            <CardLayout key={item.id}>
              <Stack
                direction="row"
                gap={1}
                sx={{ width: "100%", position: "relative" }}
              >
                <Checkbox
                  size="small"
                  onChange={(e) => {
                    if (e.target.checked) {
                      setSelectedItems([...selectedItems, item]);
                    } else {
                      setSelectedItems(
                        selectedItems.filter((i) => i.id !== item.id)
                      );
                    }
                  }}
                  sx={{
                    alignSelf: "start",
                    p: 0,
                  }}
                />
                <img
                  src={item.image}
                  alt={item.name}
                  style={{
                    width: "88px",
                    height: "88px",
                    borderRadius: 8,
                  }}
                />
                <Stack gap={0.5} sx={{ ml: 1, width: "100%" }}>
                  <Typography
                    variant="h6"
                    color="text.secondary"
                    sx={{ fontWeight: "bold", fontSize: "0.9rem" }}
                  >
                    {item.name}
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ fontWeight: "bold", fontSize: "1rem" }}
                  >
                    {formatPrice(item.price)}
                  </Typography>

                  <CustomButton
                    size="small"
                    variant="outlined"
                    onClick={() => handleRemoveItem(item)}
                    style={{ width: "max-content", alignSelf: "flex-end" }}
                  >
                    지우기
                  </CustomButton>
                </Stack>
              </Stack>
            </CardLayout>
          ))
        )}
      </Stack>

      <Grid
        container
        columnSpacing={1}
        alignItems="center"
        sx={{
          position: "fixed",
          width: "100vw",
          maxHeight: `${BOTTOM_NAVIGATION_HEIGHT * 3}px`,
          minHeight: `${BOTTOM_NAVIGATION_HEIGHT * 2}px`,
          bottom: 0,
          left: 8,
          bgcolor: theme.palette.white.main,
          boxShadow: "0 -2px 4px rgba(0, 0, 0, 0.1)",

          p: 2,
        }}
      >
        <Grid item xs={6}>
          <Stack>
            <Typography
              color="text.secondary"
              sx={{ fontWeight: "bold", fontSize: "1rem" }}
            >
              Total
            </Typography>
            <Typography
              color="primary"
              sx={{ fontWeight: "bold", fontSize: "1.3rem" }}
            >
              {formatPrice(selectedItems.reduce((a, b) => a + b.price, 0))}
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <CustomButton size="large">주문하기</CustomButton>
        </Grid>
      </Grid>
      <Modal modal cancellable>
        <Typography variant="body1" sx={{ fontSize: "1.2rem" }}>
          장바구니에서 <b>{removeItemName.current}</b>을 삭제하시겠습니까?
        </Typography>
      </Modal>
    </Layout>
  );
};

export default ShoppingCartPage;
