import { useReducer } from "react";
import REGEX from "const/regex";
import useDebounce from "./useDebounce";

export type BaseType = {
  [key: string]: boolean;
};

export type ValidStateType<T> = {
  [key in keyof T]: boolean;
};

type ActionType<T> = {
  name: keyof T;
  value: boolean;
};

type Reducer<T> = (
  state: ValidStateType<T>,
  action: ActionType<T>
) => ValidStateType<T>;

/**
 * Reducer to update the state based on the action.
 * If the action name (after removing all numbers) is a key in the REGEX object,
 * the state will be updated to have the action value.
 * Otherwise, the state is unchanged.
 *
 * @param {ValidStateType<T>} state the current state
 * @param {ActionType<T>} action the action to take
 * @returns {ValidStateType<T>} the new state
 */
const reducer = <T>(
  state: ValidStateType<T>,
  action: ActionType<T>
): ValidStateType<T> => {
  // Check if action name without number is in REGEX object's key
  if (Object.keys(REGEX).includes(String(action.name).replace(/[0-9]/g, ""))) {
    return {
      ...state,
      [action.name]: action.value,
    };
  }
  return state;
};

/**
 * A custom hook to validate the input value.
 * 
 * @param {T extends BaseType} initialState - The initial state of the validation.
 * @returns {[ValidStateType<T>, (e: React.ChangeEvent<HTMLInputElement>) => void]} - The current state of the validation and a function to validate the input.
 * 
 * The function returned as the second item in the array will debounce the validation by 300ms.
 * If the name of the input element is a key in the REGEX object, the state will be updated
 * to have the boolean value of the regex test. Otherwise, the state is unchanged.
 * 
 * The name of the input element should be the same as the key in the initialState, or should
 * be the same with the key but with numbers added.
 * 
 * This hook is intended to be used in a form with multiple inputs, where the inputs are
 * validated on change.
 * 
 * @example
 * const [validState, validate] = useValidate({ name: "", email: "" });
 * 
 * return (
 *   <form>
 *     <input type="text" name="name" onChange={validate} />
 *     <input type="email" name="email" onChange={validate} />
 *   </form>
 * );
 */
const useValidate = <T extends BaseType>(initialState: T) => {
  const [validState, dispatch] = useReducer<Reducer<T>>(reducer, initialState);
  const debounce = useDebounce();
  
  // event.target.name과 initialState의 key 값이 동일하거나, key에 숫자만 추가된 경우에만 update 
  const validate = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    
    debounce(() => {
      dispatch({ name, value: REGEX[name.replace(/[0-9]/g, "")].test(value) });
    });
  };

  return [validState, validate] as const;
};

export default useValidate;

//

// export type ValidStateType = Record<string, boolean>;

// type ActionType = {
//   name: string;
//   value: boolean;
// };

// const reducer = (state: ValidStateType, action: ActionType): ValidStateType => {
//   // Check if action name without number is in REGEX object's key
//   if (Object.keys(REGEX).includes(action.name.replace(/[0-9]/g, ""))) {
//     return {
//       ...state,
//       [action.name]: action.value,
//     };
//   }
//   return state;
// };

// const useValidate = (initialState: ValidStateType) => {
//   const [validState, dispatch] = useReducer(reducer, initialState);
//   const DEBOUNCE_DELAY = 300;
//   // const timeoutId = useRef<number | null>(null);
//   const timeoutId = useRef<ReturnType<typeof setTimeout>>();

//   const validate = (e: React.ChangeEvent<HTMLInputElement>) => {
//     const { name, value } = e.target;
//     if (timeoutId.current) {
//       clearTimeout(timeoutId.current);
//     }
//     timeoutId.current = setTimeout(() => {
//       dispatch({ name, value: REGEX[name.replace(/[0-9]/g, "")].test(value) });
//     }, DEBOUNCE_DELAY);
//   };

//   return [validState, validate] as const;
// };

// export default useValidate;
