import { Button } from "@mui/material";
import { useIntl } from "react-intl";
import { styled } from "@mui/system";
import { ButtonProps } from "@material-ui/core/Button";
import { LanguageCodeType } from "const/languageCodes";

const StyledButton = styled(Button)<{
  locale: LanguageCodeType;
  weight?: "string";
}>`
  padding: ${(props) => (props.locale === "ko" ? "0 16px" : "0 8px")};
  line-height: normal;

  word-break: keep-all;

  min-height: ${(props) => {
    switch (props.size) {
      case "small":
        return "32px";
      case "medium":
        return "40px";
      default:
        return "48px";
    }
  }};
  min-width: ${(props) => {
    switch (props.size) {
      case "small":
        return "80px";
      case "medium":
        return "96px";
      default:
        return "100%";
    }
  }};
  font-weight: ${(props) => props.weight || "bold"};
  font-size: ${(props) => {
    switch (props.size) {
      case "medium":
        return props.locale === "ko" ? "1em" : "0.9em";
      case "small":
        return props.locale === "ko" ? "0.8em" : "0.8em";
      default:
        return "1em";
    }
  }};
`;

interface CustomButtonProps {
  price?: number | string;
  locale?: LanguageCodeType;
  disabled?: boolean;
  height?: string;
}

const CustomButton = ({
  id,
  disabled,
  className,
  onClick,
  price,
  style,
  fullWidth = true,
  size,
  color = "secondary",
  variant = "contained",
  type = "submit",
  children,
}: ButtonProps & CustomButtonProps) => {
  const intl = useIntl();

  // const isFunctionDisabled = (
  //   disabled: DisabledBoolean | DisabledFunction
  // ): disabled is DisabledFunction => {
  //   return typeof disabled !== "boolean";
  // };

  return (
    <StyledButton
      className={className}
      variant={variant}
      color={color}
      type={type}
      disableElevation={true}
      fullWidth={fullWidth}
      disabled={disabled}
      onClick={onClick && onClick}
      style={style}
      locale={intl.locale as LanguageCodeType}
      size={size}
      disableRipple
    >
      {id
        ? intl.formatMessage(
            {
              id,
              // id: "bookingForm.searchButton",
              defaultMessage: "search button", // A default message if the ID's translation is not found
            },
            {
              price:
                price !== undefined
                  ? //  && price >= 0
                    price.toLocaleString()
                  : undefined,
            }
          )
        : children}
    </StyledButton>
  );
};

export default CustomButton;
