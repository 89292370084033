import { useCallback, useEffect, useRef } from "react";

type IntersectHandler = (
  entry: IntersectionObserverEntry,
  observer: IntersectionObserver
) => void;

/**
 * Custom React hook that observes the intersection of a target element with an ancestor element or the viewport(default).
 * 
 * @param onIntersect - Function to be called when the target element intersects with the observer.
 * @param onNoIntersect - Optional function to be called when the target element is no longer intersecting with the observer.
 * @param options - Optional configuration object for the IntersectionObserver.
 * 
 * @returns A ref to be attached to the DOM element you want to observe.
 */
const useIntersect = (
  onIntersect: IntersectHandler,
  onNoIntersect?: IntersectHandler,
  options?: IntersectionObserverInit
) => {
  const ref = useRef<HTMLDivElement>(null);
  const callback = useCallback(
    (entries: IntersectionObserverEntry[], observer: IntersectionObserver) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          onIntersect(entry, observer);
        } else {
          onNoIntersect?.(entry, observer);
        }
      });
    },
    [onIntersect, onNoIntersect]
  );

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    const observer = new IntersectionObserver(callback, options);
    observer.observe(ref.current);
    return () => {
      observer.disconnect();
    };
  }, [ref, callback, options]);

  return ref;
};

export default useIntersect;
