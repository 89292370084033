import { Stack, useTheme } from "@mui/material";
import CustomButton from "components/button/CustomButton";
import { BOTTOM_NAVIGATION_HEIGHT } from "const/layout";

export const OrderButton = () => {
  const theme = useTheme();

  return (
    <Stack
      direction="row"
      justifyContent="center"
      alignItems="center"
      sx={{
        p: 1,
        px: 2,
        height: `${BOTTOM_NAVIGATION_HEIGHT + 8}px`,
        width: "100%",

        position: "fixed",
        bottom: 0,
        left: 0,

        bgcolor: theme.palette.white.main,
        zIndex: 5,

        boxShadow: "0 -2px 4px rgba(0, 0, 0, 0.1)",
      }}
    >
      <Stack
        direction="row"
        gap={1}
        sx={{
          my: 1,
          width: "100%",
        }}
      >
        <CustomButton size="medium" variant="outlined">
          장바구니 담기
        </CustomButton>
        <CustomButton size="medium">주문하기</CustomButton>
      </Stack>
    </Stack>
  );
};
