import { Box, Stack, Typography } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import RemoveIcon from "@mui/icons-material/Remove";
import { useIntl } from "react-intl";

const TickerNotice = () => {
  const intl = useIntl();

  return (
    <Box sx={{ px: 1.5, paddingInlineEnd: 1, mt: 2 }}>
      <Typography
        variant="body2"
        component="div"
        sx={{ display: "flex", alignItems: "start", fontWeight: "bold" }}
        color="primary"
      >
        <Stack
          sx={{ height: "24px" }}
          justifyContent="center"
          alignItems="center"
        >
          <InfoIcon sx={{ height: "20px" }} />
        </Stack>
        {intl.formatMessage({ id: "orders.caution1" })}
      </Typography>
      <Stack sx={{ p: 0.5 }} spacing={0.7}>
        <Typography
          variant="caption"
          component="div"
          sx={{ display: "flex", alignItems: "start" }}
        >
          <Stack
            sx={{ height: "20px" }}
            justifyContent="center"
            alignItems="center"
          >
            <RemoveIcon sx={{ width: "12px", marginInlineEnd: 0.5 }} />
          </Stack>
          {intl.formatMessage({ id: "orders.caution2" })}
        </Typography>
        <Typography
          variant="caption"
          component="div"
          sx={{ display: "flex", alignItems: "start" }}
        >
          <Stack
            sx={{ height: "20px" }}
            justifyContent="center"
            alignItems="center"
          >
            <RemoveIcon sx={{ width: "12px", marginInlineEnd: 0.5 }} />
          </Stack>
          {intl.formatMessage({ id: "orders.caution3" })}
        </Typography>
        <Stack alignItems="start" direction="row">
          <Stack sx={{ height: "20px" }}>
            <RemoveIcon sx={{ width: "12px", marginInlineEnd: 0.5 }} />
          </Stack>
          <Stack>
            <Typography
              variant="body2"
              color="primary"
              sx={{ fontWeight: "bold" }}
            >
              {`${intl.formatMessage({
                id: "orders.contact",
              })} help@hcclab.com `}
            </Typography>
            <Typography variant="caption" color="black">
              {`(${intl.formatMessage({
                id: "cs.weekday",
              })} 09:00~17:00 / ${intl.formatMessage({
                id: "cs.holiday",
              })})`}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
};

export default TickerNotice;
