import { CommunicationType } from "pages/signup-panel/RegistrationTab";
import { ProviderUtils } from "./providerUtils";

export const makeSignUpFormData = (communicationMethods: CommunicationType) => {
  const formData = new FormData();

  if (ProviderUtils.isTravelwallet) {
    formData.append("provider", "TravelWallet");
  } else if (ProviderUtils.isPaybooc) {
    formData.append("provider", "Paybooc");
  } else if (ProviderUtils.isMame) {
    formData.append("provider", "Mame");
    formData.append("language", "ja");
  }

  formData.append("isReceivingEmail", String(communicationMethods.email));
  formData.append("isReceivingSms", String(communicationMethods.sms));
  formData.append("isReceivingTalk", String(communicationMethods.kakao));
  // formData.append("isReceivingPush", String(communicationMethods.push));

  return formData;
};
