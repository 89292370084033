import { Visibility, VisibilityOff } from "@material-ui/icons";
import {
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import CustomButton from "components/button/CustomButton";
import useValidate from "hooks/useValidate";
import { FormattedMessage, useIntl } from "react-intl";
import { useAppSelector } from "app/hooks";
import { InfoUpdateProps } from "./PersonalInfoFormPage";
import UserService from "services/userService";
import useLanguages from "hooks/useLanguages";
import { ProviderUtils } from "utils/providerUtils";

const initialValidState = {
  phone: true,
  password: false,
  birthDate: true,
};

const BasicInfoUpdate = ({
  userInfo,
  setUserInfo,
  setModalVisible,
}: InfoUpdateProps) => {
  const intl = useIntl();
  const { isKorean } = useLanguages();
  const { email, ssoName } = useAppSelector((state) => state.user);

  const [isChangingPassword, setIsChangingPassword] = useState(false);
  const [passwordVisibility, setPasswordVisibility] = useState({
    showCurrentPassword: false,
    showNewPassword: false,
    showConfirmPassword: false,
  });
  const [validState, validate] = useValidate(initialValidState);

  // 입력 필드 변경 처리 함수
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    // Check if newPassword and phoneNumber are valid
    if (name === "password" || name === "phone" || name === "birthDate") {
      validate(e);
    }

    setUserInfo((prev) => ({ ...prev, [name]: value }));
  };

  // 폼 유효성 검사 함수
  const validateForm = () => {
    // 이름, 휴대폰 필드 채워졌는지 확인
    if (!userInfo.name) {
      alert("이름을 입력해 주세요.");
      return false;
    }
    if (!userInfo.phone) {
      alert("전화번호를 입력해 주세요");
      return false;
    }

    // 비밀번호 변경하는 경우,
    if (isChangingPassword) {
      if (!userInfo.currentPassword) {
        alert("현재 비밀번호를 입력해 주세요");
        return false;
      }
      // 새 비밀번호 필드가 있는지 확인
      if (!userInfo.password) {
        alert("비밀번호를 입력해주세요");
        return false;
      }
      // 비밀번호 확인 필드가 있는지 확인
      if (!userInfo.confirmPassword) {
        alert("비밀번호 확인 필드를 입력해주세요");
        return false;
      }
      // 현재 비밀번호와 새 비밀번호가 다른지 확인
      if (userInfo.password === userInfo.currentPassword) {
        alert("현재 비밀번호와 새 비밀번호는 같을 수 없습니다.");
        return false;
      }

      // 새 비밀번호가 유효한지 확인
      if (!validState.password) {
        alert(intl.formatMessage({ id: "signup.passwordInvalid1" }));
        return false;
      }

      // 변경하는 비밀번호 일치하는지 확인
      if (userInfo.password !== userInfo.confirmPassword) {
        alert("비밀번호가 일치하지 않습니다.");
        return false;
      }
    }

    // 채워진 필드가 유요한 값인지 확인 (비밀번호 제외)
    if (!validState.phone) {
      alert("정확한 전화번호를 입력해 주세요");
      return false;
    }

    return true;
  };

  const handleSubmit = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    const updatedUserInfo = isChangingPassword
      ? {
          name: userInfo.name,
          oldPw: userInfo.currentPassword,
          newPw: userInfo.password,
          phone: userInfo.phone,
          birthDate: userInfo.birthDate,
        }
      : {
          name: userInfo.name,
          phone: userInfo.phone,
          birthDate: userInfo.birthDate,
        };
    try {
      await UserService.update(updatedUserInfo);
      setIsChangingPassword(false);

      setModalVisible(true);
    } catch (error) {}
  };

  const togglePasswordVisibility = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    const { name } = e.currentTarget as {
      name: keyof typeof passwordVisibility;
    };
    setPasswordVisibility((prev) => ({
      ...prev,
      [name]: !prev[name],
    }));
  };

  const handlePasswordChangingButton = () => {
    setIsChangingPassword((prev) => !prev);
    setUserInfo((prev) => ({
      ...prev,
      currentPassword: "",
      password: "",
      confirmPassword: "",
    }));
  };

  return (
    <Stack gap={4}>
      <Grid container spacing={2} alignItems="center">
        {/* 이메일 */}
        {email && (
          <>
            <Grid item xs={3}>
              <InputLabel>
                <FormattedMessage id="signup.email" />
              </InputLabel>
            </Grid>
            <Grid item xs={9}>
              <TextField
                variant="standard"
                type="email"
                name="prefix"
                value={email}
                autoComplete="off"
                sx={{ width: "100%" }}
                InputProps={{
                  readOnly: true,
                }}
                disabled
                margin="dense"
              />
            </Grid>
          </>
        )}

        {/* 이름 */}
        <Grid item xs={3}>
          <InputLabel>
            <FormattedMessage id="signup.name" />
          </InputLabel>
        </Grid>
        <Grid item xs={9}>
          <TextField
            fullWidth
            name="name"
            value={userInfo.name || ""}
            onChange={handleChange}
            autoComplete="off"
            size="small"
            // required
          />
        </Grid>

        {/* 비밀번호 */}
        {ProviderUtils.loginAvailable && !ssoName && (
          <>
            <Grid item xs={3}>
              <InputLabel>
                <FormattedMessage id="user.password" />
              </InputLabel>
            </Grid>
            <Grid item xs={9}>
              <Stack alignItems="end" gap={1}>
                {isChangingPassword && (
                  <>
                    <TextField
                      fullWidth
                      type={
                        passwordVisibility.showCurrentPassword
                          ? "text"
                          : "password"
                      }
                      name="currentPassword"
                      label={intl.formatMessage({
                        id: "user.password",
                      })}
                      value={userInfo.currentPassword}
                      onChange={handleChange}
                      size="small"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              name="showCurrentPassword"
                              aria-label="toggle password visibility"
                              onClick={togglePasswordVisibility}
                              edge="end"
                              tabIndex={-1}
                            >
                              {passwordVisibility.showCurrentPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <TextField
                      fullWidth
                      type={
                        passwordVisibility.showNewPassword ? "text" : "password"
                      }
                      name="password"
                      label={intl.formatMessage({
                        id: "forgot.newPassword",
                      })}
                      value={userInfo.password}
                      onChange={handleChange}
                      size="small"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              name="showNewPassword"
                              aria-label="toggle password visibility"
                              onClick={togglePasswordVisibility}
                              edge="end"
                              tabIndex={-1}
                            >
                              {passwordVisibility.showNewPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      error={Boolean(
                        (userInfo.password && !validState.password) ||
                          (userInfo.currentPassword &&
                            userInfo.password &&
                            userInfo.password === userInfo.currentPassword)
                      )}
                      helperText={
                        userInfo.password && !validState.password ? (
                          <>
                            <span>
                              {intl.formatMessage({
                                id: "signup.passwordInvalid1",
                              })}
                            </span>
                            <br />
                            <span>
                              {intl.formatMessage({
                                id: "signup.passwordInvalid2",
                              })}
                            </span>
                          </>
                        ) : (
                          userInfo.currentPassword &&
                          userInfo.password &&
                          userInfo.password === userInfo.currentPassword && (
                            <span>현재 비밀번호와 같을 수 없습니다.</span>
                          )
                        )
                      }
                    />
                    <TextField
                      fullWidth
                      type={
                        passwordVisibility.showConfirmPassword
                          ? "text"
                          : "password"
                      }
                      name="confirmPassword"
                      label={intl.formatMessage({
                        id: "forgot.newPasswordConfirm",
                      })}
                      // disabled={!validState.password}
                      value={userInfo.confirmPassword}
                      onChange={handleChange}
                      size="small"
                      error={
                        !!userInfo.confirmPassword &&
                        userInfo.password !== userInfo.confirmPassword
                      }
                      helperText={
                        userInfo.confirmPassword &&
                        userInfo.password !== userInfo.confirmPassword &&
                        intl.formatMessage({
                          id: "signup.passwordInvalid3",
                        })
                      }
                      autoComplete="off"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              name="showConfirmPassword"
                              aria-label="toggle confirm password visibility"
                              onClick={togglePasswordVisibility}
                              edge="end"
                              tabIndex={-1}
                            >
                              {passwordVisibility.showConfirmPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </>
                )}
                <CustomButton
                  type="button"
                  variant="outlined"
                  size="small"
                  fullWidth={false}
                  onClick={handlePasswordChangingButton}
                >
                  {isChangingPassword
                    ? "close"
                    : intl.formatMessage({ id: "user.password.change" })}
                </CustomButton>
              </Stack>
            </Grid>
          </>
        )}

        {/* 휴대폰 */}
        <Grid item xs={3}>
          <InputLabel>
            {intl.formatMessage({ id: "signup.phone" }).split(" ")[0]}
          </InputLabel>
        </Grid>
        <Grid item xs={9}>
          <TextField
            fullWidth
            type="tel"
            name="phone"
            value={userInfo.phone || ""}
            onChange={handleChange}
            // autoComplete="off"
            size="small"
            error={!!userInfo.phone && !validState.phone}
            helperText={
              userInfo.phone &&
              !validState.phone && (
                <span>
                  {intl.formatMessage({
                    id: "signup.phoneInvalid",
                  })}
                </span>
              )
            }
          />
        </Grid>

        {/* 생년월일 */}
        <Grid item xs={3}>
          <InputLabel>
            {intl.formatMessage({ id: "booking.dateOfBirth" }).split(" ")[0]}
          </InputLabel>
        </Grid>
        <Grid item xs={9}>
          <TextField
            fullWidth
            type="text"
            name="birthDate"
            value={userInfo.birthDate || ""}
            onChange={handleChange}
            // autoComplete="off"
            size="small"
            error={!!userInfo.birthDate && !validState.birthDate}
            helperText={
              !validState.birthDate && (
                <span>
                  {isKorean
                    ? "유효하지 않은 생년월일입니다"
                    : "Invalid birth date"}
                </span>
              )
            }
          />
        </Grid>
      </Grid>

      <Stack gap={1}>
        <Typography
          variant="body2"
          color="error"
          sx={{ wordBreak: "keep-all" }}
        >
          <span>
            ⚠️ 개인정보 수집∙이용에 동의해주세요. - 정보 수집 항목 바로보기
            '바로내용확인'을 진행해 주세요.
          </span>
        </Typography>
        <CustomButton onClick={handleSubmit}>
          {intl.formatMessage({ id: "user.info.save" })}
        </CustomButton>
      </Stack>
    </Stack>
  );
};

export default BasicInfoUpdate;
