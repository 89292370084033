import { Typography, List, ListItem } from "@mui/material";
import { useIntl } from "react-intl";
import { Stack } from "@mui/system";

const Instructions = () => {
  const intl = useIntl();

  // Split the text by line breaks and create list items
  const text = intl.formatMessage({ id: "seatSelection.howToSelectSeats" });
  const textLines = text.split("\n").map((line, index) => (
    <ListItem sx={{ px: 0, py: 0.5 }} key={index}>
      <Stack direction="row" alignItems="flex-start">
        <span style={{ marginInlineEnd: "8px" }}>•</span>
        <Typography variant="body2">{line}</Typography>
      </Stack>
    </ListItem>
  ));

  return <List sx={{ m: 2, p: 0 }}>{textLines}</List>;
};

export default Instructions;
