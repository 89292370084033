import styled from "styled-components";
import KTXLogo from "assets/images/KTX_logo.png";
import KTXSancheonLogo from "assets/images/KTX_SANCHEON_logo.svg";
import KTXEumLogo from "assets/images/KTX_EUM_logo.svg";
import { TrainServiceType } from "const/codeMap";

type LogoSize = "small" | "medium" | "large";

interface TrainLogoProps {
  stlbTrnClsfCd?: TrainServiceType;
  size?: LogoSize;
}

const TrainLogo = ({
  stlbTrnClsfCd = "00",
  size = "small",
}: TrainLogoProps) => {
  switch (stlbTrnClsfCd) {
    case "00": // KTX
      return <Logo src={KTXLogo} alt="KTX Logo" size={size} />;
    case "07": // KTX 산청
    case "10": // KTX 호남
      return <Logo src={KTXSancheonLogo} alt="KTX Sancheon Logo" size={size} />;
    case "16": // KTX 이음
      return <Logo src={KTXEumLogo} alt="KTX Eun Logo" size={size} />;
    default:
      return <Logo src={KTXLogo} alt="KTX Logo" size={size} />;
  }
};

export default TrainLogo;

const Logo = styled.img<{ size: LogoSize }>`
  height: ${(props) => {
    switch (props.size) {
      case "medium":
        return "14px";
      case "large":
        return "16px";
      default:
        return "12px";
    }
  }};
`;
