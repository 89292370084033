import React, { useState } from "react";
import {
  Grid,
  Stack,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
  Divider,
  useMediaQuery,
} from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import { PassengerDetail } from "app/reservationSlice";
import { ValidStateType } from "hooks/useValidate";
import { PassengerValidState } from "../PaymentBookingPage";
import CardLayout from "components/layout/CardLayout";
import { useAppSelector } from "app/hooks";
import { BookerInfo } from "utils/reservationUtils";
import useLanguages from "hooks/useLanguages";
import { ProviderUtils } from 'utils/providerUtils';

export type PassengerDetailField = "name" | "birthDate";

interface PassengerInfoSectionProps {
  agencyCode: string;
  setAgencyCode: React.Dispatch<React.SetStateAction<string>>;
  bookerDetails: BookerInfo;
  passengerDetails: PassengerDetail[];
  setPassengerDetails: React.Dispatch<React.SetStateAction<PassengerDetail[]>>;
  onChangePassengerDetail: (
    index: number,
    field: PassengerDetailField
  ) => (e: React.ChangeEvent<HTMLInputElement>) => void;
  validState: ValidStateType<PassengerValidState>;
}

const PassengerInfoSection = ({
  agencyCode,
  setAgencyCode,
  bookerDetails,
  passengerDetails,
  setPassengerDetails,
  onChangePassengerDetail,
  validState,
}: PassengerInfoSectionProps) => {
  const intl = useIntl();
  const { isKorean } = useLanguages();

  const matches = useMediaQuery("(min-width:390px)");
  const { name, birthDate } = useAppSelector((state) => state.user);
  const [isSameAsBooker, setIsSameAsBooker] = useState(false);

  const handleAgencyCodeChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setAgencyCode(e.target.value);
  };

  const handleSameAsBookerChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    setIsSameAsBooker(checked);

    if (checked) {
      // 첫 번째 탑승자 정보를 예매자 정보로 업데이트
      setPassengerDetails((prevDetails) => {
        const updatedDetails = [...prevDetails];
        updatedDetails[0] = {
          ...updatedDetails[0],
          name: name || bookerDetails.name,
          birthDate: birthDate || bookerDetails.birthDate,
        };
        return updatedDetails;
      });
    }
  };

  const getHeader = () => {
    return (
      <>
        <Stack
          direction={matches ? "row" : "column"}
          justifyContent="space-between"
          alignItems={matches ? "center" : "normal"}
          gap={matches ? 2 : 0}
          sx={{ width: "100%" }}
        >
          <Typography variant="body1" sx={{ fontWeight: "bold" }}>
            <FormattedMessage id="booking.passengerInfo" />
          </Typography>
          <FormControlLabel
            control={
              <Checkbox
                color="secondary"
                checked={isSameAsBooker}
                onChange={handleSameAsBookerChange}
              />
            }
            label={intl.formatMessage({ id: "booking.sameAsPurchaser" })}
            sx={{
              textAlign: "end",
              alignSelf: matches ? "start" : "end",
              m: 0,
              p: 0,
            }}
          />
        </Stack>
        {matches && <Divider />}
      </>
    );
  };

  const getAdditionalInfo = () => {
    return (
      <Grid container spacing={2} alignItems={"center"}>
        <Grid item xs={4}>
          <Typography variant="body1" sx={{ fontWeight: "bold" }}>
            {intl.formatMessage({ id: "booking.agencyCode" })}
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <TextField
            fullWidth
            variant="outlined"
            name="agencyCode"
            value={agencyCode}
            onChange={handleAgencyCodeChange}
            placeholder=""
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <CardLayout
      header={getHeader()}
      additionalInfo={ProviderUtils.isHanpass ? getAdditionalInfo() : undefined}
    >
      <Stack
        gap={2}
        sx={{
          width: "100%",
        }}
      >
        {passengerDetails.map((passenger, index) => (
          <Grid key={index} container spacing={1}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label={intl.formatMessage(
                  { id: "booking.passengerName" },
                  { number: index + 1 }
                )}
                variant="outlined"
                inputProps={{ maxLength: 50 }}
                name="passengerName"
                value={passenger.name}
                onChange={onChangePassengerDetail(index, "name")}
                placeholder={intl.formatMessage({
                  id: "booking.passengerNamePlaceholder",
                })}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                type="text"
                fullWidth
                name={`birthDate${index}`}
                value={passenger.birthDate}
                onChange={onChangePassengerDetail(index, "birthDate")}
                placeholder="YYYYMMDD"
                error={
                  !!passenger.birthDate && !validState[`birthDate${index}`]
                }
                helperText={
                  passenger.birthDate &&
                  !validState[`birthDate${index}`] && (
                    <span>
                      {isKorean
                        ? "유효하지 않은 생년월일입니다"
                        : "Invalid birth date"}
                    </span>
                  )
                }
              />
            </Grid>
          </Grid>
        ))}
      </Stack>
    </CardLayout>
  );
};

export default PassengerInfoSection;
