import {
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import ActivityDetailLayout from "./ActivityDetailLayout";
import CardLayout from "components/layout/CardLayout";
import CustomButton from "components/button/CustomButton";
import { forwardRef, useState } from "react";

interface ProductInquirySectionProps {
  title: string;
}

const ProductInquirySection = forwardRef<
  HTMLDivElement,
  ProductInquirySectionProps
>(({ title }, ref) => {
  const [secretInquiry, setSecretInquiry] = useState(false);
  const [inquiryText, setInquiryText] = useState("");

  return (
    <ActivityDetailLayout ref={ref} title={title}>
      <Stack gap={1}>
        <CardLayout>
          <Stack>
            <Typography variant="body2">
              • 상품과 무관한 글, 양도, 광고성 게시물, 욕설, 비방, 도배 글 등은
              사전 안내 없이 삭제될 수 있습니다.
            </Typography>
            <Typography variant="body2">
              • 주민등록번호, 연락처, 주소, 계좌번호, 카드번호와 같은 개인정보가
              노출될 경우 도용 위험이 있으니 각별히 유의해 주시기 바랍니다.
            </Typography>
          </Stack>
        </CardLayout>
        <TextField
          multiline
          rows={4}
          placeholder="문의하기"
          value={inquiryText}
          onChange={(e) => setInquiryText(e.target.value)}
        />
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item xs={4}>
            <FormControlLabel
              sx={{ alignSelf: "end", m: 0 }}
              control={
                <Checkbox
                  name="remember"
                  checked={secretInquiry}
                  sx={{ paddingInlineEnd: 0.3 }}
                  onChange={(e) => setSecretInquiry(e.target.checked)}
                />
              }
              label={"비밀글"}
            />
          </Grid>
          <Grid item xs={3}>
            <CustomButton size="small">문의하기</CustomButton>
          </Grid>
        </Grid>

        <Divider />
        <Typography variant="body2">문의 리스트</Typography>
        <Typography variant="body2">문의 리스트</Typography>
        <Typography variant="body2">문의 리스트</Typography>
        <Typography variant="body2">문의 리스트</Typography>
        <Typography variant="body2">문의 리스트</Typography>
        <Typography variant="body2">문의 리스트</Typography>
        <Typography variant="body2">문의 리스트</Typography>
        <Typography variant="body2">문의 리스트</Typography>
      </Stack>
    </ActivityDetailLayout>
  );
});

export default ProductInquirySection;
