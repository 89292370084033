import { Card, Typography, List, ListItem } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { Stack } from "@mui/system";
import useLanguages from "hooks/useLanguages";
import { ProviderUtils } from "utils/providerUtils";

const BookingGuidelines = () => {
  const { isKorean } = useLanguages();

  const guidelines = [
    // <>
    //   <FormattedMessage id="booking.noSoloTrainReservation" />{" "}
    //   <FormattedMessage id="booking.vouchersDelivery" />
    // </>,
    // <FormattedMessage id="booking.discountForAdultsOnly" />,
    // <FormattedMessage id="booking.expectedPointRates" />,
    <FormattedMessage id="booking.optionProducts" />,
    <FormattedMessage id="booking.cancellationPolicy" />,
    <FormattedMessage id="booking.noChangeAfterReservation" />,
    <FormattedMessage id="booking.generalSeatReservation" />,
    <FormattedMessage id="booking.differentLook" />,
    <FormattedMessage id="booking.realTimeReservations" />,
    <FormattedMessage id="booking.purchaseLimit" />,
    <FormattedMessage id="booking.notProvidedFeatures" />,
    <FormattedMessage id="booking.reservationRestrictions" />,
  ];

  const isPayboocKorean = isKorean && ProviderUtils.isPaybooc;

  return (
    <Card sx={{ p: 2 }}>
      <Typography variant="h6" sx={{ mb: 1 }}>
        <FormattedMessage id="booking.mustReadBeforePurchase" />
      </Typography>
      <List sx={{ p: 0 }}>
        {guidelines.map((text, index) => (
          <ListItem key={index} sx={{ p: 0.5 }}>
            <Stack direction="row" alignItems="flex-start">
              <span style={{ marginInlineEnd: "8px" }}>•</span>
              <Typography
                variant="caption"
                color={
                  index < (isPayboocKorean ? 4 : 3)
                    ? "text.primary"
                    : "text.secondary"
                }
                sx={
                  index < (isPayboocKorean ? 4 : 3)
                    ? {
                        textDecoration: "underline",
                        fontWeight: "bold",
                      }
                    : {}
                }
              >
                {text}
              </Typography>
            </Stack>
          </ListItem>
        ))}
      </List>
    </Card>
  );
};

export default BookingGuidelines;
