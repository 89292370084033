import parse from "html-react-parser";
import { useIntl } from "react-intl";
import Layout from "components/layout/Layout";

const TermsOfUse = () => {
  const intl = useIntl();

  return (
    <Layout text={intl.formatMessage({ id: "consent.termsTitle" })}>
      <div style={{ padding: "0 16px", lineHeight: 1 }}>
        {parse(`${intl.formatMessage({ id: "signup.termsContent" })}`)}
      </div>
    </Layout>
  );
};

export default TermsOfUse;
