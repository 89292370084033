import { LanguageCodeType } from "const/languageCodes";
import { useIntl } from "react-intl";

const useLanguages = () => {
  const intl = useIntl();

  const isKorean = intl.locale === "ko";
  const isJapanese = intl.locale === "ja";

  const isLanguage = (languageCode: LanguageCodeType) => {
    return intl.locale === languageCode;
  };

  return { isKorean, isJapanese, isLanguage };
};

export default useLanguages;
