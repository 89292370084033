import { useCallback, useEffect, useState } from "react";
import MenuOptions from "./sections/MenuOptions";
import Layout from "components/layout/Layout";
import LoadingSpinner from "components/LoadingSpinner";
import RecentOrderHeader from "./sections/RecentOrderHeader";
import { useNavigate } from "react-router-dom";
import { useIntl } from "react-intl";
import { Stack } from "@mui/material";
import LogoutButton from "components/button/LogoutButton";
import history from "history/browser";
import { fetchUser } from "app/userSlice";
import { fetchRecentOrders } from "app/myOrdersSlice";
import TrainTicket from "components/TrainTicket";
import PersonalInfoSettingSection from "./sections/PersonalInfoSettingSection";
import { useAppDispatch, useAppSelector } from "app/hooks";
import MyPointsSection from "./sections/MyPointsSection";
import UserService from "services/userService";
import TravelPackCard from "components/lottecard/TravelPackCard";
import { ProviderUtils } from "utils/providerUtils";
import { calculateDateBeforeOneYear } from 'utils/dateUtils';

const PersonalInfoPage = () => {
  const dispatch = useAppDispatch();
  const { userId } = useAppSelector((state) => state.user);
  const { recentOrders } = useAppSelector((state) => state.myOrders);

  const intl = useIntl();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(!userId);

  // actions
  const backToMain = useCallback(() => {
    navigate("/", { replace: true });
  }, [navigate]);

  const fetchUserInfo = useCallback(async () => {
    try {
      setLoading(true);
      const userInfo = await UserService.getMyInfo();
      dispatch(fetchUser(userInfo));
    } catch (error) {
      console.error("Failed to fetch user info:", error);
    } finally {
      setLoading(false);
    }
  }, [dispatch]);

  const fetchOrdersInfo = useCallback(async () => {
    try {
      const response = await UserService.getMyOrders(calculateDateBeforeOneYear());
      dispatch(fetchRecentOrders(response.content));
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
  }, [dispatch]);

  useEffect(() => {
    // Fetch user data only when redux doesn't have user data
    if (!userId) {
      fetchUserInfo().then(() => fetchOrdersInfo());
    } else {
      fetchOrdersInfo();
    }

    const historyEvent = history.listen(({ action }) => {
      if (action === "POP") {
        backToMain();
      }
    });
    return historyEvent;
  }, [backToMain, fetchOrdersInfo, fetchUserInfo, userId]);

  return (
    <Layout
      onBack={backToMain}
      text={intl.formatMessage({ id: "user.myInfo" })}
    >
      <Stack gap={1}>
        <PersonalInfoSettingSection />
        {ProviderUtils.pointAvailable && <MyPointsSection />}
        {ProviderUtils.provider?.provider === "lottetest" && (
          <TravelPackCard text={"트래블팩 구독하고 1천원 적립금 받아요"} />
        )}
        <RecentOrderHeader />
        <Stack justifyContent="center" gap={1}>
          {loading ? (
            <LoadingSpinner />
          ) : (
            recentOrders
              .slice(0, 5)
              .map((order, index) => <TrainTicket key={index} order={order} />)
          )}
        </Stack>
        <MenuOptions />
        {ProviderUtils.loginAvailable && <LogoutButton />}
      </Stack>
    </Layout>
  );
};

export default PersonalInfoPage;
