import { Grid, Stack, Typography } from "@mui/material";
import CardLayout from "components/layout/CardLayout";
import LoyaltyIcon from "@mui/icons-material/Loyalty";
import { FormattedMessage, useIntl } from "react-intl";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useNavigate } from "react-router-dom";
import useFetchPoint from "hooks/useFetchPoint";

const MyPointsSection = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const point = useFetchPoint();

  const navigateToPointHistory = () => {
    navigate("/user/point-history");
  };

  const navigateToRegisterCoupon = () => {
    navigate("/user/register-coupon");
  };

  const getHeader = () => {
    return (
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{ width: "100%" }}
      >
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          <FormattedMessage id="point.point" />
        </Typography>
        <Stack
          direction="row"
          alignItems="center"
          onClick={navigateToPointHistory}
          sx={{ cursor: "pointer" }}
        >
          <Typography
            variant="body2"
            color="secondary"
            sx={{ fontWeight: "bold" }}
          >
            <FormattedMessage id="point.earning_history" />
          </Typography>
          <NavigateNextIcon
            style={{
              cursor: "pointer",
              whiteSpace: "nowrap",
            }}
          />
        </Stack>
      </Stack>
    );
  };

  const getAdditionalInfo = () => {
    return (
      <Stack direction="row" justifyContent="end" sx={{ width: "100%" }}>
        <Stack
          direction="row"
          alignItems="center"
          onClick={navigateToRegisterCoupon}
          sx={{ cursor: "pointer" }}
        >
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{ fontWeight: "bold" }}
          >
            <FormattedMessage id="point.coupon_registration" />
          </Typography>
        </Stack>
      </Stack>
    );
  };

  return (
    <CardLayout header={getHeader()} additionalInfo={getAdditionalInfo()}>
      <Grid
        container
        justifyContent="space-between"
        // spacing={1}
        // rowSpacing={2}
      >
        <Grid item xs={2} sx={{ textAlign: "start", alignSelf: "start" }}>
          <LoyaltyIcon fontSize="large" color="secondary" />
        </Grid>

        <Grid item xs={10} alignSelf="end" sx={{ textAlign: "end" }}>
          <Typography variant="h5" sx={{ fontWeight: "bold" }}>
            {point.toLocaleString()}{" "}
            {intl.formatMessage({ id: "payment.koreanWon" })}
          </Typography>
        </Grid>
      </Grid>
    </CardLayout>
  );
};

export default MyPointsSection;
