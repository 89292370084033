import React from "react";
import {
  TextField,
  MenuItem,
  InputAdornment,
  FormControl,
  Select,
} from "@mui/material";

const EmailInputWithDropdown = ({ email, setEmail, domain, setDomain }) => {
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleDomainChange = (e) => {
    setDomain(e.target.value);
  };

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <TextField
        type="text"
        name="email"
        placeholder="이메일"
        value={email}
        onChange={handleEmailChange}
        InputProps={{
          endAdornment: <InputAdornment position="end">@</InputAdornment>,
        }}
        variant="outlined"
        style={{ marginInlineEnd: 8 }}
      />
      <FormControl variant="outlined">
        <Select
          name="domain"
          value={domain}
          onChange={handleDomainChange}
          displayEmpty
        >
          <MenuItem value="select">선택</MenuItem>
          <MenuItem value="gmail.com">gmail.com</MenuItem>
          <MenuItem value="naver.com">naver.com</MenuItem>
          <MenuItem value="daum.net">daum.net</MenuItem>
          {/* Add more domains as needed */}
        </Select>
      </FormControl>
    </div>
  );
};

export default EmailInputWithDropdown;

// // EmailInputWithDropdown.js
// import React from 'react';
// import './EmailInputWithDropdown.css'; // Make sure to create and import your CSS for styling

// const EmailInputWithDropdown = ({ email, setEmail, domain, setDomain }) => {
//   const handleEmailChange = (e) => {
//     setEmail(e.target.value);
//   };

//   const handleDomainChange = (e) => {
//     setDomain(e.target.value);
//   };

//   return (
//     <div className="email-input-with-dropdown">
//       <input
//         type="text"
//         name="email"
//         placeholder="이메일"
//         value={email}
//         onChange={handleEmailChange}
//         className="email-input"
//       />
//       <span>@</span>
//       <select name="domain" value={domain} onChange={handleDomainChange} className="email-domain-dropdown">
//         <option value="select">선택</option>
//         <option value="gmail.com">gmail.com</option>
//         <option value="naver.com">naver.com</option>
//         <option value="daum.net">daum.net</option>
//         {/* Add more domains as needed */}
//       </select>
//     </div>
//   );
// };

// export default EmailInputWithDropdown;
