import { Grid, Typography } from "@mui/material";
import CardLayout from "components/layout/CardLayout";
import LachaLogo from "assets/images/logo/logo.png";
import { Box } from "@mui/system";
import CardLogo from "./CardLogo";

interface CardBackProps {
  cvc: string;
}

const CardBack = ({ cvc }: CardBackProps) => {
  return (
    <CardLayout sx={{ mx: 0.5, bgcolor: "secondary.light" }}>
      <Grid
        container
        alignItems={"stretch"}
        sx={{
          minHeight: "120px",
          maxHeight: "240px",
          height: "calc((100vw - 32px) / 2.1)",
        }}
      >
        <Grid
          item
          xs={12}
          alignContent="end"
          sx={{
            height: "27%",
          }}
        >
          <Box
            sx={{
              width: "calc(100% + 32px)",
              height: "100%",
              position: "relative",
              right: 16,
              bgcolor: "#111e",
            }}
          ></Box>
        </Grid>
        <Grid
          item
          alignContent="end"
          sx={{
            bgcolor: "silver",
            width: "90%",
            height: "25%",
            px: 1,
            borderRadius: 0.2,
          }}
        >
          <Typography
            variant="h6"
            sx={{
              fontWeight: "bold",
              textAlign: "end",
              fontStyle: "italic",
            }}
          >
            {cvc}
          </Typography>
        </Grid>
        <Grid item alignContent="end">
          <Typography variant="h5" sx={{ fontWeight: "bold" }}>
            <CardLogo src={LachaLogo} alt="LACHA Logo" />
          </Typography>
        </Grid>
      </Grid>
    </CardLayout>
  );
};

export default CardBack;
