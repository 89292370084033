import { Grid, Typography } from "@mui/material";
import CustomButton from "components/button/CustomButton";
import TrainIcon from "@mui/icons-material/Train";
import { HEADER_HEIGHT } from "const/layout";

const DefaultBgImage = () => {
  return (
    <CustomButton
      style={{
        height: "187.5px",
        position: "relative",
        top: `${HEADER_HEIGHT}px`,
        cursor: "default",
        borderRadius: 0,
        overflow: "hidden",
      }}
      color="secondary"
    >
      <Grid container justifyContent="space-between">
        <Grid item xs={7.5} sx={{ mx: 2 }} alignContent="center">
          <Typography sx={{ fontSize: "1rem", textAlign: "start" }}>
            Let's
          </Typography>
          <Typography
            sx={{
              fontSize: "1.625rem",
              fontWeight: "bold",
              textAlign: "start",
              lineHeight: 1.2,
              mb: 2,
            }}
          >
            Book Your Next Trip
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <TrainIcon
            fontSize="large"
            sx={{
              fontSize: "220px",
              position: "relative",
              right: 72,
              top: -12,
            }}
          />
        </Grid>
      </Grid>
    </CustomButton>
  );
};

export default DefaultBgImage;
