import React from "react";
import HomePoint from "./HomePoint";
import TrainFlightTabs from "components/tabs/TrainFlightTabs";
import { ProviderUtils } from "utils/providerUtils";
import { useAppSelector } from "app/hooks";

const BookingFormHeader = () => {
  const { userId } = useAppSelector((state) => state.user);

  return userId && ProviderUtils.isHanacard ? (
    <HomePoint />
  ) : (
    <TrainFlightTabs value={0} />
  );
};

export default BookingFormHeader;
