import React from "react";
import { Tabs, Tab, Box } from "@mui/material";

interface CarTabsProps {
  value: number;
  onChange: (
    _e: React.SyntheticEvent<Element, Event>,
    newValue: number
  ) => void;
  tabLabels: string[];
}

const CarTabs = ({ value, onChange, tabLabels }: CarTabsProps) => {
  return (
    <Box sx={{ borderBottom: 1, borderColor: "divider", mb: 2 }}>
      <Tabs
        value={value}
        onChange={onChange}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="auto"
      >
        {tabLabels.map((label, index) => (
          <Tab key={index} label={label} sx={{ fontWeight: "bold" }} />
        ))}
      </Tabs>
    </Box>
  );
};

export default CarTabs;
