import useFormatSeatDetails from "hooks/useFormatSeatDetails";
import React from "react";
import { OrderType } from "types/orderType";
import { SeatDetails } from "components/seat/Seat";
import { useGetTicketDetails } from "hooks/bookingInfos/hooks";
import TicketHeader from 'pages/tickets/components/TicketHeader';
import TicketAdditionalInfo from 'pages/tickets/components/TicketAdditionalInfo';
import TicketContent from 'pages/tickets/components/TicketContent';

// Conditional Props in React
export interface History {
  order: OrderType;
  qrCode: React.ReactElement;
}

export type BookingInfoProps = History;

export interface TicketDetail {
  date: string;
  departure: string;
  arrival: string;
  trainType: string;
  trainNumber: string;
  departureTime: string;
  arrivalTime: string;
  duration: React.ReactElement;
  passengerDetails: string;
  seatDetails: SeatDetails[];
}

const BookingInfos = ({ order, qrCode }: BookingInfoProps) => {
  // hooks
  const formatSeatDetails = useFormatSeatDetails();

  // value
  const ticketDetails = useGetTicketDetails(order);
  const stationDetails = {
    departure: ticketDetails.departure,
    arrival: ticketDetails.arrival,
    startDate: ticketDetails.departureTime,
    endDate: ticketDetails.arrivalTime,
  };
  const travelDetails = {
    trainNumber: ticketDetails.trainNumber,
    duration: ticketDetails.duration,
  };

  const getHeader = () => {
    return <TicketHeader dateTime={ticketDetails.date} qrCode={qrCode} />;
  };

  const getAdditionalInfo = () => {
    return (
      <TicketAdditionalInfo
        passengerDetails={ticketDetails.passengerDetails}
        seatDetails={formatSeatDetails(ticketDetails.seatDetails)}
      />
    );
  };

  return (
    <TicketContent
      stationDetails={stationDetails}
      travelDetails={travelDetails}
      ticketHeader={getHeader()}
      ticketAdditionalInfo={getAdditionalInfo()}
    />
  );
};

export default BookingInfos;
