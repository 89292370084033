import { useState } from "react";
import {
  Typography,
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
  Collapse,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { FormattedMessage, useIntl } from "react-intl";
import { Stack } from "@mui/system";
import parse from "html-react-parser";
import { AgreementType, CommunicationType } from "../RegistrationTab";

interface ConsentFormProps {
  agreements: AgreementType;
  onAgreementChange: (name: keyof AgreementType) => void;
  communicationMethods: CommunicationType;
  onCommunicationMethodsChange: (method: keyof CommunicationType) => void;
  onCommunicationAllAgree?: () => void;
}

const ConsentForm = ({
  agreements,
  onAgreementChange,
  communicationMethods,
  onCommunicationMethodsChange,
  onCommunicationAllAgree,
}: ConsentFormProps) => {
  const intl = useIntl();
  const [isExpanded, setIsExpanded] = useState({
    terms: false,
    privacy: false,
    sales: false,
    info: false,
  });

  // 토글 확장 기능
  const toggleExpand = (item: keyof typeof isExpanded) => {
    setIsExpanded((prev) => ({ ...prev, [item]: !prev[item] }));
  };

  // URL을 새 탭에서 열기
  // const openURL = (url: string) => {
  //   window.open(url, "_blank", "noopener,noreferrer");
  // };

  let isPromoConsent = false;
  for (const key in communicationMethods) {
    const method = key as keyof CommunicationType;
    if (communicationMethods[method]) {
      isPromoConsent = true;
      break;
    }
  }

  return (
    <FormGroup sx={{ mb: 1, wordBreak: "keep-all" }}>
      {/* Terms and Conditions */}
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{ width: "100%" }}
      >
        <FormControlLabel
          control={
            <Checkbox
              checked={agreements.termsOfService}
              onChange={() => onAgreementChange("termsOfService")}
            />
          }
          label={intl.formatMessage({ id: "signup.termsTitle" })}
        />
        <IconButton onClick={() => toggleExpand("terms")}>
          {isExpanded.terms ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
      </Stack>
      <Collapse
        in={isExpanded.terms}
        sx={{
          width: "100%",
          lineHeight: 1,
          fontSize: "0.1rem",
        }}
      >
        {/* <Box
          dangerouslySetInnerHTML={{
            __html: intl.formatMessage({ id: "signup.termsContent" }),
          }}
        /> */}
        {parse(`${intl.formatMessage({ id: "signup.termsContent" })}`)}
      </Collapse>

      {/* Privacy */}
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{
          width: "100%",
        }}
      >
        <FormControlLabel
          control={
            <Checkbox
              checked={agreements.privacyPolicy}
              onChange={() => onAgreementChange("privacyPolicy")}
            />
          }
          label={intl.formatMessage({ id: "signup.privacyTitle" })}
        />
        <IconButton onClick={() => toggleExpand("privacy")}>
          {isExpanded.privacy ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
      </Stack>
      <Collapse
        in={isExpanded.privacy}
        sx={{ width: "100%", lineHeight: 1.2, fontSize: "0.1rem" }}
      >
        {/* <Box
          dangerouslySetInnerHTML={{
            __html: intl.formatMessage({ id: "signup.privacyContent" }),
          }}
        /> */}
        {parse(`${intl.formatMessage({ id: "signup.privacyContent" })}`)}
      </Collapse>

      {/* Operational Notification */}
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{ width: "100%" }}
      >
        <FormControlLabel
          control={
            <Checkbox
              checked={isPromoConsent}
              onClick={() => {
                isExpanded.sales || toggleExpand("sales");
                onCommunicationAllAgree?.();
              }}
              indeterminate={
                isPromoConsent &&
                (communicationMethods.email !== communicationMethods.kakao ||
                  communicationMethods.email !== communicationMethods.sms ||
                  communicationMethods.kakao !== communicationMethods.sms)
              }
            />
          }
          label={intl.formatMessage({ id: "signup.salesTitle2" })}
        />
        <IconButton onClick={() => toggleExpand("sales")}>
          {isExpanded.sales ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
      </Stack>
      <Collapse in={isExpanded.sales} sx={{ width: "95%" }}>
        {/*<Typography*/}
        {/*  variant="body2"*/}
        {/*  sx={{ marginInlineStart: 4, marginInlineEnd: 1 }}*/}
        {/*>*/}
        {/*  제도, 포인트, 적립금, 결제 관련 안내 등 [라쿠카라차]의 종합쇼핑몰*/}
        {/*  운영과 관련된 안내를 받으실 수 있습니다. 운영정보(제도) 수신동의와*/}
        {/*  관련된 사항은 담당자에게 문의하여 주시기 바랍니다.*/}
        {/*</Typography>*/}
        <Typography variant="body2" style={{ marginInlineStart: 32 }}>
          <FormattedMessage id="signup.salesContent2" />
        </Typography>
        <FormGroup row style={{ marginInlineStart: 32 }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={communicationMethods.email}
                onChange={() => onCommunicationMethodsChange("email")}
              />
            }
            label={intl.formatMessage({ id: "signup.email" })}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={communicationMethods.kakao}
                onChange={() => onCommunicationMethodsChange("kakao")}
              />
            }
            label={intl.formatMessage({ id: "signup.alimTalk" })}
          />
          {/* <FormControlLabel
            control={
              <Checkbox
                checked={communicationMethods.push}
                onChange={() => onCommunicationMethodsChange("push")}
              />
            }
            label="Push"
          /> */}
          <FormControlLabel
            control={
              <Checkbox
                checked={communicationMethods.sms}
                onChange={() => onCommunicationMethodsChange("sms")}
              />
            }
            label={intl.formatMessage({ id: "signup.sms" })}
          />
        </FormGroup>
      </Collapse>

      {/* Beneficial Notification */}
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{ width: "100%" }}
      >
        <FormControlLabel
          control={
            <Checkbox
              checked={agreements.etc}
              onChange={() => onAgreementChange("etc")}
            />
          }
          label={intl.formatMessage({ id: "signup.benefitTitle" })}
          onClick={() => toggleExpand("info")}
        />
        <IconButton onClick={() => toggleExpand("info")}>
          {isExpanded.info ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
      </Stack>
      <Collapse in={isExpanded.info} sx={{ width: "95%" }}>
        <Typography variant="body2" style={{ marginInlineStart: 32 }}>
          <FormattedMessage id="signup.benefitContent" />
        </Typography>
      </Collapse>
    </FormGroup>
  );
};

export default ConsentForm;
