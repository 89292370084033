import { Grid, Stack } from "@mui/material";
import seatForwardNormal from "assets/images/seat/seat_forward_normal.png";
import seatForwardSelect from "assets/images/seat/seat_forward_select.png";
import seatForwardDisabled from "assets/images/seat/seat_forward_disabled.png";
import seatReverseNormal from "assets/images/seat/seat_reverse_normal.png";
import seatReverseSelect from "assets/images/seat/seat_reverse_select.png";
import seatReverseDisabled from "assets/images/seat/seat_reverse_disabled.png";
import styled from "styled-components";
import React from "react";
import {
  SeatInfo,
  SeatStatus,
} from "pages/ktx/seat-selection-panel/sections/SeatMap";

export interface SeatDetails {
  scarNo: string;
  seatNo?: string;
  seatSpec: string;
}

interface SeatProps {
  seatGroup: SeatInfo[];
  selectedSeats: SeatDetails[];
  scarNo: string;
  handleSeatClick: (seat: SeatInfo) => void;
  seatStatuses: Record<string, SeatStatus>;
}

// 계산
const checkIsSelected = (
  seat: SeatInfo,
  selectedSeats: SeatDetails[],
  scarNo: string
) => {
  return selectedSeats.some(
    (selectedSeat) =>
      Number(selectedSeat.seatNo) === Number(seat.seatNo) &&
      selectedSeat.scarNo === scarNo
  );
};

const checkIsAvailable = (
  seatStatuses: Record<string, SeatStatus>,
  seat: SeatInfo
) => {
  return seatStatuses[seat.seatNo] === "available";
};

// 좌석 상태와 방향에 따라 이미지를 반환하는 함수
const getSeatImage = (
  seat: SeatInfo,
  selectedSeats: SeatDetails[],
  scarNo: string,
  seatStatuses: Record<string, SeatStatus>
) => {
  const isForwardDirection = seat.dirSeatAttCd === "009" ? true : false;
  const isAvailable = checkIsAvailable(seatStatuses, seat);
  const isSelected = checkIsSelected(seat, selectedSeats, scarNo);

  // 이미지 반환
  if (isSelected) {
    return isForwardDirection ? seatForwardSelect : seatReverseSelect;
  } else if (isAvailable) {
    return isForwardDirection ? seatForwardNormal : seatReverseNormal;
  } else {
    return isForwardDirection ? seatForwardDisabled : seatReverseDisabled;
  }
};

const Seat = ({
  scarNo,
  seatGroup,
  seatStatuses,
  selectedSeats,
  handleSeatClick,
}: SeatProps) => {
  return (
    <>
      {/* loop 도는 로직 복잡도 낮게 수정하기 */}
      {seatGroup.map((seat: SeatInfo): React.ReactElement => {
        const isAvailable = checkIsAvailable(seatStatuses, seat);
        return (
          <Grid item key={seat.seatNo}>
            <MediaQueryWrapper>
              <Stack
                justifyContent="center"
                alignItems="center"
                style={{
                  background: "center center/90% no-repeat ",
                  backgroundImage: `url(${getSeatImage(
                    seat,
                    selectedSeats,
                    scarNo,
                    seatStatuses
                  )})`,
                  height: "100%",
                  width: "100%",
                  userSelect: "none",
                  cursor: isAvailable ? "pointer" : "not-allowed",
                }}
                onClick={() => handleSeatClick(seat)}
                color={
                  isAvailable
                    ? selectedSeats.some(
                        (selectedSeat) =>
                          selectedSeat.scarNo === scarNo &&
                          Number(selectedSeat.seatNo) === Number(seat.seatNo)
                      )
                      ? "#FFF"
                      : "#000"
                    : "grey" // 선택된 좌석일 경우 텍스트 색상을 흰색으로 설정
                }
              >
                {seat.seatSpec}
              </Stack>
            </MediaQueryWrapper>
          </Grid>
        );
      })}
    </>
  );
};

export default Seat;

// Galazy Zflip
const MediaQueryWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  height: 64px;
  width: 64px;

  @media screen and (max-width: 362px) {
    height: 52px;
    width: 52px;
    font-size: 15px;
  }
`;
