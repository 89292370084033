import { SeatDetails } from "components/seat/Seat";
import { useIntl } from "react-intl";
import { removeLeadingZeros } from "utils/formatUtils";

const useFormatSeatDetails = () => {
  const intl = useIntl();

  // 선택된 차량 및 좌석 번호를 표시하기 위한 문자열 생성
  const formatSeatDetails = (detail: SeatDetails[]) => {
    // const seatDetail = detail.toSorted();
    const seatDetail = [...detail];
    seatDetail.sort();

    return (
      seatDetail
        .map((seat) =>
          intl.formatMessage(
            { id: "common.seatInfo" },
            {
              car: removeLeadingZeros(seat.scarNo),
              seat: seat.seatSpec ?? seat.seatNo,
            }
          )
        )
        .join(", ") || "선택된 좌석 없음"
    );
  };

  return formatSeatDetails;
};

export default useFormatSeatDetails;
