import Layout from "components/layout/Layout";
import { Container } from "@mui/system";
import { Stack } from "@mui/material";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import CustomButton from "components/button/CustomButton";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import MyListCardItem from "./components/MyListCardItem";
import { useAppSelector } from "app/hooks";

const MyCards = () => {
  const cards = useAppSelector((state) => state.cards);
  // redux에 저장하는 로직으로 변경하기
  const [myCards, setMyCards] = useState(cards);
  const navigate = useNavigate();

  const navigateToRegisterCard = () => {
    navigate("/card/register");
  };

  return (
    <Layout text="My Cards">
      <DragDropContext
        onDragEnd={(result) => {
          const { source, destination } = result;
          // if (!destination) {
          //   return;
          // } else if (source.index === destination.index) {
          //   return;
          // } else {
          if (destination && source.index !== destination.index) {
            const items = [...myCards];
            const [reorderedItem] = items.splice(source.index, 1);
            items.splice(destination.index, 0, reorderedItem);
            setMyCards(items);
          }
        }}
      >
        <Container maxWidth="sm" sx={{ width: "100%", mt: 2, px: 0 }}>
          <Stack gap={2}>
            <CustomButton size="medium" onClick={navigateToRegisterCard}>
              Add new card
            </CustomButton>
            <Droppable direction="vertical" droppableId="cards">
              {(provided) => (
                <Stack
                  gap={1}
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {myCards.map((card, key) => (
                    <Draggable
                      key={card.iapCdno}
                      draggableId={card.iapCdno}
                      index={key}
                    >
                      {(provided) => (
                        <MyListCardItem
                          ref={provided.innerRef}
                          card={card}
                          provided={provided}
                        />
                      )}
                    </Draggable>
                  ))}
                </Stack>
              )}
            </Droppable>
          </Stack>
        </Container>
      </DragDropContext>
    </Layout>
  );
};

export default MyCards;
