import { Tabs, Tab, useTheme } from "@mui/material";
import { useIntl } from "react-intl";
import iconSrc from "assets/icons/ico_maintab.png";
import styled from "styled-components";
import { ProviderUtils } from "utils/providerUtils";

interface TrainFlightTabsProps {
  value: number;
}

const TrainFlightTabs = ({ value }: TrainFlightTabsProps) => {
  const intl = useIntl();
  const theme = useTheme();
  const isDarkMode = useTheme().palette.mode === "dark";

  return (
    <Tabs
      value={value}
      // onChange={onChange}
      variant="fullWidth"
      indicatorColor="primary"
      textColor="primary"
      aria-label="train or flight booking tabs"
    >
      <Tab
        icon={
          <Icon
            src={iconSrc}
            id={0}
            $provider={ProviderUtils.provider}
            $isDarkMode={isDarkMode}
          />
        }
        label={intl.formatMessage({
          id: "trainMain.trainTravel",
        })}
        sx={{
          fontWeight: "bold",
          borderTopRightRadius: theme.border.radius.medium,
          borderTopLeftRadius: theme.border.radius.medium,
        }}
        // disableRipple
      />
    </Tabs>
  );
};

export default TrainFlightTabs;

const Icon = styled.div<{
  id: number;
  src: string;
  $provider: typeof ProviderUtils.provider;
  $isDarkMode: boolean;
}>`
  width: calc(40px * 0.7);
  height: calc(40px * 0.7);

  background-image: ${(props) => `url(${props.src})`};
  background-size: calc(436px * 0.7) calc(100px * 0.7);
  background-repeat: no-repeat;
  background-position-x: ${(props) => `calc(-132px * 0.7 * 2 * ${props.id})`};

  filter: ${(props) => {
    if (ProviderUtils.isPaybooc) {
      return "brightness(0) saturate(100%) invert(23%) sepia(78%) saturate(5918%) hue-rotate(340deg) brightness(104%) contrast(101%)";
    }

    if (ProviderUtils.isLottecard) {
      return "brightness(0) saturate(100%) invert(29%) sepia(83%) saturate(2510%) hue-rotate(213deg) brightness(101%) contrast(99%)";
    }

    if (ProviderUtils.isHanacard) {
      return "brightness(0) saturate(100%) invert(30%) sepia(79%) saturate(4062%) hue-rotate(157deg) brightness(97%) contrast(101%)";
    }

    if (ProviderUtils.isGME) {
      return "brightness(0) saturate(100%) invert(61%) sepia(68%) saturate(6504%) hue-rotate(336deg) brightness(101%) contrast(104%)";
    }

    if (ProviderUtils.isRedTable) {
      return "brightness(0) saturate(100%) invert(38%) sepia(76%) saturate(2925%) hue-rotate(332deg) brightness(100%) contrast(95%)";
    }

    return props.$isDarkMode
      ? "brightness(0) saturate(100%) invert(39%) sepia(9%) saturate(2178%) hue-rotate(181deg) brightness(107%) contrast(85%)"
      : "brightness(0) saturate(100%) invert(25%) sepia(50%) saturate(1212%) hue-rotate(210deg) brightness(92%) contrast(96%)";
  }};
`;
