import { Stack } from "@mui/material";
import Layout from "components/layout/Layout";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { BOTTOM_NAVIGATION_HEIGHT, HEADER_HEIGHT } from "const/layout";
import ScrollToTopButton from "components/ScrollToTopButton";
import useIntersect from "hooks/useIntersect";
import ActivityHeroSection from "./components/ActivityHeroSection";
import ActivityDetailTab from "./components/ActivityDetailTab";
import { OrderButton } from "./components/OrderButton";
import ProductTypeSection from "./components/ProductTypeSection";
import ProductInquirySection from "./components/ProductInquirySection";
import ProductCancelRefundInfoSection from "./components/ProductCancelRefundInfoSection";
import ProductInfoSection from "./components/ProductInfoSection";
import axios from "axios";

const tabLabels = [
  "상품타입",
  "상품정보",
  "취소/환불 규정",
  // "상품후기",
  "상품문의",
];

const Activity = () => {
  const { activityId } = useParams();
  const [tabValue, setTabValue] = useState(0);

  const [good, setGood] = useState<{
    goodsName: string;
    description: string;
    usage: string;
    refundNotice: string;
  }>();

  useEffect(() => {
    const fetchGoods = async () => {
      const { data } = await axios.get(
        `https://devpg.hcclab.com/goods/moreDetail?goodsId=${3}`
      );

      setGood(data);
    };
    fetchGoods();
  }, [activityId]);

  const intersectionObserverOptions = {
    rootMargin: `${HEADER_HEIGHT * 2}px 0px ${BOTTOM_NAVIGATION_HEIGHT + 8}px 0px`,
    threshold: 0.1,
  };

  const productTypeRef = useIntersect(
    () => setTabValue(0),
    undefined,
    intersectionObserverOptions
  );
  const productInfoRef = useIntersect(
    () => setTabValue(1),
    undefined,
    intersectionObserverOptions
  );
  const productCancelRefundInfoRef = useIntersect(
    () => setTabValue(2),
    undefined,
    intersectionObserverOptions
  );
  // const productReviewRef = useIntersect(
  //   () => setTabValue(3),
  //   undefined
  // );
  const productInquiryRef = useIntersect(
    () => setTabValue(3),
    undefined,
    intersectionObserverOptions
  );

  const sectionRefs = [
    productTypeRef,
    productInfoRef,
    productCancelRefundInfoRef,
    // productReviewRef,
    productInquiryRef,
  ];

  const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
    // sectionRefs[newValue].current?.scrollIntoView({
    //   behavior: "smooth",
    //   block: "start",
    // });
    const boundingRect = sectionRefs[newValue].current?.getBoundingClientRect();
    window.scrollBy({
      top: (boundingRect?.top ?? 0) - HEADER_HEIGHT * 2,
      behavior: "smooth",
    });
  };

  return (
    <Layout
      // text={`${activity.title}`}
      footer={false}
    >
      <Stack
        gap={2}
        sx={{
          position: "absolute",
          top: `${HEADER_HEIGHT}px`,
          left: "0",
          maxWidth: "calc(100vw)",
          bgcolor: "#ececec",
        }}
      >
        {/* 상품 일반 정보 */}
        <ActivityHeroSection />

        {/* 상품 세부 정보 */}
        {/* 상품 세부정보 탭 */}
        <ActivityDetailTab
          tabValue={tabValue}
          handleTabChange={handleTabChange}
          tabLabels={tabLabels}
        />

        <Stack gap={2} sx={{ mb: `${BOTTOM_NAVIGATION_HEIGHT * 2}px` }}>
          {/* 상품 타입 */}
          <ProductTypeSection
            ref={productTypeRef}
            title={tabLabels[0]}
            content={good?.description ?? ""}
          />

          {/* 상품정보 */}
          <ProductInfoSection
            ref={productInfoRef}
            title={tabLabels[1]}
            content={good?.usage ?? ""}
          />

          {/* 취소/환불 규정 */}
          <ProductCancelRefundInfoSection
            ref={productCancelRefundInfoRef}
            title={tabLabels[2]}
            content={good?.refundNotice ?? ""}
          />

          {/* 상품 문의 */}
          <ProductInquirySection ref={productInquiryRef} title={tabLabels[3]} />
        </Stack>
      </Stack>

      {/* 주문하기/장바구니 버튼 */}
      <OrderButton />

      <ScrollToTopButton />
    </Layout>
  );
};

export default Activity;
