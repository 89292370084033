import { Typography } from "@mui/material";
import { formatOrderStatus } from "utils/formatUtils";
import { FormattedMessage } from "react-intl";
import { OrderType } from "types/orderType";
import CardLayout from "components/layout/CardLayout";
import { Stack } from "@mui/system";

type OrderStationSectionType = {
  order: Pick<OrderType, "orderCreatedDate" | "orderId" | "orderStatus">;
};

const OrderStatusSection = ({ order }: OrderStationSectionType) => {
  const [date, time] = order?.orderCreatedDate?.split("T") ?? [];

  return (
    <CardLayout>
      <Stack alignItems="start" sx={{ width: "100%" }}>
        <Typography variant="body1">
          <span style={{ fontWeight: "bold" }}>
            <FormattedMessage id="orders.orderId" />
          </span>
          <span>: {order.orderId}</span>
        </Typography>
        <Typography variant="body1">
          <span style={{ fontWeight: "bold" }}>
            <FormattedMessage id="orders.orderStatus" />
          </span>
          <span>: </span>
          <Typography
            component="span"
            color={order.orderStatus === "CANCELED" ? "error" : "primary"}
            sx={{
              fontWeight: order.orderStatus !== "PENDING" ? "bold" : "normal",
            }}
          >
            <FormattedMessage id={formatOrderStatus(order.orderStatus)} />
          </Typography>
        </Typography>
        <Typography variant="body1">
          <span style={{ fontWeight: "bold" }}>
            <FormattedMessage id="orders.orderDate" />
          </span>
          <span>
            : {date} {time}
          </span>
        </Typography>
        {/*<Typography variant="body1">예약 상태: {order.reserveStatus}</Typography>*/}
      </Stack>
    </CardLayout>
  );
};

export default OrderStatusSection;
