import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { FormattedMessage } from "react-intl";
import CustomButton from "./CustomButton";

interface DeleteAccountButtonProps {
  onClick: () => Promise<void>;
}

const DeleteAccountButton = ({ onClick }: DeleteAccountButtonProps) => {
  return (
    <Card>
      <Accordion
        disableGutters
        elevation={0}
        sx={{
          borderRadius: 1,
          "&::before": { display: "none" },
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="user.delete"
          id="user.delete"
        >
          <Typography
            variant="h6"
            component="div"
            sx={{
              display: "flex",
              alignItems: "start",
              fontWeight: "bold",
            }}
            color="grey.700"
          >
            <FormattedMessage id="user.delete" />
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Stack alignItems="end" gap={1}>
            <Divider flexItem />
            <CustomButton
              variant="outlined"
              color="error"
              size="small"
              fullWidth={false}
              onClick={onClick}
            >
              <FormattedMessage id="user.delete" />
            </CustomButton>
          </Stack>
        </AccordionDetails>
      </Accordion>
    </Card>
  );
};

export default DeleteAccountButton;
