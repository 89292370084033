import { useState } from "react";
import {
  DialogActions,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Container,
  Stack,
  useTheme,
} from "@mui/material";
import Layout from "components/layout/Layout";
import { useNavigate } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import { deleteMyOrders, updateMyOrders } from "app/myOrdersSlice";
import CustomButton from "components/button/CustomButton";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { OrderStatus } from "types/orderType";
import PeriodFilter from "components/PeriodFilter";
import useLanguages from "hooks/useLanguages";
import usePeriodFilter from "hooks/usePeriodFilter";

const DetailQueryPage = () => {
  const dispatch = useAppDispatch();
  const { filterSettings } = useAppSelector((state) => state.myOrders);
  const {
    period,
    handlePeriodChange,
    fromDate,
    setFromDate,
    toDate,
    setToDate,
  } = usePeriodFilter(
    filterSettings.period,
    filterSettings.fromDate,
    filterSettings.toDate
  );
  const [status, setStatus] = useState(filterSettings.status);

  const intl = useIntl();
  const { isKorean } = useLanguages();
  const navigate = useNavigate();
  const theme = useTheme();

  const handleSubmit = () => {
    const filterSettings = {
      status,
      period,
      fromDate,
      toDate,
    };

    dispatch(deleteMyOrders());
    dispatch(updateMyOrders({ filterSettings }));
    navigate(-1);
  };

  return (
    <Layout text={intl.formatMessage({ id: "orders.filterTitle" })}>
      <Container maxWidth="md" sx={{ px: 0 }}>
        <PeriodFilter period={period} handlePeriodChange={handlePeriodChange} />

        {period === "custom" && (
          <Stack direction="row" gap={1} sx={{ mt: 2 }}>
            <TextField
              label={isKorean ? "부터" : "from"}
              type="date"
              value={fromDate}
              onChange={(e) => setFromDate(e.target.value)}
              InputLabelProps={{ shrink: true }}
              sx={{
                width: "50%",
                bgcolor: theme.palette.white.main,
                borderRadius: "16px",
              }}
            />
            <TextField
              label={isKorean ? "까지" : "to"}
              type="date"
              value={toDate}
              onChange={(e) => setToDate(e.target.value)}
              InputLabelProps={{ shrink: true }}
              sx={{
                width: "50%",
                bgcolor: theme.palette.white.main,
                borderRadius: "16px",
              }}
            />
          </Stack>
        )}

        <FormControl fullWidth sx={{ my: 1 }}>
          <InputLabel id="status-select-label">
            <FormattedMessage id="orders.status" />
          </InputLabel>
          <Select
            labelId="status-select-label"
            value={status}
            label={intl.formatMessage({ id: "orders.status" })}
            onChange={(e) => setStatus(e.target.value as "ALL" | OrderStatus)}
            sx={{
              bgcolor: theme.palette.white.main,
            }}
          >
            <MenuItem value="ALL">
              <FormattedMessage id="trainType.all" />
            </MenuItem>
            <MenuItem value="PENDING">
              <FormattedMessage id="orders.pending" />
            </MenuItem>
            <MenuItem value="COMPLETE">
              <FormattedMessage id="orders.complete" />
            </MenuItem>
            <MenuItem value="CANCELED">
              <FormattedMessage id="orders.canceled" />
            </MenuItem>
          </Select>
        </FormControl>

        <DialogActions>
          <CustomButton
            size="medium"
            fullWidth={false}
            variant="outlined"
            onClick={() => navigate(-1)}
            style={{
              backgroundColor: theme.palette.white.main,
            }}
          >
            <FormattedMessage id="orders.ignore" />
          </CustomButton>
          <CustomButton
            size="medium"
            fullWidth={false}
            variant="contained"
            onClick={handleSubmit}
          >
            <FormattedMessage id="orders.search" />
          </CustomButton>
        </DialogActions>
      </Container>
    </Layout>
  );
};

export default DetailQueryPage;
