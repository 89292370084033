import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Stack,
  Divider,
  Typography,
} from "@mui/material";
import parse from "html-react-parser";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { FormattedMessage, useIntl } from "react-intl";
import kakaoCoupon from "assets/images/kakao_coupon.webp";

interface KakoAccordionProps {
  descriptionKey?: string;
}

const KakaoAccordion = ({ descriptionKey }: KakoAccordionProps) => {
  const intl = useIntl();

  return (
    <Accordion
      disableGutters
      sx={{
        width: "100%",
        boxShadow: "none",
        "& .MuiButtonBase-root": { px: 0 },
        "& .MuiAccordionSummary-content": { my: 0 },
        bgcolor: "#0000",
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon fontSize="small" />}
        aria-controls="common.voucherUsage"
        id="common.voucherUsage"
        sx={{
          minHeight: "32px",
          display: "flex",
          alignItems: "end",
        }}
      >
        <Typography variant="body2">
          <FormattedMessage id="common.voucherUsage" />
        </Typography>
      </AccordionSummary>

      <AccordionDetails>
        <Stack alignItems={"center"} gap={1}>
          <img
            src={kakaoCoupon}
            alt="kakao_coupon"
            height={"480px"}
            style={{ borderRadius: "16px" }}
            onClick={() => window.open(kakaoCoupon)}
          />
          <Divider flexItem />

          {descriptionKey && (
            <Typography
              variant="caption"
              sx={{
                lineHeight: 0.4,
              }}
            >
              {parse(`${intl.formatMessage({ id: descriptionKey })}`)}
            </Typography>
          )}
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};

export default KakaoAccordion;
