import { styled } from "@mui/material/styles";
import { Stack, Card, Typography } from "@mui/material";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import { useIntl } from "react-intl";

interface PassengerCounterProps {
  label: "adults" | "children";
  count: number;
  total: number;
  onIncrement: () => void;
  onDecrement: () => void;
}

const PassengerCounter = ({
  label,
  count,
  total,
  onIncrement,
  onDecrement,
}: PassengerCounterProps) => {
  const intl = useIntl();
  return (
    <Wrapper>
      <Stack justifyContent="center" alignItems="center">
        <h4>
          {label === "adults"
            ? intl.formatMessage({
                id: "passengerSelection.adults",
              })
            : intl.formatMessage({
                id: "passengerSelection.children",
              })}
        </h4>
        <Typography
          component={"h5"}
          sx={{ textAlign: "center", wordBreak: "keep-all" }}
        >
          (
          {label === "adults"
            ? intl.formatMessage({ id: "passengerSelection.13Plus" })
            : intl.formatMessage({ id: "passengerSelection.ages6to13" })}
          )
        </Typography>
      </Stack>

      <Stack direction="row" alignItems="center">
        <Stack direction="row" gap={3}>
          <CounterButton
            onClick={onDecrement}
            disabled={count < 1}
            className="counter-button"
          >
            <RemoveIcon
              fontSize="small"
              color={count < 1 ? "disabled" : "secondary"}
            />
          </CounterButton>
          <h4>{count}</h4>
          <CounterButton
            onClick={onIncrement}
            disabled={total >= 9}
            className="counter-button"
          >
            <AddIcon
              fontSize="small"
              color={total >= 9 ? "disabled" : "secondary"}
            />
          </CounterButton>
        </Stack>
      </Stack>
    </Wrapper>
  );
};

export default PassengerCounter;

const Wrapper = styled(Card)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;

  width: 100%;
  height: 140px;

  padding: 8px;

  label {
    flex-grow: 1;
  }

  span {
    min-width: 32px;
    text-align: center;
  }

  /* Galaxy Fold */
  @media screen and (max-width: 280px) {
    flex-direction: column;
    gap: 8px;
  }
`;

// TODO: 색상 관련 내용 theme에 추가해서 바꾸기
const CounterButton = styled("button")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",

  border: `1px solid #222`,
  padding: "8px 12px",
  width: "28px",
  height: "28px",
  borderRadius: "100%",
  cursor: "pointer",

  backgroundColor: theme.palette.white.main,

  "&:active": {
    backgroundColor: theme.palette.grey[400],
  },

  "&:disabled": {
    borderColor: "#ddd",
  },
}));
