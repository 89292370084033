import { useAppSelector } from "app/hooks";
import { LanguageCodeType } from "const/languageCodes";
import { useState, useEffect } from "react";
import { JSONLocale } from "types/jsonType";
import useLanguages from "./useLanguages";



/**
 * Loads the locale data for the given language, using dynamic imports
 * to improve performance by lazy loading the locale data only when
 * needed.
 *
 * @param locale The language code to load the locale data for.
 * @returns A promise that resolves to the locale data.
 */
const loadLocaleData = (locale: LanguageCodeType) => {
  switch (locale) {
    case "ko":
      return import("assets/locales/ko.json");
    case "zh":
      return import("assets/locales/zh.json");
    case "ja":
      return import("assets/locales/ja.json");
    default:
      return import("assets/locales/en.json");
  }
};

/**
 * Custom hook to translate station names based on the user's language preference.
 * It loads locale data dynamically and provides a function to translate station names.
 *
 * @returns {function} A function that takes a station name in Korean and returns its translation
 * based on the current language setting. If the language is Korean, the original name is returned.
 */
const useTranslateStation = () => {
  const [localeData, setLocaleData] = useState<JSONLocale | null>(null);
  const { language } = useAppSelector((state) => state.user);
  const { isKorean } = useLanguages();

  // Find the station's English name based on the Korean name in the translations
  useEffect(() => {
    loadLocaleData(language).then((data) => {
      setLocaleData(data.default as JSONLocale);
    });
  }, [language]);

  // 역 이름을 번역하는 함수
  const translateStationName = (stationName: string): string => {
    // locale이 한국어가 아닌 경우 한국어 역에 해당하는 영어 이름을 찾아서 봔환
    return isKorean
      ? stationName
      : localeData?.stations?.find((station) => station.ko === stationName)
          ?.tr || "";
  };

  return translateStationName;
};

export default useTranslateStation;
