import CardLayout from "../layout/CardLayout";
import { Grid, Stack, Typography } from "@mui/material";
import LachaLogo from "assets/images/logo/logo.png";
import StarIcon from "@mui/icons-material/Star";
import CardLogo from "./CardLogo";
import { CardInterface } from "app/cardsSlice";

interface CardProps {
  cardNumber: string;
  expirationDate?: string;
  isMainCard?: CardInterface["rpstCrdYn"];
  isDisabled?: boolean;
  isRegister?: boolean;
}

export const formatCardNumber = (cardNumber: string, divider = " ") => {
  const cn1 = cardNumber.slice(0, 4);
  const cn2 = cardNumber.slice(4, 8);
  const cn3 = cardNumber.slice(8, 12);
  const cn4 = cardNumber.slice(12, 16);

  return `${cn1}${divider}${cn2}${divider}${cn3}${divider}${cn4}`;
};

const Card = ({
  cardNumber,
  expirationDate,
  isMainCard,
  isDisabled = false,
  isRegister = false,
}: CardProps) => {
  return (
    <CardLayout
      sx={{
        mx: 0.5,
        bgcolor: isDisabled ? "grey" : "secondary.main",
        opacity: isDisabled ? 0.6 : 1,
        color: "#F5F5F5",
        userSelect: "none",
      }}
    >
      <Grid
        container
        sx={{
          minHeight: "120px",
          maxHeight: "240px",
          height: "calc((100vw - 32px) / 2.1)",
        }}
      >
        <Grid item alignSelf="start" xs={12} sx={{ textAlign: "end" }}>
          <Typography variant="h5" sx={{ fontWeight: "bold" }}>
            <CardLogo src={LachaLogo} alt="LACHA Logo" />
          </Typography>
        </Grid>
        <Stack
          direction="row"
          alignItems="center"
          sx={{
            position: "absolute",
            top: "50%",
            transform: "translateY(-50%)",
          }}
        >
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            {formatCardNumber(cardNumber)}
          </Typography>
        </Stack>
        {isMainCard === "Y" && (
          <Stack
            direction="row"
            alignItems="center"
            gap={0.5}
            sx={{ position: "absolute", bottom: 16 }}
          >
            <StarIcon color="warning" fontSize="small" />
            <Typography variant="body2" sx={{ fontWeight: "bold" }}>
              Default Card
            </Typography>
          </Stack>
        )}
        {isRegister && (
          <Grid
            item
            alignSelf="end"
            xs={isMainCard ? 6 : 12}
            sx={{ textAlign: "end" }}
          >
            <Typography variant="body1" sx={{ fontWeight: "bold" }}>
              {expirationDate}
            </Typography>
          </Grid>
        )}
      </Grid>
    </CardLayout>
  );
};

export default Card;
