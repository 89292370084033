import React from "react";
import { Box, Typography } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CustomButton from "components/button/CustomButton";
import { Stack } from "@mui/system";
import { useNavigate } from "react-router-dom";
import { FormattedMessage } from "react-intl";

const CompletionPage = ({ isModal = false }: { isModal?: boolean }) => {
  const navigate = useNavigate();

  const handleLoginClick = () => {
    navigate("/login", { state: { backToSearchResult: isModal } });
  };

  return (
    <Box sx={{ textAlign: "center", p: 3 }}>
      <CheckCircleOutlineIcon sx={{ fontSize: 60, color: "primary.main" }} />
      <Typography variant="h4" sx={{ mt: 2, mb: 1 }}>
        <FormattedMessage id="signup.successTitle" />
      </Typography>
      <Typography variant="subtitle1" sx={{ mb: 3 }}>
        <FormattedMessage id="signup.successMessage" />
      </Typography>
      <Stack direction="row" spacing={1}>
        <CustomButton
          size="medium"
          variant="contained"
          onClick={handleLoginClick}
        >
          <FormattedMessage id="signup.login" />
        </CustomButton>
      </Stack>
    </Box>
  );
};

export default CompletionPage;
