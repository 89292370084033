import { Stack } from "@mui/system";
import CardLayout from "components/layout/CardLayout";
import { ForwardedRef, forwardRef } from "react";
import StarIcon from "@mui/icons-material/Star";
import { Typography } from "@mui/material";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import { CardInterface } from "app/cardsSlice";
import { DraggableProvided } from "react-beautiful-dnd";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import { NumericString } from "types/numericString";

const hideMiddleCardNumber = (number: NumericString) => {
  // const [cn1, , , cn4] = number.split(" ");
  const cn1 = number.slice(0, 4);
  const cn4 = number.slice(12);

  return `${cn1} •••• •••• ${cn4}`;
};

interface MyListCardItemProps {
  card: CardInterface;
  provided: DraggableProvided;
}

const MyListCardItem = forwardRef(
  (
    { card, provided }: MyListCardItemProps,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    return (
      <div ref={ref} {...provided.draggableProps} {...provided.dragHandleProps}>
        <CardLayout sx={{ py: 1, paddingInlineStart: 1.5 }}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ width: "100%" }}
          >
            <Stack direction="row" alignItems="center" gap={2}>
              <Stack direction="row" alignItems="center" gap={0.5}>
                {card.rpstCrdYn === "Y" ? (
                  <StarIcon color="warning" />
                ) : (
                  <StarIcon sx={{ color: "#1113" }} />
                )}
                <CreditCardIcon fontSize="large" />
              </Stack>
              <Stack>
                {/* <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                  {card.name}
                </Typography> */}
                <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                  {hideMiddleCardNumber(card.iapCdno)}
                </Typography>
              </Stack>
            </Stack>
            <DragHandleIcon />
          </Stack>
        </CardLayout>
      </div>
    );
  }
);

export default MyListCardItem;
