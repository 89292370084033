import html2canvas from "html2canvas";

const useSaveAsImage = (orderId: string | number) => {
  const saveAsImage = async () => {
    const canvas = await html2canvas(
      document.querySelector(`#image-to-be-saved-${orderId}`)!,
      {
        backgroundColor: "white",
        ignoreElements: (element) =>
          element.classList.contains("hide-from-saved-image"),
      }
    );
    downloadImage(canvas.toDataURL("image/png"), `lacha_order_${orderId}.png`);
  };

  return saveAsImage;
};

export default useSaveAsImage;

const downloadImage = (uri: string, filename: string) => {
  const link = document.createElement("a");
  link.style.display = "none";
  link.href = uri;
  link.download = filename;

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
