import { Typography, useTheme } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import CustomButton from "components/button/CustomButton";
import { Stack } from "@mui/system";
import Layout from "components/layout/Layout";
import CancelIcon from "@mui/icons-material/Cancel";
import useLanguages from "hooks/useLanguages";
import { useEffect } from "react";
import { useAppDispatch } from 'app/hooks';
import { deleteReservation } from 'app/reservationSlice';

const CreditCancelPage = () => {
  const { isKorean } = useLanguages();
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const errorMessage = searchParams.get("errorMessage");

  const moveToOrderHistory = () => {
    navigate("/order-history");
  };

  useEffect(() => {
    // lotte card iframe이 있는 경우 닫기
    window.top?.closeLotteCancelIframe?.();

    // reservationSlice delete
    dispatch(deleteReservation());
  }, [dispatch]);

  return (
    <Layout onBack={moveToOrderHistory}>
      <Stack
        justifyContent="space-around"
        alignItems="center"
        sx={{ width: "100%", minHeight: "50dvh" }}
      >
        <Stack
          alignItems="center"
          justifyContent="center"
          gap={1}
          sx={{ width: "100%", my: 6, textAlign: "center" }}
        >
          <CancelIcon color="error" sx={{ fontSize: 120 }} />
          <Typography variant="h5" color="error" sx={{ fontWeight: "bold" }}>
            {isKorean ? "결제취소" : "Payment canceled"}
          </Typography>
          <Typography
            variant="h6"
            sx={{ maxWidth: "280px", wordBreak: "keep-all" }}
          >
            <FormattedMessage id="payment.cancel" />
          </Typography>

          {errorMessage && (
            <Typography
              variant="h6"
              sx={{ maxWidth: "280px", wordBreak: "keep-all" }}
            >
              취소 사유: {errorMessage}
            </Typography>
          )}
        </Stack>
        <Stack
          alignItems="center"
          gap={1}
          sx={{ maxWidth: "320px", width: "100%" }}
        >
          <CustomButton
            id="payment.orderListButton"
            color="error"
            variant="outlined"
            onClick={moveToOrderHistory}
            style={{
              backgroundColor: theme.palette.white.main,
              textTransform: "capitalize",
            }}
          />
        </Stack>
      </Stack>
    </Layout>
  );
};

export default CreditCancelPage;
