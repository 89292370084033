import { Stack, Typography } from "@mui/material";
import Layout from "components/layout/Layout";
import { PaymentMethod } from "const/paymentMethod";
import useProcessPayment from "hooks/useProcessPayment";
import { lazy, useCallback, useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import PaymentService, {
  GMEPaymentRequestResponse,
} from "services/paymentService";
import TrainService from "services/trainService";
import { makeAllOptionsTrue } from "utils/consentUtils";
import { getDefaultPaymentMethod } from "utils/paymentUtils";
import history from "history/browser";
import LoadingSpinner from "components/LoadingSpinner";
import OrderStatusSection from "components/order/OrderStatusSection";
import ProductInfoSection from "pages/ktx/order-history-panel/history-detail-panel/sections/ProductInfoSection";
import RelatedProduct from "pages/ktx/order-history-panel/history-detail-panel/sections/RelatedProduct";
import PointSection from "components/payment/PointSection";
import OrderSummarySection from "components/order/OrderSummarySection";
import PaymentMethodSection from "components/payment/PaymentMethodSection";
import CustomButton from "components/button/CustomButton";
import { OrderType } from "types/orderType";
import { isAxiosError } from "axios";
import useLanguages from "hooks/useLanguages";
import useHanpassCoupon from "hooks/useHanpassCoupon";
import BookingGuidelines from "pages/tickets/components/BookingGuidelines";
import AgreementsSection from "./sections/AgreementsSection";
import useFormatPrice from "hooks/useFormatPrice";
import { ProviderUtils } from "utils/providerUtils";
import useModalSheet from "hooks/overlay/useModalSheet";
import TimeService from "services/timeService";
import GMEBottomSheetContent from "./sections/GMEBottomSheetContent";
import { getMobileOperatingSystem } from "utils/osUtils";

// const GMEBottomSheet = lazy(() => import("components/bottomSheet/BottomSheet"));
const HanpassCoupon = lazy(() => import("components/payment/HanpassCoupon"));
const PointParkIframe = lazy(() => import("components/PointParkIframe"));

const initialAgreements = ProviderUtils.isLottecard
  ? {
      term1: false,
      term2: false,
      term3: false,
    }
  : {
      term1: false,
      term2: false,
      term3: false,
      term4: false,
    };

export type PaymentAgreementType = typeof initialAgreements;

export const checkAllAgree = (agreements: PaymentAgreementType) => {
  return Object.values(agreements).every(Boolean);
};

interface PaymentPageProps {
  isHistory: boolean;
  reservationDetails: OrderType;
}

const PaymentPage = ({ isHistory, reservationDetails }: PaymentPageProps) => {
  const navigate = useNavigate();
  const hanacard = useLocation().state?.hanacard ?? false;
  const { isKorean } = useLanguages();
  const { orderId } = useParams();
  const formatPrice = useFormatPrice();

  // state
  const [isProcessing, processPayment] = useProcessPayment();
  const [isPaymentProcessing, setIsPaymentProcessing] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>(
    getDefaultPaymentMethod
  );
  const [agreements, setAgreements] = useState(initialAgreements);

  // Lacha 적립금
  const [point, setPoint] = useState("0");
  const numericPoint = Number(point.replaceAll(",", ""));

  // point park
  const [pointParkUrl, setPointParkUrl] = useState("");
  const [pointParkPoint, setPointParkPoint] = useState(
    reservationDetails?.externalPointUsed || 0
  );

  // hanpass coupon
  const {
    hanpassCoupons,
    selectedCoupon,
    hanpassCouponDiscountAmount,
    setSelectedCoupon,
  } = useHanpassCoupon();

  // data
  const isPending = reservationDetails?.orderStatus === "PENDING";
  const isPurchased = reservationDetails?.orderStatus === "COMPLETE";

  const isPaymentProcessButtonAvailable =
    !isProcessing && paymentMethod && checkAllAgree(agreements);

  // action
  const handleAllAgree = useCallback(() => {
    setAgreements(makeAllOptionsTrue(agreements));
  }, [agreements]);

  // 결제 처리 핸들러
  const handlePayment = useCallback(async () => {
    processPayment(
      Number(orderId),
      paymentMethod,
      numericPoint,
      selectedCoupon?.memberCouponSeq
    );
  }, [
    numericPoint,
    orderId,
    paymentMethod,
    processPayment,
    selectedCoupon?.memberCouponSeq,
  ]);

  const [ModalSheet, setModalVisible] = useModalSheet({
    callbackOk: handlePayment,
  });

  // gme
  const [gmeConnect, setGmeConnect] = useState<GMEPaymentRequestResponse>();

  // const gmeConnect = useRef<GMEPaymentRequestResponse>();
  // const [bottomSheetKey, setBottomSheetKey] = useState(0);
  // const [bottomSheetBottomProperty, setBottomSheetBottomProperty] = useState(
  //   BOTTOM_SHEET_HEIGHT * 2
  // );

  const handleGmeClick = () => {
    const os = getMobileOperatingSystem();

    const { availablePay = false, key = "" } = gmeConnect || {};

    if (os === "Android") {
      if (availablePay) {
        Android.needtocertification(key);
      } else {
        Android.gotodepositapplyguide("deposit insufficient");
      }
    } else {
      if (availablePay) {
        window.webkit.messageHandlers.iosWebView.postMessage({
          childData: "needtocertification",
          value: key,
        });
      } else {
        window.webkit.messageHandlers.iosWebView.postMessage({
          childData: "gotodepositapplyguide",
          value: "deposit insufficient",
        });
      }
    }
  };

  const [GmeBottomSheet, setGmeBottomSheetVisible] = useModalSheet({
    callbackOk: handleGmeClick,
  });

  // 결제 버튼 클릭 핸들러
  const handlePaymentButtonClick = useCallback(async () => {
    if (!checkAllAgree(agreements)) {
      alert(
        isKorean
          ? "이용약관을 확인 후 동의에 체크해주세요"
          : "Please consent to the forms before proceeding"
      );
      return;
    }

    try {
      setIsPaymentProcessing(true);
      if (reservationDetails) {
        const currentDate = new Date(await TimeService.getCurrentTime());
        const paymentLimitDate = new Date(reservationDetails?.paymentLimitDate);

        const isExpiredReservation = currentDate > paymentLimitDate;

        if (isExpiredReservation) {
          alert(
            isKorean
              ? "좌석 선점 시간이 지났습니다. 다시 예매해주세요"
              : "Your seat selection time has expired . Please book again"
          );
          navigate("/order-history");
          return;
        }
      }

      if (ProviderUtils.isPaybooc) {
        setModalVisible(true);
        return;
      }

      if (ProviderUtils.isLottecard) {
        if (
          reservationDetails &&
          [PaymentMethod.CREDIT, PaymentMethod.CORPORATE].includes(
            paymentMethod
          )
        ) {
          const lottecardResponse = await PaymentService.paymentRequest(
            reservationDetails.orderId,
            paymentMethod,
            numericPoint
          );

          navigate(`/payment/process/${orderId}`, {
            state: { lottecardResponse },
          });
        } else {
          handlePayment();
        }
        return;
      }

      if (ProviderUtils.isGME) {
        if (
          [PaymentMethod.CREDIT, PaymentMethod.CORPORATE].includes(
            paymentMethod
          )
        ) {
          handlePayment();
          return;
        }

        if (reservationDetails) {
          const gmeResponse = await PaymentService.paymentRequest(
            reservationDetails.orderId,
            paymentMethod,
            numericPoint
          );
          
          setGmeBottomSheetVisible(true);
          setGmeConnect(gmeResponse);
        }

        return;
      }
    } catch (error) {
      alert("결제에 실패했습니다. 다시 시도해주세요.");
    } finally {
      setIsPaymentProcessing(false);
    }

    handlePayment();
  }, [
    agreements,
    handlePayment,
    isKorean,
    navigate,
    numericPoint,
    orderId,
    paymentMethod,
    reservationDetails,
    setGmeBottomSheetVisible,
    setModalVisible,
  ]);

  const backToOrderList = useCallback(async () => {
    if (hanacard) {
      navigate("/hanacard/reservations");
      return;
    }

    if (isPending && orderId) {
      await TrainService.cancelPoint(orderId);
    }
    navigate("/order-history", { replace: true });
  }, [hanacard, isPending, navigate, orderId]);

  useEffect(() => {
    const historyEvent = history.listen(({ action }) => {
      if (action === "POP") {
        backToOrderList();
      }
    });
    return historyEvent;
  }, [backToOrderList]);

  /**
   * GME App payment
   * 심플패스워드 인증 성공 시, APP에서 getCertificationResponse 함수를 호출한다.
   * GME App인 경우 getCertificationResponse 함수 생성해서 결제 로직 진행될 수 있도록 합니다.
   */
  useEffect(() => {
    if (ProviderUtils.isGME) {
      const initGMEPayment = async (key: string) => {
        try {
          // TODO: useProcessPayment로 합칠 수 있나 생각해보기
          await PaymentService.initializeGMEPayment(key);
          history.replace("/order-history");
          navigate(`/train/creditPayment?orderId=${orderId}`);
        } catch (error) {
          if (isAxiosError(error)) {
            alert("결제에 실패했습니다. 다시 시도해주세요.");
          }
        }
      };

      window.getCertificationResponse = (key: string, isCertified: boolean) => {
        initGMEPayment(key);

        return {
          certified: isCertified,
          paymentKey: key,
        };
      };
    }
  }, [navigate, orderId]);

  const calculateFinalPrice = useCallback(() => {
    let finalPrice = 0;
    if (reservationDetails) {
      finalPrice =
        // reservationDetails.totalPrice -
        reservationDetails.originalPrice -
        numericPoint -
        pointParkPoint -
        hanpassCouponDiscountAmount;
    }
    return formatPrice(finalPrice);
  }, [
    formatPrice,
    hanpassCouponDiscountAmount,
    numericPoint,
    pointParkPoint,
    reservationDetails,
  ]);

  return (
    <Layout
      text={<FormattedMessage id="orders.detailTitle" />}
      onBack={backToOrderList}
    >
      {!reservationDetails ? (
        <LoadingSpinner />
      ) : (
        <Stack gap={1.5} sx={{ my: 2 }}>
          {isHistory && <OrderStatusSection order={reservationDetails} />}
          <ProductInfoSection order={reservationDetails} />

          {reservationDetails.goodsList && (
            <RelatedProduct order={reservationDetails} />
          )}

          <Stack gap={2} sx={{ textAlign: "center" }}>
            {ProviderUtils.pointAvailable && (
              <PointSection
                point={point}
                setPoint={setPoint}
                priceToPay={reservationDetails.totalPrice - pointParkPoint}
              />
            )}

            {ProviderUtils.isHanpass && (
              <HanpassCoupon
                coupons={hanpassCoupons}
                selectedCoupon={selectedCoupon}
                setSelectedCoupon={setSelectedCoupon}
              />
            )}

            <OrderSummarySection
              reservationDetails={reservationDetails}
              point={numericPoint}
              pointParkPoint={pointParkPoint}
              setPointParkPoint={setPointParkPoint}
              setPointParkUrl={setPointParkUrl}
              hanpassCouponDiscountAmount={hanpassCouponDiscountAmount}
            />

            {/* lottecard/hanacard인 경우 결제수단 선택 가리기 */}
            {!ProviderUtils.isLottecard && !ProviderUtils.isHanacard && (
              <PaymentMethodSection
                paymentMethod={paymentMethod}
                setPaymentMethod={setPaymentMethod}
              />
            )}
            <BookingGuidelines />
            <AgreementsSection
              agreements={agreements}
              setAgreements={setAgreements}
              handleAllAgree={handleAllAgree}
            />

            {/* hanacard는 결제수단 선택 부분 여기서 보여주기  */}
            {ProviderUtils.isHanacard && (
              <PaymentMethodSection
                paymentMethod={paymentMethod}
                setPaymentMethod={setPaymentMethod}
              />
            )}
          </Stack>

          {/* 결제 버튼 */}
          <Stack direction="row" gap={1}>
            <CustomButton
              id="payment.paymentProceed"
              // variant="outlined"
              onClick={handlePaymentButtonClick}
              disabled={isProcessing || !isPaymentProcessButtonAvailable}
              price={calculateFinalPrice()}
              size={isPending && !isPurchased ? "medium" : "large"}
            />
          </Stack>

          {/* Paybooc modal */}
          <ModalSheet
            cancellable
            okText={
              <FormattedMessage
                id="payment.paymentProceed"
                values={{ price: calculateFinalPrice() }}
              />
            }
          >
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              결제 오류 발생 시, https://lacha.co.kr 에서 결제하실 수 있습니다.
            </Typography>
          </ModalSheet>

          {ProviderUtils.isGME && gmeConnect && (
            <GmeBottomSheet
              cancellable
              okText={gmeConnect?.availablePay ? "Pay" : "Recharge"}
              cancelText="Cancel"
              buttonDirection="column"
            >
              <GMEBottomSheetContent
                value={gmeConnect}
                reservationDetails={reservationDetails}
              />
            </GmeBottomSheet>
          )}

          {/* GME bottom sheet */}
          {/* {ProviderUtils.isGME && gmeConnect.current && (
            <GMEBottomSheet
              key={bottomSheetKey}
              value={gmeConnect.current}
              bottom={bottomSheetBottomProperty}
              setBottom={setBottomSheetBottomProperty}
              reservationDetails={reservationDetails}
            />
          )} */}

          {/* 포인트 파크 iframe */}
          {pointParkUrl && (
            <PointParkIframe
              pointParkUrl={pointParkUrl}
              onClose={() => setPointParkUrl("")}
            />
          )}

          {(isProcessing || isPaymentProcessing) && <LoadingSpinner overlap />}
        </Stack>
      )}
    </Layout>
  );
};

export default PaymentPage;
