import { Card, Typography, Box, Stack } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import Layout from "components/layout/Layout";
import AlarmIcon from "@mui/icons-material/Alarm";
import ForumIcon from "@mui/icons-material/Forum";
import useLanguages from "hooks/useLanguages";

const CustomerCenterPage = () => {
  const intl = useIntl();
  const { isKorean } = useLanguages();

  return (
    <Layout text={intl.formatMessage({ id: "cs.title" })}>
      <Stack sx={{ width: "100%" }} alignItems="center">
        <Card sx={{ width: "100%", my: 2 }}>
          <Box sx={{ p: 2 }}>
            <Stack alignItems="center" gap={1}>
              <Stack alignItems="center">
                <ForumIcon sx={{ my: 2 }} fontSize="large" />
                <Typography variant="h5" gutterBottom>
                  <FormattedMessage id="cs.message" />
                </Typography>
                <Typography variant="body1">
                  {isKorean ? "이메일" : "Email"}: help@hcclab.com
                </Typography>
                <Typography variant="body1" gutterBottom>
                  {isKorean ? "전화번호" : "Tel"}: 02-568-1220
                </Typography>
              </Stack>
              <Stack alignItems="center">
                <AlarmIcon sx={{ my: 2 }} fontSize="large" />
                <Typography variant="body2">
                  <FormattedMessage id="cs.weekday" /> 09:00~18:00
                </Typography>
                <Typography variant="body2">
                  <FormattedMessage id="cs.lunch" /> 12:00~13:00
                </Typography>
                <Typography variant="body2" gutterBottom>
                  <FormattedMessage id="cs.holiday" />
                </Typography>
              </Stack>
              {/* <Button
            onClick={goToNotice}
            variant="contained"
            color="primary"
            sx={{ mt: 2 }}
            >
            {intl.formatMessage({ id: "cs.notice" })}
          </Button> */}
            </Stack>
          </Box>
        </Card>
      </Stack>
    </Layout>
  );
};

export default CustomerCenterPage;
